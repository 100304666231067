
// 颜色自定义
$primary_color:#232F3F;
// 导出按钮
.export-btn {
    float: right;
    border-color: #000;
    height: 36px;
    width: 88px;
    &:hover {
        background-color: #000;
        color: #fff;
    }
}