@import 'styles/_mixin';
.caption{
    width: 268px;
    padding: 20px 14px;
    .head{
        display: flex;
        justify-content: space-around;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #ffffff;
        background-color: #333333;
        // border-radius: 4px;
    }
    .body{
        padding-bottom: 12px;
        .item{
            display: flex;
            justify-content: space-around;
            text-align: center;
            padding-top: 12px;
            background-color: #F9F9F9;
            .unit{
                width: 80px;
                font-size: 14px;
                color: #666666;
            }
            .nums{
                width: 60px;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
            }
        }
    }
    .area-body{
        max-height: 286px;
        overflow-y: scroll;
    }
    .area-scroll {
        @include customScrollBar(286px);
    }
}