.all-box-dig {
    background-color: #FFFFFF;
    width: 100%;
    overflow-x: hidden;
    .digit-body {
        // width: 1160px;
        margin: 0 auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .digit-one {
            width: 100%;
            position: relative;
            .main-banner{
                width: 100%;
                height: 800px;
            }
            .data_top_img{
                position: absolute;
                right: 0;
                bottom: 0;
                width: 844px;
            }
        }
       
        .data_top{
            width: 100%;
            // height: 800px;
            // background: url('https://image.sciphi.cn/dt_insights/data_top_bg.png') center top no-repeat;
            // position: absolute;
            // top: 0px;
            // margin-top: -66px;
            position: absolute;
            // margin: 205px auto 0;
            left: 0;
            top: 0;
            .data_top_con{
                width: 1440px;
                // margin-top: 205px;
                margin: 198px auto 0;
                padding-left: 114px;
                .machine_title{
                    width: 777px;
                    font-size: 66px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 90px;
                }
                .change_title{
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 42px;
                    margin-top: 44px;
                }
                .age_title{
                    width: 579px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 36px;
                    margin-top: 18px;
                }
            }
        }
        #insightsBox {
            width: 1440px;
            height: 495px;
            background: #fff;
        }
        .insights_box{
            width: 1440px;
            height: 495px;
            background: #fff;
            display: flex;
            padding-top: 100px;
            padding-left: 100px;
            .ins_item{
                width: 396px;
                height: 296px;
                background: #363636;
                border-radius: 10px;
                margin-right: 26px;
                .ins_pic{
                    width: 80px;
                    height: 80px;
                    margin-left: 16px;
                }
                .ins_item_title{
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 32px;
                    margin-top: 16px;
                    margin-left: 30px;
                }
                .ins_item_line{
                    width: 46px;
                    height: 2px;
                    background: #FFFFFF;
                    border-radius: 1px;
                    margin-bottom: 26px;
                    margin-left: 30px;
                }
                .ins_item_con{
                    width: 289px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-left: 30px;
                }
                .inspic1{
                    background: url('https://image.sciphi.cn/dt_insights/insights_img1.svg') no-repeat;
                }
                .inspic2{
                    background: url('https://image.sciphi.cn/dt_insights/insights_img2.svg?v=1') no-repeat;
                }
                .inspic3{
                    background: url('https://image.sciphi.cn/dt_insights/insights_img3.svg') no-repeat;
                }
            }
            .insItem1:hover{
                .insLine1{
                    width: 46px;
                    height: 2px;
                    background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                    border-radius: 1px;
                }
                .inspic1{
                    background: url('https://image.sciphi.cn/dt_insights/insights_act_img1.svg') no-repeat;
                }
            }
            .insItem2:hover{
                .insLine2{
                    width: 46px;
                    height: 2px;
                    background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                    border-radius: 1px;
                }
                .inspic2{
                    background: url('https://image.sciphi.cn/dt_insights/insights_act_img2.svg') no-repeat;
                }
            }
            .insItem3:hover{
                .insLine3{
                    width: 46px;
                    height: 2px;
                    background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                    border-radius: 1px;
                }
                .inspic3{
                    background: url('https://image.sciphi.cn/dt_insights/insights_act_img3.svg') no-repeat;
                }
            }
        }
        .dtins_box{
            width: 100%;
            height: 1192px;
            background: #FBF9F9;
            display: flex;
            flex-direction: column;
            .dtins_box_con {
                width: 1440px;
                margin: 0 auto;
                position: relative;
            }
            .dtins_con{
                text-align: center;
                margin-top: 90px;
                .dtins_title{
                    font-size: 52px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 64px;
                }
                .dtins_content{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666;
                    line-height: 28px;
                    margin-top: 32px;
                    padding: 0 270px;
                }
            }
            .dtins_con_two {
                flex: 1;
            }
            .circle_middle{
                position: absolute;
                left: 450px;
                top: 413px;
            }
            .circle_left{
                position: absolute;
                left: 0px;
                top: 282px;
            }
            .circle_right{
                position: absolute;
                right: 0px;
                top: 282px;
            }
        }
        >p {
            font-size: 52px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 48px;
            text-align: center;
            margin: 90px 0 16px 0;
        }

        .sec1_body_one {
            height: 30px;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            margin-bottom: 16px;
        }

        .sec1_body_two {
            width: 900px;
            height: 80px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 40px;
            text-align: center;
        }

        .sec2_body_one {
            width: 900px;
            height: 120px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 40px;
            margin: 0 0 60px 0;
        }

        .sec3_body_one {
            width: 900px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666;
            line-height: 28px;
            margin: 32px 0 94px 0;
        }

    }
    .digit-body-end {
        margin: 0 auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .pic-all {
            width: 730px;
            height: 730px;
            border: 1px solid #FFC6C5;
            background: linear-gradient(180deg, rgba(255,255,255,0.7) 0%, rgba(254,200,187,0.5) 52%, #FFFFFF 100%);
            border-radius: 50%;
            position: absolute;
            margin-top: 200px;
            .red_center{
                width: 260px;
                height: 78px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 37px;
                line-height: 26px;
            }
            .cen {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 75px;
                text-align: center;
                position: relative;
                left: 210px;
                top: 330px;
                width: 290px;
                height: 76px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 60px;
            }

            :nth-child(2) {
                width: 260px;
                height: 78px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                padding: 11px 19px;
                text-align: center;
                position: relative;
                left: 235px;
                top: -115px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 37px;
            }

            :nth-child(3) {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: 30px;
                top: -70px;
                width: 120px;
                height: 64px;
                background: linear-gradient(135deg, #979797 0%, #373535 100%);
                border-radius: 60px;
            }

            :nth-child(4) {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: -25px;
                top: 26px;
                width: 160px;
                height: 64px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 60px;
            }

            :nth-child(5) {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: -20px;
                top: 118px;
                width: 160px;
                height: 64px;
                background: linear-gradient(135deg, #979797 0%, #373535 100%);
                border-radius: 60px;
            }

            :nth-child(6) {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: 42px;
                top: 217px;
                width: 120px;
                height: 64px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 60px;
            }

            :nth-child(7) {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                padding: 10px;
                text-align: center;
                position: relative;
                left: 234px;
                top: 267px;
                width: 260px;
                height: 78px;
                background: linear-gradient(135deg, #979797 0%, #373535 100%);
                // background: #02375a;
                border-radius: 37px;
            }

            :nth-child(8) {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: 560px;
                top: 75px;
                width: 120px;
                height: 64px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 60px;
            }

            :nth-child(9) {
                
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: 590px;
                top: -153px;
                width: 160px;
                height: 64px;
                background: linear-gradient(135deg, #979797 0%, #373535 100%);
                border-radius: 60px;
            }

            :nth-child(10) {
                
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: 595px;
                top: -373px;
                width: 160px;
                height: 64px;
                background: linear-gradient(135deg, #FC9E9E 0%, #E75040 100%);
                border-radius: 60px;
            }

            :nth-child(11) {
                
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 63px;
                text-align: center;
                position: relative;
                left: 566px;
                top: -598px;
                width: 120px;
                height: 64px;
                background: linear-gradient(135deg, #979797 0%, #373535 100%);
                border-radius: 60px;
            }




        }

        .sec-1 {
            position: relative;
            top: -10px;
            left: -336px;

            .a,
            .b {
                display: flex;
                justify-content: space-between;

                >span {
                    margin:0 10px 12px 0;
                    // border: 1px solid #979797;
                    line-height: 43px;
                    width: 130px;
                    height: 44px;
                    text-align: center;
                    background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                    border: 1px solid #FFB5B4;
                    // border-image: linear-gradient(141deg, rgba(255, 158, 158, 0.9), rgba(255, 145, 145, 0.25)) 1 1;
                    border-radius: 60px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .c {
                margin-top: 10px;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 32px;
                margin-bottom: 8px;
            }
        }

        .sec-2 {
            position: relative;
            left: -477px;
            top: 29px;

            .a {
                >span {
                    line-height: 43px;
                    text-align: center;
                    width: 340px;
                    height: 44px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                    border-radius: 60px;
                    border: 1px solid #A6A6A6;
                    // border-image: linear-gradient(141deg, rgba(147, 147, 147, 0.9), rgba(158, 158, 158, 0.25)) 1 1;
                }
            }


            .b {
                margin: 12px 0;
                display: flex;
                // justify-content: space-between;

                >span {
                    border: 1px solid #A6A6A6;
                    line-height: 43px;
                    text-align: center;
                    width: 230px;
                    height: 44px;
                    background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                    border-radius: 60px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                :nth-child(2) {
                    border: none;
                    font-style: normal;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 43px;
                    margin-left: 12px;
                }
            }

            .c {
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 32px;
                margin-bottom: 8px;
            }
        }

        .sec-3 {
            position: relative;
            left: -527px;
            top: 98px;
            display: flex;
            flex-direction: column;
            >span {
                width: 190px;
                height: 44px;
                line-height: 43px;
                background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                border-radius: 60px;
                border: 1px solid #FFB5B4;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-bottom: 12px;
            }
        }

        .sec-4 {
            position: relative;
            left: -521px;
            top: 150px;
            display: flex;
            flex-direction: column;
            >span {
                width: 190px;
                height: 44px;
                background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                border-radius: 60px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 43px;
                border: 1px solid #A6A6A6;
                text-align: center;
                margin-bottom: 12px;
            }
        }

        .sec-5 {
            position: relative;
            left: -448px;
            top: 270px;
            display: flex;
            flex-direction: column;
            .plm{
                width: 290px;
                height: 68px;
                line-height: 24px;
                
            }
            
            span {
                border: 1px solid #FFB5B4;
                font-size: 16px;
                text-align: center;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                border-radius: 60px;
                padding-top: 8px;
            }
            .sec_5_child{
                margin-top: 12px;
                .sed_span{
                    width: 140px;
                    height: 44px;
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }

        .sec-7 {
            position: relative;
            left: 416px;
            top: 46px;
        }

        .sec-7 {
            display: flex;
            flex-direction: column;

            >span {
                border: 1px solid #FFB5B4;
                text-align: center;
                width: 230px;
                height: 44px;
                background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                border-radius: 60px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 43px;
                margin-bottom: 12px;
            }
        }

        .sec-6 {
            position: relative;
            top: 290px;

            .a {
                display: flex;
                // justify-content: space-around;
                margin-bottom: 12px;

                >span {
                    font-size: 16px;
                    margin-right: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 43px;
                    border: 1px solid #979797;
                    text-align: center;
                    width: 230px;
                    height: 44px;
                    background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                    border-radius: 60px;
                }
            }

            .b {
                >span {
                    // margin: 10px;
                    // padding: 7px 30px;
                    border: 1px solid #979797;
                    width: 150px;
                    height: 44px;
                    background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                    border-radius: 60px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                    line-height: 43px;
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }

        .sec-8 {
            position: relative;
            left: 500px;
            top: -333px;
            display: flex;
            flex-direction: column;
            align-items: center;

            >span {
                margin-bottom: 10px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 32px;
            }

            .a {
                display: flex;
                flex-direction: column;
                .xian{
                    width: 228px;
                    margin-left: 52px;
                }
                .nami{
                    // padding: 0 12px;
                    width: 280px;
                }
                span {
                    height: 44px;
                    border: 1px solid #979797;
                    line-height: 43px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 12px;
                    background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                    border-radius: 60px;
                }
                .sec_8_child{
                    .child_spn_left{
                        width: 146px;
                        margin-right: 10px;
                    }
                    .child_spn_right{
                        width: 120px;
                    }
                    span{
                        display: inline-block;
                    }
                }
            }
        }

        .sec-9 {
            position: relative;
            left: 558px;
            top: -736px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sec_title9{
                height: 32px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 32px;
                background:none;
            }
            :nth-child(1) {
                border: none;
            }
            span{
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 32px;
            }
            >span {
                width: 244px;
                height: 34px;
                border: 1px solid #FFB5B4;
                line-height: 43px;
                text-align: center;
                width: 208px;
                height: 44px;
                background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                border-radius: 60px;
            }

            .a {
                display: flex;
                margin: 8px 0 12px 0;
                >span {
                    // margin: 8px;
                    // padding: 7px 16px;
                    text-align: center;
                    border: 1px solid #FFB5B4;
                    width: 60px;
                    height: 44px;
                    background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                    border-radius: 60px;
                    line-height: 43px;
                    margin-right: 10px;
                }

                :nth-child(1) {
                    border: none;
                    background:none;
                    margin-right: 12px;
                }
            }
        }

        .sec-10 {
            position: relative;
            left: 449px;
            top: -1002px;

            >span {
                border: 1px solid #979797;
                width: 180px;
                height: 44px;
                background: linear-gradient(180deg, rgba(255,255,255,0.61) 0%, rgba(209,208,208,0.72) 46%, #F4F4F4 100%);
                border-radius: 60px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 43px;
                text-align: center;
                display: inline-block;
            }
        }

        .sec-11 {
            position: relative;
            left: 290px;
            top: -1308px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sec_tip_label{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 32px;
                margin-bottom: 8px;
            }
            .a {

                // width: 100px;
                // height: 34px;
                // border: 1px solid #979797;
                // line-height: 34px;
                // margin-bottom: 20px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 43px;
                text-align: center;
                width: 180px;
                height: 44px;
                background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                border-radius: 60px;
                border: 1px solid #FFB5B4;
                margin-bottom: 12px;
                // border-image: linear-gradient(141deg, rgba(255, 158, 158, 0.9), rgba(255, 145, 145, 0.25)) 1 1;

            }

            .b {
                width: 370px;
                display: flex;
                justify-content: space-around;

                >p {
                    // width: 100px;
                    // height: 34px;
                    // border: 1px solid #979797;
                    // line-height: 34px;
                    margin-bottom: 10px;
                    text-align: center;
                    // width: 130px;
                    height: 44px;
                    background: linear-gradient(180deg, rgba(255,250,249,0.61) 0%, rgba(255,202,191,0.72) 46%, #FFFAF9 100%);
                    border-radius: 60px;
                    border: 1px solid #FFB5B4;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 43px;
                    padding: 0 17px;
                    margin-right: 10px;
                }
            }
            .c{
                width: 340px;
                display: flex;
                justify-content: space-around;
                margin-bottom: 20px;
                span{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 32px;
                }
            }

        }
    }
    


}

// @media (max-width: 1440px) {
//     .digit-body {
//         width: 1160px;
//     }
// }