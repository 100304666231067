.search-layer-form {
    position: relative;
    max-width: 100%;
    min-width: 1440px;
    margin: 0 auto;
    .content{
        margin: 0 auto;
        // padding-top: 160px;
        width: 860px;
        // height: 800px;
        .directions{
        padding: 20px 0;
        margin-bottom: 0;
        font-size: 16px;
        color: #9292a3;
        span{
            cursor: pointer;
            padding: 0 16px;
            border-right: 2px solid #9292a3;
        }
        span:last-child{
            border-right: none;
        }
        .active{
            font-weight: 600;
            font-size: 16px;
            color: #2222DA;
        }
        }
        .form-box {
            display: flex;
            .input-box{
                width: 720px;
                input{
                    padding: 14px 20px;
                }
            }
            .button-box{
                button{
                    width: 140px;
                    height: 100%;
                    background-color:#333333;
                    &:hover{
                        background-color:#333333;
                        border-color:#333333;
                    }
                }
                .ant-btn .ant-btn-primary{
                    border-radius: 0;
                }
            }
            .ant-input-affix-wrapper{
                padding: 0 11px;
            }
            .iconfont{
                font-size: 24px;
                color: #bbbbbb;
            }
        }
        .suggest-box{
            position: absolute;
            top: 50px;
            width: 720px;
            background-color: #fff;
            // border-radius: 10px;
        }
    }
}
.recommend-express{
    // background: #eee;
    margin: 42px auto 0;
    width: 920px;
    display: flex;
    .recommend-left{
        flex: 1;
        .recommenditem{
            display: flex;
            margin-bottom: 24px;
            .pic{
                width: 126px;
                height: 70px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                .custom-avatar,.custom-no-avatar{
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                    background-color: #ffffff;
                    img{
                        width: 100%;height: 100%;
                        object-fit: contain;
                    }
                }
                .custom-no-avatar {
                    img{
                        width: fit-content;
                    }
                }
            }
           .introduce-con{
               margin-left: 10px;
               .introduce{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    width: 360px;   
                    height: 36px;
                    overflow: hidden;
                    display:-webkit-box; 
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2; 
                    text-overflow: ellipsis;
               }
           }
        }
    }
    .express-right{
        margin-left: 60px;
        width: 364px;
        .expressitem{
            margin-bottom: 20px;
            cursor: pointer;
            .date{
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 17px;
                margin-top: 6px;
            }
        }
      
    }
    .title-box{                   
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 800;
        color: #333333;
        line-height: 20px;
        span{
            margin-right: 10px;
        }
   }
    .title{
        width: 64px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 800;
        color: #333333;
        line-height: 22px;
        margin-bottom: 20px;

    }
}