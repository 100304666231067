// user页面样式
.user-div{
    min-height: 60vh;
    position: relative;
    margin-top: 20px;
    // 退出按钮
    .logout{
        position: absolute;
        top:240px;
        left: 0;
        width: 200px;
        padding-left: 40px;
        .ant-btn{
            border: 1px solid #333333;
            color: #333333;
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            padding: 5px 40px;
            // border-radius: 5px;
            margin-left: -20px;
            &:hover{
                background-color: #333333;
                color: #fff;
            }
        }
    }
}
.user-con{
    // min-height: 80vh;
    padding-bottom: 20px;
    // 左边导航
    .ant-tabs-bar,.ant-tabs-nav-list{
        width: 200px;
        border: 0;
        margin-top: -5px;
        .ant-tabs-tab{
            // line-height: 50px;
            // height: 50px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 18px;
            text-align: left;
            padding: 0;
            padding-left: 40px;
            margin-bottom: 30px;
            cursor: pointer;
            .ant-tabs-tab-btn{
                padding-bottom: 5px;

            }
        }
        .ant-tabs-tab-active{
            width: fit-content;
            .ant-tabs-tab-btn{
                font-weight: 800;
                color: #333333;
                border-bottom: 2px solid #333333;

            }
            // background: linear-gradient(90deg, #f3f4fa 0%, white 100%);
        }
        .ant-tabs-ink-bar{
            display: none !important;
        }
    }
    .ant-tabs-left-content,.ant-tabs-content-holder{
        border: none;
        height: 100%;
        padding-right: 24px;
        // padding-top: 20px;
    }
    // 右边内容
    .ant-tabs-content,.ant-tabs-content-holder{
        // background-color: red;
        .ant-tabs-tabpane{
        border: 1px solid #DCDCDC;
            .ant-breadcrumb{
                margin: 30px 0;
            }
        }
        .ant-collapse{
            border: none;
            background-color:#fff;
            padding: 20px;
            >.ant-collapse-item{
                border-color: #EAEEFA;
                &:last-child{
                    border: none;
                }
            }
            .ant-collapse-item{
                .anticon.anticon-right{
                    display: none;
                }
            }
            // 头部
            .ant-collapse-header{
                padding: 30px 15px;
                font-size: 15px;
                .title{
                    color: #121F3D;
                    >span{
                        color: #9094A0;
                        width: 150px;
                        display: inline-block;
                    }   
                }
            }
            // 编辑按钮
            .edit-btn{
                color: #333333;
                width: 60px;
                cursor: pointer;
                .anticon{
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
        }
    }
    // 个人中心
    .center-box{
        .ant-collapse-content{
            // border: none;
        }
        .ant-collapse-content-box{
            display: flex;
            display: -webkit-flex;
            justify-content: flex-start;
            padding: 35px 16px;
        }
        h2{
            line-height: 44px;
            width: 150px;
            color: #9094A0;
            font-size: 15px;
        }
       
    }
    
    .ant-form{
        width: calc( 100% - 200px );
        .ant-form-item-label{
            text-align: left;
            line-height: 44px;
            >label{
                color: #9094A0;
                font-size: 15px;
            }
            .ant-form-item-required::before{
                display: none;
            }
            &:first-child{
                width: 150px;
            }
        }
        .ant-form-item-control{
            .ant-input{
                // line-height: 44px;
                height: 44px;
            }
        }
        .save-btn{
            margin-left: 150px;
            background-color: #333333;
            border-color:#333333;
            // border-radius: 8px;
            padding: 0 25px;
            height: 36px;line-height: 36px;
        }
    }
    .pwd-con{
        padding: 40px;   
    } 
    // 下载历史
    .down-div{
        h1{
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            color: #121F3D;
        }
        ul{
            padding: 20px;
        }
        li{
            width: 50%;
            padding: 10px 20px;
            display: inline-block;
            span{
                height: 60px;
                width: 60px;
                padding: 10px;
                background-color: #F0F2F7;
                border-radius: 10px;
                display: inline-block;
                margin-right: 20px;
                img{
                    width: 40px;height: 40px;
                }
            }
            >a{
                color: #121F3D;
                font-size: 16px;
                display: inline-block;
                &:hover{
                    color: #182390;
                }
            }
        }
    }
    
}
