.tabcharts {
    .pie,
    .graph,
    .barline,
    .patent-bar {
        height: 500px;
        width: 100%;
    }
    .effect-pie,
    .effect-tree,
    .effect-bar,
    .effect-scatter{
        height: 550px;
        width: 100%;
    }

    .invest_out {
        height: 700px;
        width: 100%;
    }

    .geo-bar {
        height: 420px;
        width: 100%;
    }

    // tab切换按钮
    .ant-tabs.ant-tabs-top .ant-tabs-nav {
        color: #666666;
        border: none;
        margin-bottom: 15px;

        &::before {
            border: none;
        }

        .ant-tabs-ink-bar {
            display: none !important;
        }

        .ant-tabs-nav-list {
            // background-color: #F9FAFD;
            padding: 8px 0;
            
            .ant-tabs-tab {
                margin-right: 15px;
                padding: 0px 16px 0px 0;
                border-right: 1px solid #eeeeee;
                

                .ant-tabs-tab-btn {
                    
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;

                }
            }

            >:nth-last-child(2){
                border-right: none;
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 800;
                    color: #333333;
                    line-height: 20px;

                }
            }
        }
    }

    // 切换内容
    .ant-tabs-content.ant-tabs-top-content,
    .ant-tabs-content.ant-tabs-content-top {

        // 单选项
        .check-group {
            margin-bottom: 20px;
            margin-left: 5px;
        }

        // 头部
        .charts-top {
            margin-bottom: 40px;
            background-color: #f9f9f9;
            flex-wrap: wrap;

            li {
                width: 25%;
                flex-direction: column;
                align-items: center;
                margin: 15px 0;

                span {
                    display: inline-block;

                    &:first-child {
                        color: #121F3D;
                        font-size: 25px;
                        font-weight: 600;

                        i {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            color: #121F3D;
                        }
                    }

                    &:last-child {
                        color: #9094A0;
                        font-size: 12px;
                    }
                }
            }
        }

        .finance_ok {
            li {
                font-size: 14px;
                line-height: 30px;
                flex-direction: row;
                justify-content: center;

                span.number {
                    color: #121F3D;
                    font-size: 22px;
                    margin: 0 10px;
                    font-weight: 600;
                }
            }
        }

        // 图表主体
        .charts-box {
            padding: 0;
            margin: 0;
        }
    }

    // 可切换图表的底部 切换 下载等功能
    .charts-footer {
        margin-top: 40px;

        .iconfont {
            font-size: 20px;
            font-weight: 600;
            color: #999; // 9094A0
        }
        .icon-charts-list{
            font-weight: 500;
            font-size: 19px;
        }

        .ant-radio-button-wrapper-checked .iconfont {
            color: $primary_color;
        }

        // 中间可视化切换
        .ct-col {
            text-align: center;
            line-height: 22px;

            p {
                margin-bottom: 0;
                display: inline-block;
                padding: 10px;
                background-color: #F9F9F9;
                color: $primary_color;
                font-size: 16px;
                // border-radius: 10px;
                font-weight: 500;
            }

            .anticon {
                margin: 0 25px;
                cursor: pointer;
            }
        }

        // 底部 全屏，切换展示形式，下载按钮
        .rt-col {
            display: flex;
            display: -webkit-flex;
            justify-content: flex-end;
            align-items: center;

            .ant-radio-group {
                padding: 6px 10px !important;

                .ant-radio-button-wrapper {
                    height: 28px;
                    line-height: 28px;
                }
            }
        }
        i.iconfont, i.iconfont{
            &:hover{
                color: $primary_color;
            }
        }

        // 切换按钮 入驻企业 line和table切换
        .ant-radio-group.toggle-btn {
            background-color: #F9FAFD;
            padding: 10px;
            line-height: 0;

            .ant-radio-button-wrapper {
                border: none;
                background: none;
            }
            .ant-radio-button-wrapper:focus-within {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            .ant-radio-button-wrapper:hover {
                border: none;
                background: #fff;
            }

            .ant-radio-button-wrapper:not(:first-child)::before {
                display: none;
            }

            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                background-color: #fff;
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
                outline: none;
            }
        }

        p {
            color: #121F3D !important;
        }
    }

    .effect-circle,
    .radar {
        width: 100%;
        height: 500px;
    }

    .ant-table-placeholder {
        border-bottom: none;
    }
}

.invest-tabs {
    .ant-tabs-content.ant-tabs-top-content {
        .charts-box {
            background: none;
        }
    }
}

.echart-full {
    .box_shadow.tabcharts {
        box-shadow: none;
    }
}

// 公司的融资 tabs
.com-invest {
    .investor-col {
        >span {
            >span {
                display: block;
            }
        }
    }

    .ant-tabs-tabpane {
        // height: 300px;
    }

    .ant-empty-normal {
        margin: 100px 0;
    }

    .ant-table-placeholder,
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        border-color: transparent;
        cursor: pointer;
    }

    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
        background: #fff;
    }

    .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
        background: #fff;
    }
}

.invest-tabs,
.industry-tabs {

    .ant-table-placeholder,
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        border-color: transparent;
        cursor: pointer;
    }

    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
        // background: rgba(255, 255, 255, 1);
        // box-shadow: 0px 0px 10px 5px rgba(234, 234, 255, 1);
        background: #fff;
    }

    .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
        background: #fff;
    }
}

// 产业的地理情况
.industry-tabs {

    .ant-tabs-content.ant-tabs-top-content .charts-top li:nth-child(2) span:first-child,
    .ant-tabs-content.ant-tabs-top-content .charts-top li:nth-child(4) span:first-child {
        color: #121F3D;
    }
}