.all-box-about {
    background-color: #ffffff;
    box-sizing: border-box;
    overflow-x: hidden;
    .all-box-t {
        position: relative;
        .main-banner {
            width: 100%;
        }
        .module-one {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding-top: 88px;
            z-index: 10;
            position: absolute;
            left: 0;
            top: 0;
            .one-body {
                width: 1440px;
                height: 100%;
                padding: 180px 140px 0;
                margin: 0 auto;
                position: relative;
                > span {
                    display: block;
                    width: 318px;
                    height: 318px;
                    position: absolute;
                    // animation-name: jump aa;
                    // animation-duration: 15s;
                    // animation-iteration-count: infinite;
                    // animation-timing-function: ease-out;
                    // transform: scale(0.5);
                    animation:jump 15s linear infinite;
                    @keyframes jump {
                        0% {
                            transform: translateY(0);
                            animation-timing-function: ease-in;
                            // transform: scale(0.6);
                        }
             
                        30% {
                            transform: translateY(10px);
                            animation-timing-function: ease-in;
                        }
             
                        60% {
                            transform: translateY(20px);
                            animation-timing-function: ease-in;
                        }
             
                        80% {
                            transform: translateY(30px);
                            animation-timing-function: ease-in;
                        }
             
                        95% {
                            transform: translateY(45px);
                            animation-timing-function: ease-in;
                        }
             
                        15%,
                        45%,
                        70%,
                        85%,
                        100% {
                            transform: translateY(60px);
                            animation-timing-function: ease-out;
                            // transform: scale(1);
                        }
                    }
                    &:nth-of-type(1){
                        top: -240px;
                        left: 213px;
                        transform: translate(0, 0);
                    }
                    &:nth-of-type(2){
                        bottom: -159px;
                        left: 376px;
                        animation-delay: 9s;
                    }
                    &:nth-of-type(3){
                        bottom: 39px;
                        right: -159px;
                        animation-delay: 6s;
                    }
                    &:nth-of-type(4){
                        top: 88px;
                        right: 146px;
                        animation-delay: 3s;
                    }
                }
                .ball {
                    width: 318px;
                    // position: absolute;
                    // // animation-name: jump aa;
                    // // animation-duration: 15s;
                    // // animation-iteration-count: infinite;
                    // // animation-timing-function: ease-out;
                    // // transform: scale(0.5);
                    // animation:jump 15s linear infinite;
                    opacity: 0;
                    animation:aa 3s forwards;
                    @keyframes aa {
                        0% {
                            // transform: scale(0.6);
                            opacity: 0;
                        }
             
                        100% {
                            // transform: scale(1);
                            opacity: 1;
                        }
                    }
                   
                    
                    // @keyframes ball {
                    //     0%{
                    //         transform: translateY(10px);
                    //     }
                    //     100%{
                    //         transform: translateY(-20px);
                    //     }
                    //   }
                    // &:nth-of-type(1){
                    //     top: -240px;
                    //     left: 213px;
                    //     transform: translate(0, 0);
                    // }
                    // &:nth-of-type(2){
                    //     bottom: -159px;
                    //     left: 376px;
                    //     animation-delay: 9s;
                    // }
                    // &:nth-of-type(3){
                    //     bottom: 39px;
                    //     right: -159px;
                    //     animation-delay: 6s;
                    // }
                    // &:nth-of-type(4){
                    //     top: 88px;
                    //     right: 146px;
                    //     animation-delay: 3s;
                    // }
                }
            }
            .title {
                font-size: 80px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 120px;
            }
            .intro {
                font-size: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 30px;
            }
        }
    }
    .module-two {
        width: 100%;
        margin: 0 auto;
        z-index: 100;
        background-color: #FBF9F9;
        .two-body {
            width: 1440px;
            padding: 90px 205px;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            text-align: center;
            margin: 0 auto;
        }
    }
    .module-three {
        background: #FBF9F9;
        width: 100%;
        height: 1700px;
        overflow-y: hidden;
        .three-body {
            width: 1440px;
            margin: 0 auto;
            padding: 0 80px 90px;
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 66px;
                height: 500px;
                &:nth-last-of-type(1){
                    margin-bottom: 0;
                }
                > div {
                    flex: 1;
                }
                .title {
                    font-size: 52px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 64px;
                    position: relative;
                    &:after {
                        content: '';
                        width: 154px;
                        height: 4px;
                        background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                        border-radius: 2px;
                        position: absolute;
                        left: 0;
                        top: -18px;
                    }
                }
                .profile {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28px;
                    margin-top: 30px;
                }
                img {
                    width: 600px;
                    &.right-img {
                        margin-left: 88px;
                    }
                    &.left-img {
                        margin-right: 88px;
                    }
                }
            }
        }
    }
    .module-four {
        background: #fff;
        width: 100%;
        .four-body {
            width: 1440px;
            margin: 0 auto;
            padding: 0 100px 90px;
            .title {
                font-size: 52px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-top: 66px;
            }
            .info {
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left {
                    > div {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        &:nth-of-type(1) {
                            margin-bottom: 32px;
                        }
                        img {
                          width: 40px;
                          margin-right: 12px;  
                        }
                    }
                }
                .right {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    img {
                        width: 120px;
                        height: 120px;
                        background: #FEFEFE;
                        border-radius: 8px;
                        border: 1px solid #000000;
                        margin-right: 14px;  
                    }
                }
            }
        }
    }
}