// 感兴趣的标签选择modal
.label-modal,.usertype-modal{
    .ant-modal-close{
        display: none;
    }
    .ant-modal-header{
        border: none;
        padding: 24px;
        .ant-modal-title{
            text-align: center;
            font-size: 24px;
            color: #121F3D;
            line-height: 40px;
        }
    }
    .ant-modal-body{
        padding: 10px 50px;
    }
}
.label-select{
    padding-top: 20px;
    &::after{
        content: '';
        clear: both;
        display: block;
        overflow: hidden;
    }
    .tags-box{
        margin-bottom: 40px;
        >h1{
            color: #121F3D;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
        }
        >div.box_shadow{
            padding: 20px;
            border-radius: 0;
            box-shadow: none;
            >span.ant-tag{
                font-size: 14px;
                border: 1px solid #333333;
                color: #333333;
                background:#fff;
                padding: 3px 10px;
                margin: 5px 20px 5px 0;
                cursor: pointer;
                &:hover{
                    background: #333333;
                    border-color: #333333;
                    color: #fff;
                }
            }
            >span.ant-tag-checkable-checked{
                background: #F6F6F6;
                color: #9292A3;
                cursor: not-allowed;
                border-color: transparent;
            }
        }
    }
    // 选中列表
    .checked-box{
        margin: 20px 0 0;
        border-top: 1px solid #EBEBEB;
        padding-top: 20px;
        justify-content: space-between;
        align-items: flex-start;
        .ant-btn{
            color: #9292A3;
            border: none;
            box-shadow: none;
        }
        .ant-tag{
            background-color: #333333;
            color: #fff;
            padding: 3px 10px;
            margin: 5px 18px 5px 0;
            font-size: 14px;
            border-color: #333333;
            .anticon.anticon-close{ color: #fff; }
        }
    }
}
.usertype-modal,.label-select{
    // 保存按钮
    .save-label{
        text-align:right;
        font-size: 20px;
        color: #333333;
        cursor: pointer;
        border: none;
        float: right;
        margin-bottom: 50px;
        background-color: transparent;
        box-shadow: none;
        >i{
            font-size: 24px;
            vertical-align: middle;
            margin-left: 5px;
        }
    }
    .ant-btn.save-label[disabled]{
        color: rgba(0,0,0,.25);
        cursor: not-allowed;
        &:hover{
            background-color: transparent;
        }
    }
}

.usertype-modal{
    .usertype_div{
        margin-top: 50px;
        &::after{
            content: '';
            clear: both;
            display: block;
            overflow: hidden;
        }
    }
    .type-list{
        width: 21%;
        margin: 0 2%;
        display: inline-block;
        text-align: center;
        padding: 20px 40px 0px;
        border: 1px solid transparent;
        vertical-align: top;
        margin-bottom: 50px;
        cursor: pointer;
        >span{
            display: inline-block;
            margin: 15px 0;
            height: 52px;
            color: #121F3D;
            font-size: 18px;
            line-height: 26px;
            font-weight: 500;
        }
    }
    .type-list.hover{
        background-color: #fff;
        box-shadow:0px 2px 10px 0px rgba(240,242,247,1);
        border-radius: 12px;
    }
    .type-list.active{
        border: 1px solid #2222DA;
        border-radius: 12px;
    }
}