.all-box-research {
    background-color: #ffffff;

    .research-body {
        width: 1160px;
        margin: 66px auto 0;

        .ant-breadcrumb {
            margin: 40px 0;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;

            a,
            span {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
            }
        }

        .all {

            .leftbox {
                margin-right: 317px;
                width: 100%;
    
                .title {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #121F3D;
                    line-height: 33px;
                    margin: 50px 0 30px 0;
                }
    
                .aut_time{
                    .aut{
                        margin-right: 20px;
                    }
                }
    
                >hr{
                    border: 1px solid #eeeeee;
                    margin: 39px 0 49px 0;
                }
                .content {
                    text-align: justify;
                    width: 1200px;
                    >p{
                        >img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}