.nologin-home{
    min-width: 1240px;
    // 导航
    .nav{
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 120;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 3px 4px 0px #e8eaee;
        .flex_box{
            margin: 0 auto;
            height: 66px;
            line-height: 66px;
            align-items: center;
            justify-content: space-between;
            padding:  0 40px;
            max-width: 1440px;
            .ant-btn{
                border-color: #2222DA;
                color: #182390;
            }
        }
    }
    h1.title{
        text-align: center;
        font-size:32px;
        font-weight:500;
        color:rgba(18,31,61,1);
        line-height:45px;
        span{
            display: block;
            font-size: 14px;
            font-weight:400;
            color:rgba(144,148,160,1);
            line-height:32px;
        }
    }
    // top_ads
    .top-banner{
        padding-top: 60px !important;
        justify-content: space-between;
        h1{
            font-size:44px;
            font-weight:500;
            color:rgba(18,31,61,1);
            line-height:54px;
        }
        h2{
            font-size:20px;
            font-weight:400;
            color:rgba(121,121,131,1);
            line-height:28px;
            margin-bottom: 40px;
        }
        p{
            .ant-btn{
                border-radius:10px;
                margin-right: 20px;
                padding: 0 25px;
                height: 50px;
                color: #fff;
                font-size: 16px;
                line-height: 50px;
                &:first-child{
                    background:rgba(34,34,218,1);
                    box-shadow:0px 11px 16px 0px rgba(34,34,218,0.18);
                }
            }
        }
        >img{
            max-width: 600px;
            display: inline-block !important;
        }
    }
    // 数据驱动
    .data{
        position: relative;
        &::before{
            width: 100%;
            height: 500px; 
            position: absolute; left: 0;right: 0; top: 0; 
            bottom: 0;
            z-index: -1; content: ''; 
            border-radius: 100% 100% 0 0; 
            background:linear-gradient(180deg,rgba(131,173,229,0.15) 0%,rgba(255,244,229,0) 100%);
        }
        .container{
            padding-top: 80px;
        }
        dl{
            width: 25%;
            display: inline-block;
            text-align: center;
            padding: 20px 40px;
            vertical-align: top;
            margin-top: 40px;
            dt{
                margin: 20px 0;
                font-size:20px;
                font-weight:400;
                color:rgba(18,31,61,1);
                line-height:28px;
            }
            dd{
                font-size:15px;
                font-weight:400;
                color:rgba(144,148,160,1);
                line-height:26px;
            }
        }
    }
    // 科创专家
    .sci{
        margin-top: 100px;
        justify-content: space-between;
        dl{
            dt{
                font-size:30px;
                font-weight:500;
                color:rgba(18,31,61,1);
                line-height:42px;
                margin-bottom: 30px;
            }
            dd.des{
                font-size:15px;
                font-weight:400;
                color:rgba(18,31,61,1);
                line-height:33px;
                width: 500px;
                margin-bottom: 40px;
            }
            dd.list{
                vertical-align: middle;
                line-height: 32px;
                margin: 20px 0;
                font-size:16px;
                font-weight:400;
                color:rgba(18,31,61,1);
                line-height:28px;
                .iconfont{
                    width: 32px;
                    text-align: center;
                    display: inline-block;
                    font-size: 20px;
                    margin-right: 20px;
                    color: #2222DA;
                }
                .icon-home-sci1,.icon-home-sci2{
                    font-size: 24px;
                }
            }
        }
    }
    // 数字
    .nums{
        padding-bottom: 200px;
        dl{
            width: 20%;
            display: inline-block;
            margin-top: 100px;
            text-align: center;
        }
        dt{
            font-size:56px;
            font-family:Roboto-Regular,Roboto;
            font-weight:400;
            color:rgba(18,31,61,1);
            line-height:66px;
            span{
                font-size:24px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                color:rgba(18,31,61,1);
                line-height:33px;
                display: inline-block;
                margin-left: 10px;
            }
        }
        dd{
            margin-top: 20px;
            font-size:16px;
            font-weight:400;
            color:rgba(18,31,61,1);
            line-height:28px;
        }
    }
    // 解决方案
    .solve{
        background-color: #F9FAFD;
        padding-top: 50px;
        dl{
            display: inline-block;
            width: 20%;
            margin: 40px 2.5%;
            text-align: center;
            padding: 30px;
            vertical-align: top;
            cursor: pointer;
            &:hover{
                background-color: #fff;
            }
            dt{
                font-size:20px;
                font-weight:500;
                color:rgba(18,31,61,1);
                line-height:28px;
                margin: 20px 0;
            }
            dd{
                font-size:14px;
                font-weight:400;
                color:rgba(144,148,160,1);
                line-height:26px;
            }
        }
    }
    // 评价
    .evaluate{
        padding-bottom: 100px;
        >.flex_box{
            margin-top: 50px;
            justify-content: space-between;
            align-items: center;
            >.anticon{
                font-size: 36px;
                color: #C5C5CF;
            }
        }
        .ant-tabs{
            width: 70%;
            margin: 0 auto;
            .ant-tabs-content{
                padding: 20px 40px;
            }
        }
    }
}
// 底部
footer.layout-footer{
    padding: 50px 100px;
    margin-top: 0;
    justify-content: space-between;
    img{
        margin-bottom: 40px;
    }
    >div{
        flex-direction: column;
        align-items: flex-start;
        >span{
            font-size:12px;
            font-weight:400;
            color:rgba(197,197,207,1);
            line-height:35px;
        }
    }
    a{
        margin-right: 50px;
        color: #9094A0;
        line-height: 36px;
        &::before{
            content: '|';
            display: inline-block;
            margin-right: 10px;
        }
    }
    dl{
        margin-bottom: 0;
        margin-right: 100px;
        dt{
            font-size:16px;
            font-weight:500;
            color:rgba(47,40,30,1);
            line-height:30px;
            // margin-bottom: 20px;
        }
        dd{
            font-size:14px;
            font-weight:400;
            color:rgba(144,148,160,1);
            line-height:35px;
        }
    }
}
footer.has-aside{
    padding-left: 260px;
}
@media (max-width: 1440px){
    .nologin-home{
        .container{
            padding: 20px 100px;
        }
    }
}
