.all-box-demand {
    background-color: #ffffff;
    overflow-x: hidden;
    .all-box-t {
        position: relative;
        .main-banner {
            width: 100%;
        }
        .module-one {
            width: 100%;
            height: 100%;
            margin: 88px auto 0;
            padding: 0 110px;
            z-index: 10;
            position: absolute;
            left: 0;
            top: 0;
            .one-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 1200px;
                margin: 0 auto;
                @keyframes go{
                    from{
                        opacity: 0;
                        // bottom: 0px;
                        // right: 0px;
                    }to{
                        opacity: 1;
                        // bottom: 42px;
                        // right: 42px;
                    }
                }
                .title {
                    font-size: 66px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin: 78px auto 46px;
                    width: 100%;
                    opacity: 0;
                    animation: go 1s ease-in 1s;
                    animation-fill-mode: forwards;
                    > div:nth-of-type(1){
                        width: 100%;
                        text-align: left;
                    }
                    > div:nth-of-type(2){
                        width: 100%;
                        text-align: right;
                    }
                }
                .intro-box {
                    display: flex;
                    margin: 0 auto;
                    .intro-ban {
                        width: 572px;
                        opacity: 0;
                        &:nth-of-type(1) {
                            margin-right: 58px;
                            animation: go 1s ease-in 2s;
                            animation-fill-mode: forwards;

                        }
                        &:nth-of-type(2) {
                            animation: go 1s ease-in 3s;
                            animation-fill-mode: forwards;
                        }
                    }
                }
            }
        }
        
    }
    .module-two {
        width: 1440px;
        margin: 90px auto 0;
        padding: 0 80px;
        position: relative;
        .two-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                font-size: 52px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
            }
            .intro-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 84px;
                .item {
                    width: 300px;
                    height: 326px;
                    border-radius: 10px;
                    position: relative;
                    border: 1px solid rgba(0,0,0,0.12);
                    // overflow: hidden;
                    .default-item {
                        padding: 34px 24px; 
                    }
                    .type-icon {
                        width: 72px;
                    }
                    .little-title {
                        margin-top: 40px;
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        position: relative;
                        &:after {
                            content: '';
                            width: 46px;
                            height: 2px;
                            background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                            border-radius: 1px;
                            position: absolute;
                            left: 0;
                            bottom: -10px;
                        }
                    }
                    .intro {
                        margin-top: 20px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }
                    .symbol-icon {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    @keyframes active_move
                    {
                        from {top:300px;}
                        to {top:0px;}
                    }
                    /* Safari and Chrome */
                    @-webkit-keyframes active_move  
                    {
                        from {top:300px;}
                        to {top:0px;}
                    }
                    &:hover {
                        background: linear-gradient(180deg, #FFB0B0 0%, rgba(255,255,255,0) 100%);
                        border-color: transparent;
                        box-shadow: inset 4px 4px 24px 0px rgba(255,120,120,0.2), inset -8px -8px 24px 0px rgba(250,33,0,0.04);
                        // border-image: linear-gradient(141deg, rgba(255, 146, 146, 0.9), rgba(255, 255, 255, 0.25)) 1 1;
                        border: none;
                        .default-item {
                            display: none;
                        }
                        .active-item {
                            display: block;
                            // animation-duration: 1.5s;
                            // animation-fill-mode: both;
                            // animation-name: active_move;
                        }
                    }
                }
                .active-item {
                    display: none;
                    position: absolute;
                    top: 0px;
                    transform:translate(0px,0px);
                    padding: 30px;
                    .little-title {
                        margin-top: 0;
                    }
                    .info {
                        margin-top: 16px;
                        .info-item {
                            display: flex;
                            font-size: 15px;
                            font-family: PingFangSC-Light, PingFang SC;
                            font-weight: 300;
                            color: #666666;
                            line-height: 24px;
                            .lable {
                                width: 4px;
                                height: 4px;
                                background: #555555;
                                margin-right: 8px; 
                                margin-top: 8px;
                            }
                            .text {
                                flex: 1;
                            }
                        }
                    }
                }
            }
        }
        .zhedang {
            position: absolute;
            bottom: -120px;
            width: 100%;
            height: 120px;
            background: #fff;
            margin: 0 auto;
        }
    }
    .module-three {
        background: #FBF9F9;
        width: 100%;
        border-top: 120px solid #fff;
        z-index: 100;
        min-height: 3600px;
        overflow-y: hidden;
        .three-body {
            width: 1440px;
            margin: 0 auto;
            padding: 98px 80px 100px;
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 66px;
                // display: none;
                height: 500px;
                // opacity: 0;
                &:nth-last-of-type(1){
                    margin-bottom: 0;
                }
                > div {
                    flex: 1;
                }
                .title {
                    font-size: 52px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 64px;
                    position: relative;
                    &:after {
                        content: '';
                        width: 154px;
                        height: 4px;
                        background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                        border-radius: 2px;
                        position: absolute;
                        left: 0;
                        top: -18px;
                    }
                }
                .profile {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28px;
                    margin-top: 30px;
                }
                img {
                    width: 600px;
                    &.right-img {
                        margin-left: 88px;
                    }
                    &.left-img {
                        margin-right: 88px;
                    }
                }
            }
            .item-active {
                opacity: 1;
            }
            @keyframes active_move
            {
                from {margin-top:300px;}
                to {margin-top:0px;}
            }
            /* Safari and Chrome */
            @-webkit-keyframes active_move  
            {
                from {margin-top:300px;}
                to {margin-top:0px;}
            }
            .active-item {
                display: block;
                // animation-duration: 1.5s;
                // animation-fill-mode: both;
                // animation-name: active_move;
            }
        }
    }
}
