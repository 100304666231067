// antd empty 二次封装的空白区域
.update-data{
    display: flex;
    display: -webkit-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 20px 0;
    img{
        width: 36px;
        height: 36px;
    }
    span{
        color: #595959;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
        display: inline-block;
        margin-left: 10px;
    }
}

// 自定义空白图片
.custom-empty{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    img{
        max-height: 70%;
    }
}

.compare-empty{
    >img{
        width: 100px;
        height: 91px;
    }
}