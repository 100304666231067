.individual{
    margin: 0 auto;
    padding-top: 30px;
    .section-title{
        padding: 20px 0;
        margin: 0;
        margin-top: 20px;
        font-weight: 600;
        font-size: 20px;
        color: #121f3d;
        img{
            margin-left: 6px;
        }
    }
    .main{
        display: flex;
        .recommend{
            // min-width: 740px;
            flex: 1;
            margin-right: 40px;
            .box-title {
                padding-left: 5px;
                font-weight: 600;
                font-size: 16px;
                color: #000;
            }
        }
    }
    .label-box{
        position: relative;
        .label{
            width: 86px;
            height: 34px;
        }
        .text{
            position: absolute;
            top: 0;
            left: 34px;
            font-size: 14px;
            font-weight: 600; 
        }
    }
}
.industry{
    .icon-explain-tip{
        font-size: 14px;
        color: #9094A0;
        margin-left: 10px;
        font-weight: 500;
    }
    .box{
        display: flex;
        justify-content: space-between;
        padding: 30px;
        box-shadow: 0px 2px 18px 0px rgb(240, 242, 247);
        border-radius: 14px;
        padding-right: 58px;
        .itemList{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .borders{
            width: 1px;
            height: 20px;
            background: #e8e8e8;
        }
        .item{
            position: relative;
            width: 150px;
            padding-left: 28px;
            text-align: center;
            // border-left: 1px solid #e8e8e8;
           
            .nums{
                font-weight: 600;
                font-size: 24px;
                color: #1e2e42;
            }
            .text{
                font-size: 12px;
                color: #9292A3;
            }
        }
        
        .item:first-child{
            border: none;
        }
    }
}
.heading{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
    -ms-flex-pack:justify;
    justify-content:space-between;
    margin-bottom: 15px;    
    .cell{
        // flex: 1;
        max-width: 200px;
        width: 25%;
        padding: 30px;
        text-align: center;
        border: 1px solid transparent;
        box-shadow: 0px 2px 18px 0px rgb(240, 242, 247);
        border-radius: 14px;
        .text{
            margin-top: 10px;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 20px;
            color: #121f3d;
        }
    }
    .cell:hover{
        border-color:#2222da;
    }
}
.nopark.heading{
    .cell{
        max-width: 262px;
    }
}

.news{
    padding-top: 30px;
    padding-bottom: 60px;
    width: 350px;
    .head{
        padding-top: 10px;
        position: relative;
        .text{
            
            font-weight: 600;
            font-size: 20px;
            color: #121f3d;
        }
        .more{
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 14px;
            color: #9292a3;
        }
    }
    .box{
        padding-right: 2px;
        .item{
            padding-top: 30px;
            .title{
                font-size: 16px;
                color: #232f3f;
                font-weight: 600;
            }
            a:hover {
                color:#182390
            }
            .content{
                max-height: 140px;
                overflow: hidden;
                padding: 14px 0 4px;
            }
            .time{
                font-size: 12px;
                color: #9292a3;
            }
        }
        .item:first-child{
            padding-top: 15px;
        }
    }
}

.rank{
    position: relative;
    padding: 30px 30px 0 30px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 6px 0px rgb(234,234,255);
    .label-wrap{
        position: absolute;
        top:0;
        right: -6px;
    }
    .rank-title{
        display: block;
        padding-left: 5px;
        font-size: 16px;
        color: #232f3f;
        font-weight: 700;
        border-left: 3px solid #eee;
        /*推荐 人物学校 职位 */
        span{
            color: #888;
            margin-left: .5em;
        }
    }
    .list{
        padding: 30px 50px 30px 40px;
        // padding-left: 40px;
        .cell{
            display: flex;
            margin: 10px 0;
            height: 30px;
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            color: #121f3d;
            .order{
                width: 25px;
                margin-right: 24px;
                text-align: center;
            }
            .name,.school{
                flex:1;
            }
            .school{
                text-align: right;
            }
            .name{
                font-size: 16px;
                color: #000;
            }
        }
        .cell:first-child{
            background: url('~assets/images/board/first.png') no-repeat;
        }
        .cell:nth-child(2){
            background: url('~assets/images/board/second.png') no-repeat;
        }
        .cell:nth-child(3){
            background: url('~assets/images/board/third.png') no-repeat;
        }
    }
    .tech{
        display: flex;
        margin-bottom: 20px;
        padding: 0;
        .tech-pic{
            margin-top: 20px;
            margin-right: 30px;
            width: 145px;
            height: 80px;
        }
        .content {
            padding-top: 20px;
            .ap-wrap{
                width: 420px;
                height: 24px;
                overflow: hidden;
                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis;
                .application{
                    padding: 2px;
                    font-size: 12px;
                    border-radius: 4px;
                    cursor: default;
                    // background-color: #FFF1E2;
                    // color: #ED6400;
                    background-color: #F0F3FD;
                    color:#2222DA;
                }
            }
        }
    }
    .tech:last-child{
        margin-bottom: 0;
    }
    .company-item{
        display: flex;
        margin-bottom: 20px;
        
        .graph{
            width: 270px;
            margin: 20px;
            .df{
                height: 100px;
                width: 100px;
                overflow: hidden;
                line-height: 100px;
                background-color: #F6F8FF;
                text-align: center;
                border-radius: 8px;
            }
            .avatar {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                height: 100px;
            }
        }
        .right{
            flex: 1;
            margin-top: 15px;
            div{
                margin: 6px 0;
            }
            .head{
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 10px;
                span{
                    margin: 0 6px;
                    color: #f85b00;
                }
            }
            .index{
                font-size: 18px;
                font-weight: 600;
                color: #232f3f;
            }
            .labels{
                display: flex;
                flex-flow: row wrap;
                bottom: 10px 0;
                span{
                    display: block;
                    font-size: 12px;
                    margin-right: 1em;
                    margin-bottom: 1em;
                    padding: 0  10px;
                    background-color: #F5F0FF;
                    color: #784fed;
                    border-radius: 3px;
                }
            }
            .bar{
                display: inline-block;
                // height: 18px;
                padding-left: 2px;
                font-weight: 500;
                background-color: #e3efff;
                border-radius: 2px;
            }
        }
    }
    .company-item:last-child{
        margin-bottom: 0;
    }
    .person-item{
        display: flex;
        margin-top: 20px;
        // margin-bottom: 20px;
        .left{
            width: 130px;
            height: 130px;
            // margin-right: 30px;
            .df{
                overflow: hidden;
                line-height: 100px;
                background-color: #F6F8FF;
                text-align: center;
                border-radius: 8px;
            }
            .avatar {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                height: 130px;
            }
        }
        .right{
            padding: 0 20px;
            .index{
                color:#9292a3;
                .index_num{
                    font-weight: 600;
                    font-size: 18px;
                    color: #232F3F;
                }
            }
            .labels{
                padding: 0 20px 0 20px;
                // padding: 20px 0;
                .lsign{
                    display: inline-block;
                    height: 24px;
                    margin-right: 10px;
                    padding: 2px 10px 0 10px;
                    font-size: 12px;
                    border-radius: 4px;
                    cursor: default;
                    background-color: #F5F0FF;
                    color: #764CED;
                    margin-bottom: 5px;
                }
            }
            .head{
                width: 240px;
                // margin-bottom: 40px;
                text-align: center;
                font-weight: 600;
                font-size: 16px;
                color: #121F3D;
            }
        }
    }
}
.teach-rank{
    padding-bottom: 30px;
}