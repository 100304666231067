.news-list{ 
    width: 820px;
    .ant-breadcrumb{
        margin: 20px 0;
    }
    .item{
        margin-bottom: 30px;
        .date{
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            color: #121f3d;
        }
        .bottom{
            display: flex;
            .time{
                padding-top: 14px;
                width: 60px;
                font-size: 15px;
                font-weight: 400;
                color: #9c9c9c;
            }
            .content{
                padding: 14px 20px 0; 
                .title{
                    cursor: default;
                    font-weight: 600;
                    font-size: 18px;
                }
                .title:hover{
                    color: #182390
                }
            }
            .content:hover{
                border-radius: 14px;
                box-shadow: 0px 2px 10px 0px #F0F2F7;
            }
        }
    }
    .page-box{
        padding: 60px 0;
        margin: 0 auto;
        text-align: center;
    }
}