// 园区新闻
.news.public{
    padding-bottom: 0;
    h1{
        justify-content: space-between;
        .ant-btn-link{
            color: #9094A0;
            &:hover{
                color: $primary_color;
            }
        }
    }
    ul{
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    li{
        width: 32%;
        margin-right: 2%;
        height: 150px;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        &:nth-child(3n){
            margin-right: 0;
        }
        &::before{
            content: '';
            width: 100%;height: 100%;
            position: absolute;
            background-color: rgba($color: #000000, $alpha: 0.3);
            z-index: 2;
            border-radius: 6px;
        }
        img{
            position: absolute;
            top: 0;left: 0;
            width: 100%;height: 100%;
            object-fit: cover;
            z-index: 1;
            border-radius: 6px;
        }
        dl{
            position: absolute;
            left: 15px;bottom: 0;
            width: calc( 100% - 30px );
            color: #fff;
            margin-bottom: 0;
            z-index: 3;
            dd{
                opacity: .7;
            }
        }
    }
}
// 相关政策
.policy.public{
    margin-bottom: 100px;
    li{
        justify-content: space-between;
        line-height: 30px;
        font-size: 15px;
        a{
            color: #100C0B;
            >span{
                font-weight: 600;
            }
            &:hover{
                color: $primary_color;
            }
        }
    }
}