.policy-page{
    padding-top: 20px;
    .content{
        .head{
            padding-bottom: 30px;
            border-bottom: 2px solid #eeeeee;
            .title{
                padding: 60px;
                text-align: center;
                font-weight: 600px;
                font-size: 32px;
                color: #121f3d;
            }
            .date{
                text-align: center;
                font-size: 14px;
            }
        }
        .main-body{
            padding-top: 20px;
            line-height: 36px;
            font-size: 16px;
            font-weight: 500;
        }
        .append{
            padding-top: 30px;
           .down{
               display: block;
               font-size: 14px;
               line-height: 24px;
               color: #182390;
           } 
        }
    }
}