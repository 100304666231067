
.expresslist{
    .ant-breadcrumb{
        line-height: 0;
        margin-top: 20px;
    }
    .contain{
        margin-top: 30px;
        .item{
            margin-bottom: 30px;
            .date{
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-bottom: 30px;
            }
            .title-box{
                height: 22px;
                display: flex;
                align-items: center;
                .time{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    margin-right: 40px;
                }
                .title{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 800;
                    color: #333333;
                }
            }
            .introduce{
                margin-top: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                padding-bottom: 30px;
                border-bottom: 1px solid #EEEEEE;
                margin-left: 80px;
            }
        }
        .btn-box{
            margin-left: 80px;
            cursor: pointer;
            .btn{
                width: 120px;
                height: 46px;
                border: 1px solid #333333;
                margin: 0 auto;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 46px;
                text-align: center;
            }
        }
    }
}
