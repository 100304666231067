.all-box {

    .protocol-body {
        width: 1300px;
        margin: 66px auto 0;
        background-color: #ffffff;
        padding-bottom: 120px;
        .part-one {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-size: 34px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 48px;
                margin: 100px 0 60px 0;
            }

            p {
                // width: 408px;
                height: 33px;
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 33px;

            }

            .content {
                width: 1100px;
                height: 120px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 40px;

            }
        }

        .part-two {
            display: flex;
            flex-direction: column;
            align-items: center;

            .tit {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 33px;
                margin: 60px 0 20px 0;
            }

            .con {
                width: 1100px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 40px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .protocol-body {
        width: 1160px;
    }
}
