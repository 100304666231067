@import 'styles/_mixin';


.fiter-search {
    overflow: visible !important;
}
.fiter-search-input {
    position: relative;
    .custom-search{
        width: 320px;
    }
    .results {
        position: absolute;
        top: 37px;
        left: 0;
        z-index: 100;
        width: 320px;
        height: 160px;
        overflow: auto;
        @include customScrollBar(160px);
    }
    .result-box {
        width: 100%;
        background-color: #fff;
        padding-bottom: 5px;
        li {
            padding: 5px 12px;
            cursor: pointer;
            &:hover{
                background-color: #333;
                color: #fff;
            }
        }
    }
}
