.individuation-charts{
    .indi-title{
        font-size: 22px;
        color: #252F40;
        font-weight: 600;
        border-bottom: 2px solid #333;
        padding-bottom: 15px;
        margin: 20px 0 30px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .ant-input-affix-wrapper,.ant-input{
        width: 256px;
        margin-right: 10px;
        height: 26px;
        line-height: 26px;
        vertical-align: top;
        color: transparent;
        cursor: pointer;
        text-shadow: 0 0 0 #333;
    }
    .ant-btn{
        height: 26px;
        padding: 0 10px;
    }
    .select-box{
        position: relative;
        .steps-content.select-steps-custom{
            &::after{
                width: 1200px;
            }
        }
    }
    // 产业的可视化图表 左右布局
    .ant-row{
        margin-top: 50px;
        .barline{
            width: 100%;
            height: 400px;
        }
        // 可视化另一侧的数据展示
        .indi-industry-des{
            justify-content: space-between;
            flex-direction: column;
            align-items: stretch;
            padding-top: 70px;
            padding-left: 20px;
            li{
                background-color: #F9F9F9;
                list-style: none;
                padding: 15px 20px;
                align-items: center;
                text-align: left;
                >p{
                    width: 50%;
                    margin-bottom: 0;
                    >span{
                        display: block;
                        &:first-child{
                            color: #999;
                        }
                        strong{
                            font-size: 26px;
                            color: #2c0000;
                            margin-right: 10px;
                        }
                    }
                    span.blue-num{
                        color: #263895 !important;
                        font-weight: 600;
                    }
                    span.green,span.red{
                        margin-left: 6px;
                        font-weight: 600;
                        >i{
                            font-size: 12px;
                        }
                    }
                    span.green{
                        color: seagreen;
                    }
                    span.red{
                        color: tomato;
                        i{
                            vertical-align: text-top;
                        }
                    }
                    &:first-child{
                        border-right: 1px solid #eee;
                    }
                    &:last-child{
                        padding-left: 20px;
                        >span{
                            color: #666;
                        }
                    }
                }
            }
        }
    }
    // 轮次分布的数据展示
    .scatter-row{
        .barline{
            height: 450px;
            width: 100%;
        }
        .ant-col-16{
            padding: 40px 0;
        }
        .indi-industry-des{
            padding: 20px 40px 0 0;
            >li{
                >span{
                    display: block;
                    &:first-child{
                        color: #999;
                    }
                    strong{
                        font-size: 26px;
                        color: #2c0000;
                        margin-right: 10px;
                    }
                }
            }
        }

    }
    // 人物影响力
    .effect-person-charts{
        .effect-scatter,.effect-bar{
            width: 100%;
            height: 400px;
        }
    }
    .indi-collection-tech{
        .tech-cloud{
            height: 350px;
            width: 100%;
        }
        .indi-title{
            a{
                font-size: 14px;
                color: #333333;
            }
        }
        .indi-collections{
            justify-content: space-around;
            flex-wrap: wrap;
            >a{
                width: 30%;
                border: 1px solid #333;
                padding: 10px;
                margin-bottom: 20px;
                img{
                    display: inline-block;
                    width: 100%;
                    height: 81px;
                    object-fit: contain;
                }
                dl{
                    margin-bottom: 0;
                    dt{
                        font-size: 14px;
                        color: #333;
                        line-height: 18px;
                        margin: 6px 0;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                    dd{
                        font-size: 12px;
                        color: #666;
                        line-height: 18px;
                        overflow: hidden;
                        display: -webkit-box; 
                        -webkit-box-orient: vertical; 
                        -webkit-line-clamp: 2; 
                        word-break: break-al
                    }
                }
                
            }
        }
    }
}

.input-mask{
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    background-color: rgba($color: #fff, $alpha: .1);
    display: block;
}
.hidden-mask.input-mask{
    display: none;
}