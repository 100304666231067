.research {

    .all {

        .leftbox {
            margin-right: 317px;
            width: 100%;

            .title {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #121F3D;
                line-height: 33px;
                margin: 50px 0 30px 0;
            }

            .aut_time{
                .aut{
                    margin-right: 20px;
                }
            }

            >hr{
                border: 1px solid #eeeeee;
                margin: 39px 0 49px 0;
            }
            .content {
                text-align: justify;
                width: 1200px;
                >p{
                    >img{
                        width: 100%;
                    }
                }
            }

            >p {
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #232F3F;
                line-height: 26px;
                margin-top: 70px;
            }
            .hr{
                border: 1px solid #333333;
            }

            .footer {

                .hr {
                    border: 1px solid #333333;
                }

                display: flex;
                margin-top: 50px;
                justify-content: space-between;

                .image {
                    .imgage {
                        width: 250px;
                        height: 140px;
                        border-radius: 4px;
                    }

                    .title {
                        width: 250px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #121F3D;
                        line-height: 20px;
                        margin: 20px 0 8px 0;
                    }

                    .time {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9094A0;
                        line-height: 20px;
                    }
                }

                .image:hover {
                    cursor: pointer;

                    .title {
                        color: #182390;
                    }
                }

            }

            .footer:hover {
                cursor: pointer;
            }
        }
    }
}

.tip:hover {
    cursor: pointer;
}

.right-list:hover {
    cursor: pointer;
}