// 产业链
.chain-box{
    // 当产业链为一个的时候 公司会存在多个
    // .ant-tabs-bar.ant-tabs-top-bar{
    //     display: none;
    // }
    .chain-tabs{
        .ant-row-flex{
            justify-content: space-between;
        }
        h1{
            width: 100%;
            height: 70px;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border-radius: 6px;
            background-color:#232F3F;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;
            strong{
                font-size: 18px;
            }
        }
        .ant-col.ant-col-7{
            &:nth-child(3){
                h1{
                    background-color:#232F3F;
                }
            }
            &:nth-child(5){
                h1{
                    background-color: #232F3F;
                }
            }
        }
        .ant-row{
            display: flex;
            justify-content: space-between;
        }
        .ant-card{
            // border-style: dashed;
            // border-radius: 10px;
            margin-bottom: 20px;
            background-color: #F9F9F9;
            .ant-card-head{ border: none; }
            .ant-card-head-wrapper{
                display: inline;
            }
            .ant-card-head-title{ 
                padding: 0; 
                background-color: #232F3F;
                padding: 3px 10px;
                border-radius: 0 0 5px 5px;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 14px;
                max-width: 270px;
            }
            .ant-card-body{
                padding: 0 20px 20px;
                a{
                    color: #7C8793;
                    // text-decoration: underline;
                    margin-right: 15px;
                    &:hover{
                        color: #2222DA;
                    }
                }
                .target{
                    color: #2222DA;
                    font-weight: 500;
                }
            }
        }
        .arrow-col{
            line-height: 70px;
            height: 70px;
            text-align: center;
            img{
                width: 35px;
            }
        }
    }
}

.no-chain-box{
    // 当产业链为一个的时候 公司会存在多个
    .ant-tabs-bar.ant-tabs-top-bar,.ant-tabs-nav{
        display: none;
    }
}
@media (max-width:1200px) {
    .chain-box .chain-tabs .ant-card .ant-card-head-title{
        max-width: 180px;
    }
}


