$primary_color:#333333;

.tech{
    .page-title{
        padding-top: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #121f3d;
        width: 80px;
    }
    .title{
        font-size: 16px;
        color: #262626;
        margin-right: 20px;
    }

    .column{
        position: relative;
        padding-top: 10px;
        padding-bottom: 30px;
        border-bottom: solid 2px #333333;
        margin-bottom: 30px;
        .column-title {
            position: absolute;
            top: 16px;
            left: 0;
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: left;
            font-size: 16px;
            color: #333333;
            font-weight: 800;
        }
        .category {
            padding-left: 80px;
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            .cate-label {
                &:nth-child(1){
                    font-size: 16px !important;
                }
                height: 30px;
                // padding: 0 10px;
                padding: 5px;
                line-height: 30px;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                margin-right: 40px;
            }
            .active {
                background: none;
                font-size: 18px;
                color: $primary_color;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 800;
                transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
            }
        }
    }
    .tech-list{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        padding-top: 20px;
        .item{
            width: 380px;
            margin-bottom: 30px;
            padding: 0 20px;
            // margin-right: 20px;
            border-right: 1px solid #EEEEEE;
            height: 168px;
            // background: red;
            .item-box{
                padding: 20px;
            }
            .title{
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: bold;
                color: #232f3f;
            }
            .body{
                display: flex;
                justify-content: space-between;
                .trend{
                    margin-right: 14px;
                    width: 88px;
                    height: 37px;
                }
                .main{
                    flex: 1;
                    margin-left: 20px;
                    .list-i{
                        width: 184px;
                        overflow: hidden;
                        white-space: nowrap; 
                        text-overflow: ellipsis;
                        .tips{
                            display: inline-block;
                            width: 90px;
                            font-size: 12px;
                            color: #999999;
                        }
                        .text{
                            color: #333333;
                        }
                    }
                }
            }
            .application{
                // width: 326px;
                padding-top: 14px;
                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis;
                color: #a4a4b3;
                >span{
                    padding: 0 .5em;
                    // background-color: #FFF1E2;
                    // color: #ED6400;
                    border-radius: 4px;
                    color: #1F3992;
                    background-color: #eeeeee;
                }
            }
        }
        .item:nth-child(3n) {
            margin-right: 0;
            border-right: 0;
        }
        .item:hover{
            // box-shadow: 0px 0px 6px 0px rgb(234, 234, 255); 
            .item-box{
               box-shadow: 0px 0px 0px 1px #333333;
            }
        }
    }

    .page-box{
        padding: 60px 0;
        margin: 0 auto;
        text-align: center;
    }
}