@import "styles/_mixin";

// 封装table
.custom-table {
    position: relative;

    .ant-table-header {
        background: none;
    }

    .ant-table-wrapper {
        margin-top: 30px;
    }

    .ant-table-thead > tr {
        >th {
            // background-color: #fff;
            background-color: transparent;
            border: none;
        }
    }

    // 滚动条
    .ant-table-body {
        @include ScrollBarStyle()
    }
    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
        background:#fff;
    }
    // 居中设置
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        text-align: left;
        border-bottom: none;
        padding-left: 40px;
    }

    .ant-table-pagination.ant-pagination {
        text-align: center;
        float: none;
        padding: 40px 0;
    }
}


.collect-table {
    position: relative;

    .ant-modal-wrap {
        top: 120px;
    }

    // 删除按钮默认隐藏
    .ant-table-row {
        td {
            &:last-child {
                opacity: 0;
            }
        }
    }

    // 删除按钮hover时 显示
    .row-active {
        td {
            &:last-child {
                opacity: 1;
            }
        }
    }
}

// 删除按钮的modal框
.delete-modal {
    top: 50px;

    .ant-modal-body {
        text-align: center;
        padding: 40px 24px 20px;
    }

    .ant-modal-footer {
        text-align: center;
        border-top: none;
        padding: 10px 16px 20px;

        .ant-btn {
            width: 120px;
            height: 40px;

            &:first-child {
                &:hover {
                    color: #D0021B;
                    border-color: #D0021B;
                }
            }
        }

        .ant-btn-primary {
            background-color: #D0021B;
            border-color: #D0021B;
            margin-left: 20px;
        }
    }
}