// 左边sider
.sider-lt{
    background-color: #fff;
    .ant-anchor{
        .ant-anchor-ink{ display: none; }
        .ant-anchor-link{
            line-height: 56px;
            height: 56px;
            color: #C5C5CF;
            font-size: 18px;
            text-align: left;
            padding: 0;
            padding-left: 40px;
            cursor: pointer;
            width: fit-content;
            .ant-anchor-link-title{
                color: #333333;
            }
        }
        .ant-anchor-link-active{
            color: #333;
            font-weight: 600;
            background: #fff !important;
        }
        .ant-anchor-link-title:hover{
            font-weight: 800;
            color: #333;
        }
        .ant-anchor-link-title-active{
            position: relative;
            &::after{
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 6px;
                border-bottom: 2px solid #000;
            }
        }
        .ant-anchor-link:nth-last-child(1){
            margin-bottom: 5px;
        }
        
    }
}
