.all-box {
    background-color: #ffffff;

    .regist-body {
        width: 1040px;
        margin: 106px auto 0;
        display: flex;

        .left {
            margin-right: 90px;

            .one {
                height: 42px;
                font-size: 30px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 42px;
            }

            .two {
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px;

            }

            .three {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 25px;

            }

            .four {
                width: 530px;
                height: 48px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 24px;

            }

            .five {
                height: 24px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 24px;
                margin-bottom: 30px;
            }

            .table{
                width: 530px;
                height: 280px;
                text-align: center;
                overflow: hidden;
                border: 1px solid #EEEEEE;
                display: block;

                th {
                    width: 530px;
                    background: #232F3F;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;


                }

                td {
                    width: 530px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;

                }

                tr {
                    width: 530px;
                    height: 40px;
                }
            }
            
            .table_t {
                width: 530px;
                text-align: center;
                border: 1px solid #EEEEEE;
                display: block;

                th {
                    width: 530px;
                    background: #232F3F;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;


                }

                td {
                    width: 530px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;

                }

                tr {
                    width: 530px;
                    height: 40px;
                }
            }

            .bottom {
                width: 530px;
                height: 45px;
                border: 1px solid #EEEEEE;
                border-top: none;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 45px;
                text-align: center;
                cursor: pointer;
            }
        }

        .right {
            width: 420px;
            height: 660px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.05);
            padding: 30px 40px 24px 30px;

            .regist {
                .input {
                    height: 40px;
                }
                .input-o{
                    margin: 15px 0;
                }

                .btn {
                    height: 40px;
                    width: 100%;
                    background: #232F3F;
                    // border-radius: 4px;
                    span{
                        color: #ffffff;
                    }
                }

                .txt {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;

                }

                ._btn {
                    width: 350px;
                    height: 46px;
                    background: #232F3F;
                    // border-radius: 4px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 20px;
                    border: 1px;
                }

                ._btn:hover {
                    color: #ffffff;
                    background-color: #232F3F;
                }
                
            }
            .ant-checkbox-wrapper-checked{
                .ant-checkbox-checked, .ant-checkbox-inner{
                    background: #232F3F;
                    border-color: #232F3F;
                }
                            
            }
            
        }
    }
}

@media (max-width: 1440px) {
    .report-body {
        width: 1040px;
    }
}