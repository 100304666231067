.search-page{
    padding-top: 20px;
    .searchimg-box{
        width: 100%;
        // height: 200px;
        display: flex;
        // align-items: center;
        justify-content: center;
        padding-bottom: 22px;
        .searchLogoImg{
            width: 200px;
            height: 48px;
        }
    }
    
    // 搜索结果为空
    .empty{
        padding-top: 48px;
        text-align: center;
        img{
            width: 50px;
            height: 60px;
        }
        .title{
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 16px;
            color: #202031;
            span{
                color:#2222DA
            }
        }
        .tips{
            font-size: 14px;
            color: #9094a0;
        }
    }
    .page-box{
        padding: 60px 0;
        margin: 0 auto;
        text-align: center;
    }

    .search-form {
        position: relative;
        width: 940px;
        margin: 0 auto;
        >h5{
            font-size: 14px;
            color: #9292A3;
            line-height: 28px;
        }
        .directions{
            padding: 20px 0;
            margin-bottom: 0;
            font-size: 16px;
            color: #9292a3;
            span{
                cursor: pointer;
                padding: 0 16px;
                border-right: 2px solid #9292a3;
            }
            span:last-child{
                border-right: none;
            }
            .active{
                font-weight: 600;
                font-size: 16px;
                color: #333333;
            }
        }
        .form-box {
            display: flex;
            justify-content: space-between;
            input{
                width: 780px;
                padding: 14px 20px;
                height: 48px;
                // border-radius: 8px;
                border-color: #333333;
                line-height: 0;
            }
            
            button{
                width: 140px;
                height: 50px;
                background-color: #333333;
                // border-radius: 8px;
                &:hover{
                    background-color: #333333;
                    border-color: #333333;
                }
            }
            .ant-btn .ant-btn-primary{
                border-radius: 0;
            }
            
        }
        .suggest-box{
            position: absolute;
            top: 50px;
            width: 832px;
            background-color: #fff;
            // border-radius: 10px;
            z-index: 10;
        }
        .ant-input-affix-wrapper{
            padding: 0 11px;
        }
        .iconfont{
            font-size: 24px;
            color: #bbbbbb;
        }
        
}
// 搜索结果
.result {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 940px;
    margin: 0 auto;
    padding: 40px 0;
    .space{ margin-top: 20px;}
    // 推荐位
    .recommend {
        width: 620px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0px 2px 10px 0px #f0f2f7;
        // border-radius: 14px;
        .head{
            padding-left: 10px;
            font-size: 20px;
            font-weight: 600;
            color: #121f3d;
            border-left: 3px solid #2222DA;
        }
        .subject{
            display: flex;
            align-content: flex-end;
            line-height: 84px;
            .img{
                width: 84px;
                height: 84px;
                overflow: hidden;
                margin-right: 20px;
                text-align: center;
                background-color: #F6F9FE;
                // border-radius: 8px;
                .logo-img{
                    max-width: 84px;
                    max-height: 84px;
                    height: auto;
                }
            }
            .name{
                font-size: 20px;
                font-weight: 600;
                color: #121f3d;
            }
        }
        .theme-box{
            padding: 12px 15px;
            margin-top: 20px;
            background-color: #F9FAFd;
            // border-radius: 8px;
            .title{
                font-weight: 600;
                font-size: 16px;
                color: #121f3d;
            }
            .body{
                display: flex;
                flex-flow: row wrap;
                justify-content: space-around;
                padding: 10px 0;
                .item{
                    padding: 0 40px;
                    text-align: center;
                    border-right: 1px solid #E8E8E8;
                    .nums{
                        font-size: 24px;
                        color: #121f3d;
                    }
                    .orange{
                        color: #F85B00;
                    }
                    .desc{
                        padding-top: 0;
                        font-size: 12px;
                        color: #9292a3;
                    }
                }
                .item:last-child{
                    border: none;
                }
            }
            .desc{
                display: flex;
                flex-flow: row wrap;
                padding-top: 6px;
                .desc-item{
                    flex: 1;
                    min-width: 160px;
                    margin-right: 10px;
                    font-size: 12px;
                    line-height: 2em;
                }
            }
        }
    }
    .content{
        padding-right: 40px;
    }
    .list {
        // padding: 20px 0;
        margin-top: 0;
        .list-item{
            display: flex;
            margin-bottom: 30px;
            .company-logo{
                width: 90px;
                height: 90px;
                overflow: hidden;
                margin-right: 16px;
                line-height: 90px;
                text-align: center;
                background-color: #F6F8FF;
                // border-radius: 8px;
                .logo-img{
                    max-width: 100%;
                    height: auto;
                }
                .df{
                    height: 100px;
                    overflow: hidden;
                    line-height: 100px;
                    background-color: #F6F8FF;
                    text-align: center;
                    // border-radius: 8px;
                }
                .avatar {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    height: 90px;
                }
            }
            .right{
                width: 480px;
                .company-name{
                    margin-bottom: .5em;
                    font-weight: bold;
                    font-size: 16px;
                    a{color: #121f3d;}
                    a:hover{
                        color: #182390;
                    }
                    
                }
                .text{
                    overflow: hidden;
                    height: 42px;
                    font-size: 14px;
                    color: #9094a0;
                }
            }
        }
    }
    .content{
        // max-width: 650px;
    }
    // tab切换按钮
    .ant-tabs.ant-tabs-top .ant-tabs-nav{
        border: none;
        margin-bottom: 15px;
        &::before{
            border: none;
        }
        .ant-tabs-ink-bar{display:none !important;}
        .ant-tabs-nav-list{
            background-color: #fff;
            padding: 8px;
            // border-radius: 10px;
            color: #999999;
            .ant-tabs-tab{
                margin-right: 0;
                color: #999999;
                font-size: 16px;
                .ant-tabs-tab-btn{
                    height: fit-content;
                    padding:0 16px;
                    border-right: 1px solid #EEEEEE;
                }
                &:nth-last-child(1){
                    border-right: 0;
                }
              
            }
            #rc-tabs-0-tab-4{
                border-right: none;
            }
            .ant-tabs-tab-active{
                background-color: #fff;
                color: #232F3F;
                // border-radius: 8px;
                .ant-tabs-tab-btn{
                    font-weight: 800;
                    color: #232F3F;
                }
                
            }
        }
    }
    // 测试用户提示
    .test-search-tip{
        display: inline-block;
        width: 100%;
        line-height: 50px;
        text-align: center;
        color: #9094a0;
    }
    // 搜索结果提示
    .ant-empty{
        margin-top: 100px;
    }
}
// 右侧专辑
.collection{
    display: flex;
    flex-direction: column;
    width: 290px;
    padding: 20px 30px;
    margin-top: 50px;
    .item:first-child{
        padding-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
        color: #121f3d;
        border-bottom: 1px solid #121f3d;
    }

    .item{
        margin-bottom: 16px;
        font-size: 16px;
        color: #444;
    }
    a:hover{
        font-weight: 600;
        color: #182390;
    }
}
}
.ant-input-affix-wrapper{
    border-radius: 0;
    box-shadow: none;
}
.ant-input-affix-wrapper-focused{
    border-radius: 0;
    box-shadow: none;
    border-color: #333333;
}
.ant-input-affix-wrapper:hover{
    border-color: #333333;
}
#search-input:focus{
    border: none;
    box-shadow: none;
}