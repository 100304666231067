footer.newfooter-section {
    width: 100%;
    min-height: 214px;
    background: #010101;
    .foot-com {
        width: 1440px;
        padding: 0 80px;
        margin: 50px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF; 
        .logo {
            width: 70px;
        }
        .border {
            width: 1px;
            height: 20px;
            background: #FFFFFF;
            margin: 0 20px;
        }
    }
    .footer-beian {
        width: 1440px;
        padding: 0 80px;
        margin: 55px auto 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .border {
            width: 400px;
            height: 1px;
            background-color: #666;
        }
        .beian {
            display: flex;
            align-items: center; 
            a {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                    width: 18px;
                    margin-left: 40px;
                }
            }
        }
    }
}