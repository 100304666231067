.person {
    position: relative;
    user-select: none;

    > hr {
        border: 1px solid #333;
        background-color: #333;
    }
    #toast {
        position: absolute;
        top: 400px;
        right: 60px;
    }

    #compare {
        position: absolute;
        top: 400px;
        right: 0;
    }

    .page-title {
        padding-top: 20px;
        font-size: 20px;
        font-weight: bold;
    }

    .page-box {
        padding: 60px 0;
        margin: 0 auto;
        text-align: center;
    }

    .filter {
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid #232F3F;

        .column {
            position: relative;
            padding: 15px 0 0 0;
            // max-height: 45px;
            // overflow: hidden;
            display: flex;
            align-items: center;

            .column-title {
                position: absolute;
                font-size: 16px;
                color: #333333;
                font-weight: 800;
                top: 14px;
                left: 0;
                width: 80px;
                height: 37px;
                line-height: 37px;
                text-align: left;
            }

            .category {
                // padding-left: 80px;
                flex: 1;
                display: flex;
                flex-flow: row wrap;
                height: 37px;
                overflow: hidden;
                align-items: center;
                margin-left: 80px;

                .cate-label {
                    &:nth-child(1) {
                        font-size: 16px !important;
                    }

                    // height: 32px;
                    // line-height: 32px;
                    // padding: 0 13px;
                    // color: #333;
                    // cursor: pointer;
                    font-size: 14px;
                    color: #333;
                    font-weight: 500;
                    margin-right: 40px;
                    cursor: pointer;
                    height: 37px;
                    line-height: 37px;
                }

                .active {
                    // font-size: 18px;
                    // font-weight: bold;
                    // color: #2222da;
                    font-weight: 800;
                    background: none;
                    font-size: 16px;
                    color: #333333;
                    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -webkit-transform-style: preserve-3d;
                    margin-top: -4px;
                }

                .alpha-label {
                    width: 24px;
                    height: 24px;
                    margin-top: 3px;
                    margin-right: 6px;
                    padding: 0;
                    text-align: center;
                    line-height: 24px;
                    cursor: pointer;
                    border: 1px solid #c9c9c9;
                    border-radius: 4px;
                }

                .alpha-active {
                    border-color: #232F3F;
                    color: #fff;
                    background-color: #232F3F;
                }
            }

            // 人物筛选项的 展开收起按钮
            .toggle-btn-box {
                align-self: flex-start;
                margin-top: 7px;
                font-size: 12px;
                color: #999;
                background: #F6F6F6;
                width: 56px;
                height: 24px;
                cursor: pointer;
                transition-duration: 0.3s;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    margin-left: 5px;
                    font-size: 10px;
                }

            }

            .category-toggle {
                height: auto;
                overflow: auto;
            }

        }

        .child-wrap {
            padding-left: 130px;

            .child {
                display: inline-block;
                padding: 0 10px;
                line-height: 36px;
                background-color: #f6f6f6;
            }
        }

        .checked-area {
            position: relative;
            padding: 16px 0;
            .checklist{
                width: 1100px;
                display: flex;
                flex-wrap: wrap;
            }
            .ant-tag {
                background: #EEEEEE;
                height: 36px;
                line-height: 36px;
                padding: 0 16px;
                margin-bottom: .5em;
                // border-radius: 4px;
                font-size: 15px;
                color: #333333;
                border: none;
                font-weight: 500;
            }

            .clear-all {
                width: 76px;
                height: 30px;
                border-radius: 2px;
                text-align: center;
                position: absolute;
                line-height: 30px;
                cursor: pointer;
                top: 14px;
                right: 0;
                border: 1px solid #333333;
            }
        }
    }

    .order-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 600px;
        height: 40px;
        padding: 0 10px;
        margin: 2em 0;
        background-color: #f9fafd;
        border-radius: 10px;

        .orderby {
            height: 24px;
            line-height: 18px;
            padding: 6px 8px;
            font-size: 12px;
            cursor: pointer;
        }

        .checked {
            font-weight: 600;
            border-radius: 8px;
            color: #182390;
        }
    }

    .personList {
        display: flex;
        flex-flow: row wrap;
        // justify-content: space-around;
        padding-top: 30px;
        padding-left: 10px;

        .thumb {
            width: 33.33%;
            max-width: 380px;
            height: 230px;
            // margin-right: 20px;
            margin-bottom: 30px;
            padding: 0 20px 0 5px;
            position: relative;
            border-right: 1px solid #EEEEEE;

            .thumbbox {
                padding: 20px;
                position: relative;
                height: 230px;

                .body {
                    display: flex;
                    flex-direction: row;

                    .left-side {
                        width: 85px;
                        height: 85px;

                        // overflow: hidden;
                        .df {
                            width: 85px;
                            height: 85px;
                            overflow: hidden;
                            line-height: 85px;
                            background-color: #F6F8FF;
                            text-align: center;
                            border-radius: 3px;
                        }

                        .avatar {
                            display: block;
                            margin: 0 auto;
                            max-width: 100%;
                            height: 85px;
                        }
                    }

                    .profiles {
                        width: 280px;
                        padding-left: 15px;
                        font-size: 10px;
                        color: #232f3f;

                        .fullname {
                            margin-bottom: 0;
                            font-weight: bold;
                            font-size: 16px;
                        }

                        .company {
                            width: 100%;
                            margin-bottom: 10px;
                            margin-top: 10px;
                            font-size: 12px;
                        }

                        .position {
                            font-size: 12px;

                        }

                        .dt-index {
                            color: #a4a4b3;

                            span {
                                color: #333
                            }
                        }
                    }
                }

                .bottom {
                    display: flex;
                    flex-flow: row nowrap;

                    .tags {
                        flex: 1;
                        max-height: 100px;
                        padding-top: 10px;
                        overflow: hidden;
                        font-size: 0;

                        span {
                            margin-left: 6px;
                            margin-bottom: 6px;
                        }
                    }

                }

                .info {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    display: flex;

                    i.iconfont.icon-ic_xinfeng {
                        font-size: 14px;
                    }

                    >i.iconfont,.icon, i.iconfont.icon-ic_xinfeng{
                        margin: 0 12px;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        background-color: #f9f9f9;
                        color: #232F3F;
                        display: -ms-flexbox;
                        display: flex;
                        display: -webkit-flex;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -ms-flex-align: center;
                        align-items: center;
                        cursor: pointer;

                        &.active,
                        &:hover {
                            color: #fff;
                            background-color: #232F3F;
                        }
                    }

                    .email {
                        display: none;
                    }

                }
            }

        }

        .thumb:nth-child(3n) {
            border-right: 0;
        }

        .thumb:nth-child(3n) {
            margin-right: 0;
        }

        .thumb:hover {
            .info {
                .email {
                    display: block;
                }
            }

            // box-shadow: 0px 0px 6px 0px rgba(234, 234, 255, 1);
            .thumbbox {
                box-shadow: 0px 0px 0px 1px #333333;
            }
        }

        .thumb:last-child:after {
            content: '';
            height: 0;
            width: 420px;
        }
    }


    .mark {
        width: auto;
        color: #1975d0;
        background-color: #e3efff;
        font-size: 10px;
    }
}

.ant-message-notice-content {
    padding: 30px 50px;
    border-radius: 80px;

    .anticon {
        display: none;
    }
}

.ant-modal-header {
    background-color: #fff;

    .ant-modal-title {
        text-align: left;

        >img {
            width: 120px;
            height: 29px;
        }
    }
}

.ant-modal-body {
    text-align: center;

    >p {
        &:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            margin: 15px 0 20px 0;
        }

        &:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-bottom: 50px;
        }

        &:nth-child(3) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-bottom: 10px;
        }

        &:nth-child(4) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-bottom: 50px;
        }
    }
}

// 导出按钮
h1.export {
    width: 100%;
    padding: 20px 0 0;
    &::after{
        content: '';
        display: block;
        clear: both;
    }
}