.custom-page{
    >li{
        height: 34px;
    }
    .ant-pagination-item{
        line-height: 32px;
    }
    padding: 70px 0 20px 0;
    text-align: center;
    .ant-pagination-item-active a{
        color: #fff;
        background-color: #232F3F;
    }
    .ant-pagination-item-active{
        background: #232F3F;
    }
}