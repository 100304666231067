$Color: '#02375A','#044E79','#0F6A94','#2583AF','#3FA3D2','#80BCD9','#80BCD9','#A2D6E0','#B9DEE5','#FFCAE3E8';


.tooltip{
    span{
        display:inline-block;
        width:8px;
        height:8px;
        border-radius:50%;
        margin-right: 3px;
    }

    @for $index from 1 through length($Color) {
        span:nth-of-type( #{$index} ){      
            background:#{nth($Color, $index)};          
        }
        span:last-child{      
            background:#fff;          
        }
    }

    
    // @each $item in $Color {
    //     $index: index($Color, $item);
    //     span:nth-child( #{$index*2}  ){      
    //         background: #{$item};          
    //     }
    // }
}
