.nologin-container{
    width: 1440px !important;
    // padding: 20px;
    height: 840px !important;
    margin: 0 auto;
    .logo{
        display: inline-block;
        .logoImg{
            width: 118px;
            // margin-top: -27px;
            // margin: 21px 0 0 28px;
        }
    }
    .login-box{
        width: 1440px;
        height: 100%;
        // margin: 50px auto;
        background-color: #fff;
        .loginImg{
            margin-top: 60px;
        }
        
    }
    .register-box{
        margin-top: 40px;
    }
    .ad{
        // border-radius: 14px 0 0 14px;
        width: 740px !important;
        height: 100%;
        background: url('https://image.sciphi.cn/dt_insights/login-banner.jpg') no-repeat;
        background-size: 100% 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        // justify-content: space-between;
        padding: 41px 48px;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #FFFFFF;
        // line-height: 32px;
        // line-height:45px;
        >.bold{
            display: flex;
            display: -webkit-flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 36px;
            // line-height: 50px;
            font-weight: 600;
            margin-bottom: 10px;
            >img{
                width: 120px;
                margin-right: 20px;
            }
        }
    img{
        display: inline-block;
    }
    >div{
        // margin-top: 14px;
    }
    .title1{
        margin-top: 75px;
    }
    .title {
        text-align: center;
    }
    }
    .login-form{
        flex: 1 !important;
        padding: 40px 110px 160px;
        border-radius: 0 14px 14px 0;
        .back {
            display: block;
            width: 78px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 1px solid #F2F2F2;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            margin-bottom: 60px;
            &:hover {
                background: #000000;
                border-color: #000000;
                color: #fff;
            }
        }
        h1{
            font-size: 42px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 70px;
        }
        .inp-lable {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 10px;
        }
        .mobile-item{
            margin-bottom: 26px;
        }
        .pwd-item{
            margin-bottom: 24px;
        }
        .ant-form-item-control{
            line-height: 48px;
        }
        .ant-input{
            height: 48px;
            border-radius:8px;
            border: 1px solid #323851;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .btn-form{
            margin-top: -20px;
            .ant-form-item-children,.ant-legacy-form-item-children, .ant-form-item-control-input-content{
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                .ant-checkbox-wrapper{
                    color: #9094A0;
                }
                .goforgot{
                    color: #1F3992;
                    cursor: pointer;
                }
            }
            .check-box {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                cursor: pointer;
                display: flex;
                align-items: center;
                img {
                    width: 22px;
                    margin-right: 10px;
                }
            }
        }
        .forgot-box{
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            align-items: center;
            .btn-form{
                flex: 1;
            }
            .goforgot{
                color: #1F3992;
                cursor: pointer;
                display: block;
                height: 48px;
                margin-top: -20px;
            }
        }
        .login-btn{
            display: block;
            width: 100%;
            height: 54px;
            background: #000000 !important;
            border-radius: 8px;
            margin-top: 50px;
            // background-color: #2222DA;
            color: #fff;
            // border-radius:8px;
            font-size: 16px;
        }
        .info-tele{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            a{
                color: #1F3992;
            }
        }
        .code-input{
            height: 48px;
        }
        #regcode{
            width: 90%;
            margin:2px 0 0 10%;
            height: 44px;
            // border-radius: 5px;
        }
        .sms-btn{
            width: 90%;
            margin-left: 10%;
            height: 48px;
            // border-radius: 8px;
        }
        .ant-btn-primary{
            background-color: #232F3F;
        }
        .ant-btn-primary[disabled]{
            background-color: #f5f5f5;
        }
        .ant-checkbox-wrapper-checked{
            .ant-checkbox-checked, .ant-checkbox-inner{
                background: #232F3F;
                border-color: #232F3F;
            }
                        
        }
    }
    // 忘记密码
    .forgot{
        h1{
            text-align: center;
        }
        .gologin{
            float: right;
            margin: 20px 0;
            >span{
                color: #1F3992;
                cursor: pointer;
            }
        }
    }
}
.login-con{
    width: 100%;
    min-height: 100vh;
    // background-color: #F9FAFD;
    display: flex;
    // justify-content: center;
    align-items: center;
}
