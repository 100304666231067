@import 'styles/_mixin';

// 人物影响力排行
.person-effect-container{
    margin-top: 20px;
}
// 左侧列表标题
.effect-lf-list{
    > h1{
        font-size: 22px;
        color: #333;
        // line-height: 32px;
        margin-bottom: 18px;
        font-weight: 600;
        align-items: center;
        .iconfont{
            color: #333;
            font-size: 18px;
            font-weight: 500;
            margin-left: 8px;
            display: inline-block;
            cursor: pointer;
        }
        .icon-ic_wenhao{
            font-size: 19px;
            margin-left: 10px;
        }
    }
    // 指数解释
    .ant-tooltip{
        width: 330px;
        max-width: 330px;
        left: 212px;
        .ant-tooltip-arrow-content{
            width: 10px;height: 10px;
            background-color: #fff;
        }
        .ant-tooltip-inner{
            background-color: #fff;
            color: #333;
            font-weight: 500;
            font-size: 10px;
            text-align: justify;
            padding: 15px;
        }
    }
    >.select-box{
        position: relative;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        padding-bottom: 20px;
        >.ant-input-affix-wrapper, >.ant-input{
            width: 300px;
            height: 30px;
            line-height: 30px;
        }
        >.ant-btn{
            width: 30px;
            height: 30px;
            border-color: #eee;
            border-radius: 4px;
            padding: 0;
        }
        i{
            width: 30px;
            height: 30px;
            display: inline-block;
            cursor: pointer;
        }
        .duigou{
            background-image: url('../../assets/images/person/effect-dui.svg');
        }
        .disable-duigou{
            cursor: not-allowed;
            background-image: url('../../assets/images/person/effect-dui-disable.svg');
        }
        .cha{
            background-image: url('../../assets/images/person/effect-cha.svg');
        }
        .ant-btn[disabled]{
            background-color: #fff;
            border-color: #dcdcdc;
            i{
                color: #dcdcdc;
            }
        }
    }
}
// 全局定义的联动选择框 样式 父级要有定位
.steps-content.select-steps-custom{
    position: absolute;
    top: 42px;
    z-index: 100;
    &::after{
        position: absolute;
        top: 0;
        z-index: -1;
        content: '';
        display: inline-block;
        width: 400px;
        height: 600px;
        background-color: rgba($color: #fff, $alpha: .1);
    }
    .select-list{
        width: 240px;
        padding: 20px 0;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        >h1{
            padding-bottom: 10px;
            margin: 0 20px 10px;
            justify-content: space-between;
            border-bottom: 1px solid #999;
            span{
                font-size: 14px;
                &:first-child{
                    color: #666;
                }
                &:last-of-type{
                    color: #232F3F;
                    cursor: pointer;
                }
            }
        }
        ul{
            @include customScrollBar(300px);
            padding: 0 20px;
            margin-bottom: 0;
            >li{
                color: #666;
                cursor: pointer;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #eee;
                line-height: 46px;
                &:hover{
                    color: #333;
                }
            }
            >li.active{
                color: #333;
            }
        }
        
    }
}
.effect-lf-list{
    // 左侧列表
    
    .effect-person-ul{
        @include customScrollBar(calc( 100vh - 150px ));
        height: calc( 100vh - 150px );
        overflow-y: auto;
        >li{
            border-bottom: 1px solid #eee;
            padding: 20px;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .effect-rank{
                width: 34px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                font-size: 20px;
                color: #666;
                font-weight: 500;
            }
            dl{
                width: calc(100% - 50px);
                margin-bottom: 0;
                dd{
                    margin-bottom: 0;
                }
                dd.flex_box{
                    align-items: center;
                    margin-bottom: 0;
                }
                dd.flex_box > span{
                    min-width: 60px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    color: #333;
                }
                .name{
                    font-size: 18px;
                    font-weight: 600;
                }
                .pos{
                    font-size: 14px;
                    margin-left: 20px;
                }
                dd.labels {
                    margin-top: 10px;
                    > span{
                        margin-right: 10px;
                        margin-bottom: 10px;
                        background-color: #F9F9F9;
                        padding: 5px 15px;
                        font-size: 12px;
                    }
                }
            }
            &:last-of-type{
                border: none;
            }
            .rank-top{
                background-image: url('../../assets/images/person/effect-top1.svg');
                background-repeat: no-repeat;
                color: #fff;
            }
            &:nth-of-type(2){
                .rank-top{
                    background-image: url('../../assets/images/person/effect-top2.svg');
                }
            }
            &:nth-of-type(3){
                .rank-top{
                    background-image: url('../../assets/images/person/effect-top3.svg');
                }
            }
            
        }
        // 选中的状态
        >li.active{
            position: relative;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: calc(100% - 20px);
                top: 10px;left: 0;
                border: 1px solid #333;
                z-index: 0;
            }
        }
        
        // 加载更多按钮
        .more-btn{
            text-align: center;
            margin: 20px 0 50px;
            cursor: pointer;
            color: #999;
        }
    }
}

// 右侧可视化区域
.effect-rt-detail{
    padding-left: 30px;
    > .detail-title{
        margin-bottom: 25px;
        padding-bottom: 20px;
        border-bottom: 2px solid #666;
        span{
            font-size: 18px;
            color: #999;
            margin-right: 15px;
            cursor: pointer;
        }
        span.active{
            color: #333;
            font-size: 22px;
            font-weight: 600;
        }
    }
}

// 图表集合
.effect-tabs{
    .ant-tabs-content{
        height: 550px;
    }
    .tabcharts .charts-footer .ct-col .anticon{
        margin: 0 10px;
    }
    .tabcharts{
        margin-bottom: 50px;
    }
}
// 信息卡片
.effect-info-card{
    color: #333;
    > .basic-box{
        justify-content: space-between;
        align-items: center;
    }
    .basic-lf{
        .custom-no-avatar,.custom-avatar{
            width: 140px;
            height: 140px;
            border-radius: 0;
        }
        dt{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        dd{
            margin-bottom: 20px;
            span{
                margin-right: 20px;
            }
            &:last-child{
                span{
                    margin-right: 10px;
                    background-color: #F9F9F9;
                    padding: 5px 15px;
                }
            }
        }
    }
    >.des-box{
        height: 120px;
        margin: 20px 0;
        overflow: hidden;
        >.des{
            line-height: 24px;
            margin-bottom: 0;
        }
    }
    >.des-box-auto{
        height: auto;
        overflow: visible;
    }
    .des-con {
        > span {
            font-size: 14px;
            line-height: 24px;
        }
    }
    // 指数
    >.index-ul{
        margin-top: 20px;
        padding: 24px 50px 30px;
        justify-content: space-around;
        border-top:1px solid #eee;
        border-bottom:1px solid #eee;
        li{
            flex-direction: column;
            color: #999;
            text-align: center;
            span{
                color: #333;
                font-weight: 600;
                font-size: 24px;
            }
        }
    }
    // 可视化图表
    >.charts-btns{
        justify-content: space-between;
        >.btns-box{
            padding: 20px 0;
            flex-direction: column;
            justify-content: space-around;
            i{
                height: 36px;
                width: 36px;
                border-radius: 5px;
                border: 1px solid #F9F9F9;
                display: -ms-flexbox;
                display: flex;
                display: -webkit-flex;
                justify-content: center;
                align-items: center;
                background: #F9F9F9;
                color: #232F3F;
                cursor: pointer;
                &:hover{
                    background-color: #232F3F;
                    color: #fff;
                }
                &.iconfont.icon-eye{
                    font-size: 18px;
                }
                &.iconfont.icon-compare{
                    border-radius: 5px;
                }
            }
            i.active{
                background-color: #232F3F;
                color: #fff;
            }
        }
        > .charts-tab{
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 120px);
            padding: 0 20px;
            > .iconfont{
                display: inline-block;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
                color: #999;
                font-weight: 900;
                width: 36px;height: 36px;
                border-radius: 50%;
                background-color: rgba($color: #000, $alpha: .1);
            }
        }
    }
}

