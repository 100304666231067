
.detail {
    // 人物侧边导航
    .aside {
        position: fixed;
        width: 200px;
        padding-right: 20px;
        padding-left: 16px;
        user-select: none;
        .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
            display: none;
        }
        .ant-menu.ant-menu-sub {
            .ant-menu-item {
                height: 40px;
                line-height: 40px;
                margin: 0;
                padding: 0 0 0 36px !important;
                font-size: 14px;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
            }
            .ant-menu-item.ant-menu-item-selected {
                color: #333;
                font-weight: 600;
                a::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 4px;
                    border-bottom: 2px solid #000;
                }
            }
        }
        
        .ant-anchor {
            .ant-anchor-ink {
                display: none;
            }
            .ant-anchor-link {
                padding: 0;
                height: 40px;
                line-height: 40px;
                padding-left: 36px;
                font-size: 14px;
                width: fit-content;
                .ant-anchor-link-title{
                    color: #333333;
                }
            }
            .ant-anchor-link-active{
                color: #333333;
                font-weight: 600;
                background: #fff;
            }
            .ant-anchor-link-title:hover{
                font-weight: 800;
                color: #333333;
            }
            .ant-anchor-link-title-active{
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 4px;
                    border-bottom: 2px solid #000;
                }
            }
            .ant-anchor-link:nth-last-child(1){
                margin-bottom: 5px;
            }
        }
        .ant-menu-dark {
            background-color: transparent;
        }
        .ant-menu-inline {
            border-right: none;
        }
        .ant-menu-submenu-arrow::before,.ant-menu-submenu-arrow::after{
            background: #333333 !important;
        }
        .ant-menu-submenu-title {
            font-size: 18px;
            color: #333333;
            height: 56px;
            line-height: 56px;
            margin-top: 0;
            margin-bottom: 0;
        }
        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
            background: transparent;
        }
        .ant-menu-item {
            margin: 0;
            color: #333333;
            height: 56px;
            line-height: 56px;
            &:hover {
                font-weight: 600;
            }
            .link {
                font-size: 18px;
                color: #333333 ;
            }
        }

        .ant-menu-item-selected {
            // background:linear-gradient(90deg,rgba(243,244,250,1) 0%,rgba(255,255,255,1) 100%);
            background: #ffffff !important;
            .link{
                color: #333333;
                font-weight: 600;
            }
        }
        
    }
    //  人物详情
    .main {
        margin-left: 200px;
        section{
            margin-bottom: 20px;
        }
        #basic, #expert, #academic, #research, #statistics, #relations, #education, #employment, #honor {
            margin-top: -95px;
            border-top: 95px solid transparent;
        }
        .theme-box {
            padding: 30px;
            // box-shadow: 0px 0px 6px 0px rgba(234, 234, 255, 1);
            // border-radius: 14px;
        }
        .relate-footer{
            display: flex;
            justify-content: flex-end;
            .oprate{
                width: 48px;
                height: 40px;
                margin-left: 20px;
                line-height: 36px;
                border: 1px solid #f4f4fa;
                text-align: center;
                cursor: pointer;
                img{
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .custom-table .ant-table-tbody>tr>td:first-child {
        color: inherit
    }

    .section-title {
        font-size: 20px;
        font-weight: bold;
        color: #202031;
        margin-top: 0;
        margin-bottom: 20px;
        border-bottom: 2px solid #333333;
        padding-bottom: 20px;
    }

    .honor {
        li {
            line-height: 2em;
            margin-bottom: 13px;
        }
    }

    .flex-box {
        display: flex;
        flex-flow: row wrap;
    }

    .basic {
        // display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        position: relative;
        #compare{
            position: absolute;
            top: 200px;
            right: -58px;
        }
        
        .left {
            padding-top: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .summary {
                width: 80%;
                display: flex;
                flex-flow: row nowrap;
                .photo {
                    width: 160px;
                    height: 160px;
                    overflow: hidden;
                    .df{
                        overflow: hidden;
                        line-height: 160px;
                        background-color: #F6F8FF;
                        text-align: center;
                        // border-radius: 8px;
                    }
                    .avatar {
                        display: block;
                        margin: 0 auto;
                        max-width: 100%;
                        height: 160px;
                    }
                }

                .info {
                    flex: 1;
                    padding-left: 20px;

                    .profile {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding-bottom: 10px;

                        .column {
                            width: 50%;
                            min-width: 120px;
                            margin-bottom: 4px;
                            padding-right: 5px;
                        }

                        .column span {
                            font-size: 12px;
                            color: #9292A3;
                        }
                    }

                    .tags {
                        span {
                            width: auto;
                            margin-right: 6px;
                            margin-bottom: 6px;
                        }
                        span:first-child{
                            margin-left: 0;
                        }
                    }
                    .section-title{
                        border-bottom: 0;
                    }
                }
            }
            .action > * {
                margin:0 10px;
                align-items: center;
                cursor: pointer;
            }
            .action{
                .iconfont:hover{
                    background: #232F3F;
                    color: #fff;
                }
            }
        }

        .right {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            align-items: flex-start;
            .introduce{
                padding: 0 30px 0 0;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                .inner{
                    height: 10px;
                    width: 100%;
                }   
            }
            .introduce-auto{
                height: auto;
                overflow: visible;
            }
            .contact {
                width: 300px;
                padding: 0 30px;
                // box-shadow: 0px 0px 6px 0px rgba(234, 234, 255, 1);
                // border-radius: 14px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                border-left: 1px solid #333333;
                ul {
                    margin-bottom: 0;
                }
                li {
                    /* margin-top: 1em; */
                    width: 240px;
                    line-height: 28px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    #academicResearch{
        display: flex;
        #academic, #research{
            width: 100%;
        }
        #research{
            padding-left: 20px;
        }
        #academic{
            padding-right: 20px;
            .academic{
                margin-top: 20px;
                border-top: 2px solid #333333;
                margin-right: 20px;
                padding-top: 20px;
                a{
                    color: #666666;
                    span{
                        color: #333333;
                    }
                }
                .nums{
                    color: #333333;
                }
            }
        }
        .academic-title{
            padding-bottom: 0;
            border-bottom: 0;
            margin: 0;
        }
        
    }

    #education, #employment{
        .custom-table .ant-table-tbody > tr:nth-child(2n+1){
            background: #fff;
        }
        
        .custom-table .ant-table-tbody > tr:hover{
            box-shadow: none;
        }
    }
    .patent {
        position: relative;
        padding-top: 30px;

        .patent-wrap {
            padding:  30px;
            // box-shadow: 0px 0px 6px 0px rgba(234, 234, 255, 1);
            // border-radius: 14px;
            .left-side {
                display: flex;
                flex-flow: row wrap;
                width: 380px;
                .separator {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    width: 1px;
                    height: 40px;
                    background-color: #e8e8e8;
                }

                .piece {
                    position: relative;
                    width: 120px;
                    height: 80px;
                    text-align: center;
                    color: #9292A3;
                    font-size: 12px;

                    div {
                        min-height: 36px;
                        color: #1e2e42;
                        font-size: 24px;
                    }

                    .expert {
                        position: relative;
                        color: #00b090;
                        .indicator{
                            display: none;
                        }
                    }

                    .valid {
                        color: #f85b00;
                    }
                }
            }
        }

        .graph {
            position: absolute;
            top: 82px;
            right: 60px;
            width: 240px;
            height: 220px;
            /*background-color: darkmagenta;*/
        }
    }

    .scholar {
        padding-top: 30px;
    }



    .part {
        // width: 48%;
        padding: 12px;
        background-color: #F9F9F9;
        // margin-bottom: 20px;
        border-radius: 4px;

        .head {
            margin-left: 20px;
            margin-bottom: 10px;
            font-weight: bold;
            color: #333333
        }

        .body {
            display: flex;
            flex-flow: row wrap;
            list-style: disc;
            padding-left: 2em;
            margin-bottom: 0;
            li{ 
                width: 46%;
            }
            li:nth-child(even){
                margin-left: 25px;
            }
        }
    }
    .statistics {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .item{
            // flex: 1;
            // width: 45%;
            .head{
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #121f3d
            }
        }
    }
    #statistics .statistics{
        padding: 30px 0px;
    }
    .emptyimg{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }
}