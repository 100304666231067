@import 'styles/_mixin';

.virtual-list {
    @include hiddenScrollBar();
}
.research-lists {
    margin-top: 40px;
    .arti {
        display: flex;
        justify-content: space-between;
        padding: 35px 24px;
        min-height: 200px;

        .left {
            margin-right: 60px;

            .head {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #232F3F;
                line-height: 26px;

            }
        }

        .aut_time {
            padding: 10px 0 26px 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9094A0;
            line-height: 20px;
            .aut{
                margin-right: 20px;
            }
        }

        .body {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4A4A4A;
            line-height: 20px;
            letter-spacing: 2px;
        }

        .right {
            width: 260px;

            img {
                width: 260px;
                height: 140px;
            }
        }
    }

    .arti:hover {
        box-shadow: 0px 0px 0px 1px #333333;
        cursor: pointer;

        .head {
            color: #333333;
        }
    }
}