.comperpare {
    margin-top: 20px;

    .tab-right {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }

    .tab-title {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
    }

    .ant-tabs-nav {
        border-bottom: 1px solid;
        margin: 0 0 40px 0;
    }

    .com-com-basic-top {
        display: flex;
        margin-bottom: 20px;

        .custom-no-avatar {
            width: 60px;
            height: 60px;
        }

        .custom-avatar {
            width: 60px;
            height: 60px;

            >img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                height: 60px;
                object-fit: contain;
            }
        }

        .basic-info {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            >span {
                &:nth-child(1) {
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin-bottom: 8px;

                    >p {
                        display: inline;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 17px;
                    }
                }

                &:nth-child(2) {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                }
            }
        }
    }

    .com-com-body {
        &:nth-last-child(1) {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        margin: 40px 0;
        padding-bottom: 40px;
        border-bottom: 1px solid #DCDCDC;

        .basic-bottom {
            display: flex;

            .info-le {
                height: 134px;
                width: 250px;
                display: flex;
                flex-wrap: wrap;
                padding: 0 30px 0 80px;

                .info-item {
                    text-align: center;
                    width: 50%;
                    display: flex;
                    flex-direction: column;

                    >span {
                        &:nth-child(1) {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 17px;
                        }

                        &:nth-child(2) {
                            font-size: 26px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 37px;
                        }
                    }
                }
            }

            .info-cen {
                height: 134px;
                width: 278px;
                padding: 0 30px;
                border-left: 1px solid #DCDCDC;
                border-right: 1px solid #DCDCDC;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .info-cen-item {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;

                    >span {
                        padding: 5px 14px;
                        height: 26px;
                        background: #F9F9F9;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 17px;

                    }

                    &:nth-child(3) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        >div {
                            &:nth-child(1) {
                                width: 65%;
                            }
                        }

                    }
                }
            }

            .info-ri {
                width: 630px;
                padding-left: 30px;

                .info-ri-top {
                    display: flex;
                    flex-wrap: wrap;

                    >span {
                        display: block;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                    }

                    .info-ri-top-body {
                        display: flex;
                        flex-wrap: wrap;
                        width: 88%;

                        >p {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 17px;
                            padding: 5px 14px 4px 14px;
                            background: #f9f9f9;
                            margin: 0px 5px 10px 5px;
                        }
                    }

                }

                .info-ri-bottom {
                    display: flex;
                    flex-wrap: wrap;

                    >span {
                        display: block;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                    }

                    .info-ri-bottom-body {
                        display: flex;
                        flex-wrap: wrap;
                        width: 88%;

                        >p {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 17px;
                            padding: 5px 14px 4px 14px;
                            background:#f9f9f9;
                            margin: 0px 5px 10px 5px;
                            // cursor: pointer;
                        }
                    }

                }
            }
        }

        .fina-bottom {
            height: 162px;
            display: flex;

            .fina-bottom-le {
                width: 577px;
                height: 162px;
                border-right: 1px solid #dcdcdc;
                display: flex;
                justify-content: center;
                align-items: center;

                >img {
                    width: 100px;
                    height: 91px;
                }
            }

            .fina-bottom-ri {
                width: 582px;
                height: 162px;
                padding-left: 40px;

                .fina-bottom-ri-top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    >span {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                    }
                }

                .fina-bottom-ri-bottom {
                    >dd {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;

                        >img {
                            width: 100px;
                            height: 91px;
                            margin-top: 20px;
                        }

                        >i {
                            padding: 4px 14px;
                            background: #F9F9F9;
                            font-style: normal;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 17px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .ind-bottom {
            padding-left: 80px;

            .chain {
                >span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;

                    &:nth-child(1) {
                        margin-right: 37px;
                    }
                }
            }

            .ind-bottom-item {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                .compare-empty{
                    margin-top: 40px;
                }

                >span {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 17px;
                    padding: 5px 14px 4px 14px;
                    background: #0F6A94;
                    width: 28%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                >dd {
                    margin: 0;
                    height: 100%;
                    margin-left: 2%;
                    width: 78%;
                    background-color: #f9f9f9;
                    padding: 4px 14px;

                    >i {
                        font-style: normal;
                        margin-right: 1%;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 17px;
                        width: 4%;
                    }
                }
            }
        }

        .patent-bottom {
            height: 160px;
            display: flex;

            .patent-bottom-le {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 2;
                // width: 532px;
                height: 160px;

                >img {
                    width: 100px;
                    height: 91px;
                }
            }

            .patent-bottom-cen {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                height: 160px;
                border-left: 1px solid #DCDCDC;

                >img {
                    width: 100px;
                    height: 91px;
                }
            }

            .patent-bottom-ri {
                width: 337px;
                height: 160px;
            }
        }
    }
}