.emptybg, .img-box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    img{
        width: 100%;
        height: 100%;
    }
    .introducenone-box{
        position: absolute;
        padding: 10px;
        font-size: 13px;
        &>div{
            background:rgba(0,0,0,0.5);
            padding: 5px;
            overflow: hidden;
            display:-webkit-box; 
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2; 
            text-overflow: ellipsis;
        }
    }
}