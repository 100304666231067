// 公司 样式
@import '../comps/chain.scss'; // 产业链
// 颜色自定义
$primary_color:#232F3F;

.company-con {
    .park-main {
        >hr {
            border: 1px solid #333333;
            background-color: #333333;
        }
    }

    // 基本信息
    .basic {
        .header-title {
            h1 {
                .flex_box {
                    width: 96px;
                    height: 96px;
                    border-radius: 10px;
                    background-color: #F6F9FE;
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 96px;
                    }
                }

                dd,
                dl,
                dt {
                    margin-bottom: 0;
                }

                dd:nth-child(2) {
                    line-height: 20px;
                    margin-top: 5px;
                    margin-bottom: 15px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;

                }
            }

            .stock-code {
                margin-left: 5px;
            }
        }

        .summary {
            >dl {
                dt {
                    justify-content: space-between;
                    margin-bottom: 20px;

                    span {
                        i {

                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            line-height: 17px;
                            font-style: normal;
                            margin-right: 5px;
                        }

                        font-size: 14px;
                        font-family: PingFangSC-Medium,
                        PingFang SC;
                        font-weight: 500;
                        color: #1B232F;
                        line-height: 15px;
                    }

                    .operate {
                        color: #1B232F;
                    }
                }

                dd {
                    height: 72px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-align: justify;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;

                }

            }

            .summary-auto {
                height: 100%;
            }

            .link_ul {
                li {
                    display: flex;
                    display: -webkit-flex;
                    justify-content: flex-start;
                    width: 236px;

                    >span {
                        width: 36px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 26px;


                        &:first-child {
                            white-space: nowrap;
                        }

                        &:last-child {
                            word-break: break-all
                        }
                    }

                    >i {
                        width: 200px;
                        display: block;
                        font-style: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 12px;
                        font-family: Helvetica;
                        color: #4A4A4A;
                        line-height: 26px;

                    }
                }
            }
        }



        .com-info {

            >dl,
            >ul {
                display: inline-block;
                vertical-align: top;
            }

            >dl {
                width: 70%;
            }

            >ul {
                width: calc(30% - 30px);
            }
        }
    }

    // 上市信息
    .stock {
        .stock-tab {
            // .ant-tabs-nav {
            //     padding-top: 7px;
            // }

            dl {
                height: 100%;
                border-radius: 10px;
                // background-color: #F9FAFD;
                padding: 0 20px 20px;
                margin-bottom: 0;
                text-align: center;

                dt {
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 30px;

                    span {
                        &:first-child {
                            height: 27px;
                            font-size: 16px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 26px;


                            &::before {
                                content: '';
                                height: 90%;
                                width: 4px;
                                vertical-align: text-top;
                                display: inline-block;
                                margin-right: 5px;
                                background-color: $primary_color;
                            }
                        }

                        font-size: 14px;
                        font-family: PingFangSC-Regular,
                        PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 26px;

                    }
                }

                dd {
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    span {
                        &:first-child {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            line-height: 26px;

                        }

                        &:last-child {
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 26px;

                        }
                    }
                }

                .ant-btn {
                    margin: 20px 0;
                    float: right;
                    border: none;
                    background-color: transparent;
                    box-shadow: none;
                    padding: 0;
                    margin-top: 40px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;


                    &:hover {
                        color: $primary_color;
                    }
                }

            }

            .report-col {
                border-left: 1px solid #EEEEEE;

                dl {
                    dt {
                        >span {
                            font-size: 16px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 26px;

                        }
                    }
                }

                .ant-list-split .ant-list-item {
                    border: none;
                }

                .ant-list-items {
                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 20px;
                        font-size: 15px;
                        height: 42px;
                        overflow: hidden;
                        
                        // pdf下载图表
                        .iconfont{
                            font-size: 24px;
                        }

                        span {
                            text-align: left;
                            display: inline-block;

                            &:nth-child(1) {
                                width: 25%;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                                line-height: 20px;
                            }

                            &:nth-child(2) {
                                width: 65%;
                                font-size: 14px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #333333;
                                line-height: 20px;
                            }
                        }

                        &:hover {
                            span {
                                color: $primary_color;
                            }
                        }

                        .anticon {
                            color: #2222D9;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    // 团队成员
    .member {
        >h1 {
            border-bottom: 2px solid #000;
            padding-bottom: 20px;
        }

        .ant-list-split .ant-list-item {
            border: none;
        }

        .ant-list-item-meta-content {
            padding: 20px 10px;

            span.name {
                color: #000;
                font-size: 18px;

                span {
                    margin-left: 20px;
                }
            }

            .ant-list-item-meta-description {
                color: #4A4A4A;
                line-height: 24px;
                font-size: 12px;
            }
        }
    }

    .compete {
        margin-bottom: 50px;

        .graph {
            height: 500px;
        }
    }
}

// 自定义分页
.ant-list .ant-list-pagination {
    text-align: center;

    .simple-custom-page {

        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-prev,
        .ant-pagination-jump-next {
            // min-width: 24px;
            height: 32px;
            line-height: 32px;
            margin-right: 12px;
        }

        .ant-pagination-item-link {
            border: 1px solid #C9C9C9;
            height: 32px;

            .anticon {
                color: #9292A3;
            }
        }

        .ant-pagination-simple-pager {
            height: 32px;
            border: 1px solid #C9C9C9;
            border-radius: 4px;
            background-color: #fff;
            padding: 0 20px 0 0px;
            margin-right: 12px;

            >input {
                border: none;
                margin-right: 0;
                background-color: transparent;
                text-align: right;
                padding: 0;
            }

            .ant-pagination-slash {
                margin-right: 3px;
            }
        }
    }
}

// 公司列表
.company-con.ant-layout {
    background: none;

    // padding: 20px;
    .company-filters {
        margin-top: 20px;
        padding-bottom: 20px;

        >h1 {
            font-size: 20px;
            line-height: 26px;
            font-weight: 600;
            color: #121F3D;
            margin-bottom: 20px;

            >span {
                color: #262626;
                font-size: 14px;
            }
        }
    }
    
    // 筛选项的 展开收起
    .toggle-btn-sel {
        align-self: flex-start;
        margin-top: 7px;
        height: 24px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F6F6F6;
        color: #999;
        cursor: pointer;
        font-size: 12px;
        i{
            margin-left: 5px;
            font-size: 10px;
        }
    }

    // 一级菜单
    .select-tag {
        margin: 10px 0;
        // justify-content: space-between;
        max-height: 500px;
        overflow: hidden;
        position: relative;

        >.title {
            width: 8%;
            display: inline-block;
            padding: 5px 0;
            font-size: 16px;
            color: #333333;
            font-weight: 800;
            position: relative;

            .anticon.anticon-info-circle {
                position: absolute;
                right: 15px;
                top: 8px;
                color: #9094A0;
            }
        }

        >div {
            width: 88%;
        }

        .ant-tag {
            &:nth-child(1){
                font-size: 16px !important;
            }
            font-size: 14px;
            color: #333;
            font-weight: 500;
            margin-right: 30px;
            padding: 5px 0;
            cursor: pointer;
        }

        .ant-tag-checkable-checked {
            background: none;
            font-size: 18px;
            font-weight: 800;
            color: #333333;
        }

        &:last-child {
            .toggle-btn {
                opacity: 0;
            }
        }
    }
    .hidden-text {
        max-height: 34px;
        overflow: hidden;
    }

    // 有搜索框的选项
    .filter-box {
        overflow: visible;
        display: flex;
        > .tags_box {
            width: auto;
            overflow: hidden;
        }
        > .fiter-search-input {
            width:auto;
        }

    }

    .toggle-btn {
        align-self: flex-end;
        font-size: 15px;
        color: #aaa;
        line-height: 32px;
        cursor: pointer;
    }

    // dispaly tag list
    .checked-box {
        justify-content: space-between;
        align-items: flex-start;

        >p {
            margin-bottom: 0;
            margin-top: 10px;
        }

        .ant-tag {
            background-color: #EEEEEE;
            height: 36px;
            line-height: 36px;
            padding: 0 16px;
            margin-bottom: 10px;
            border-radius: 4px;
            font-size: 15px;
            color: #333333;
            border: none;
            font-weight: 500;

            .anticon.anticon-close {
                font-size: 16px;
                font-weight: 700;
                color: #333333;
                margin-left: 12px;
            }
        }

        .sub-tag {
            background-color: #fff;
            border: 1px solid #333333;
        }

        .ant-btn {
            color: #9292A3;
            font-size: 15px;
            border: none;
            margin-top: 14px;
        }
    }

    // 二级菜单
    .sub-select-tag {
        background-color: #F6F6F6;
        margin-left: 8%;
        margin-top: -10px;
        padding: 8px 5px;

        >.title {
            color: #333;
            width: 70px;
            display: inline-block;
            margin: 0 0 0 15px;
            vertical-align: top;
        }

        >div {
            display: inline-block;
            width: calc(100% - 85px);
        }

        span {
            font-size: 15px;
            margin: 0 15px;
            padding: 5px 0;
            cursor: pointer;
        }

        .ant-tag {
            color: #9292A3;
        }

        .ant-tag-checkable-checked {
            background: none;
            font-weight: 800;
            color: $primary_color;
        }
    }

    // table 列表
    .company-list {
        padding-top: 40px;

        h1 {
            margin-bottom: 30px;

            .sums {
                color: #1F3992;
                font-size: 20px;
                margin: 0 10px;
            }

            // 收藏按钮
            .collect_btn {
                display: inline-block;
                background-color: #232F3F;
                padding: 0 25px;
                line-height: 36px;
                height: 36px;
                cursor: pointer;
                color: #fff;
                margin: 0 30px 0 40px;

                i {
                    display: none;
                }
            }
        }

        .ant-select-selection--single {
            height: 36px;
            line-height: 36px;
        }

        .ant-select-selection__rendered {
            line-height: 36px;
        }

        .ant-select-focused .ant-select-selection {
            border-color: #d9d9d9;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .ant-select-open .ant-select-selection {
            border-color: $primary_color;
        }
        
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
            border-color: #333;
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            border-color: #333;
            box-shadow: none;
            -webkit-box-shadow:none;
        }

        .top500 {
            display: inline-block;
            margin: 0 40px;
            color: #555;
        }

        .company-table {

            .ant-table-thead>tr,
            .ant-table-tbody>tr {
                height: 28px;
                line-height: 28px;
            }
        }
    }

    .ant-tag-checkable:active {
        background-color: transparent;
    }
}

.tags_box {
    position: relative;

    // height: 40px;
    // overflow: hidden;
    .btn {
        position: absolute;
        right: 10px;
        bottom: -10px;
        z-index: 1000;
        font-style: normal;
    }
}

.ant-select-dropdown-menu-item {
    line-height: 32px;
}

@media(max-width:1400px) {
    .company-con .layout-rt .park-main .dt-index>div.box_shadow {
        >ul {
            width: 78%;
        }

        .dt-radar {
            width: 22%;
        }
    }
}

@media(max-width:1200px) {
    .company-con .layout-rt .park-main .dt-index>div.box_shadow {
        padding: 20px 10px;

        >ul {
            width: 75%;
        }

        .dt-radar {
            width: 25%;
        }
    }
}

.park-container {
    .parktitle {
        border-bottom: 2px solid #000;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .iconfont.icon-explain{
            font-size: 20px;
            vertical-align: text-top;
            margin-left: 10px;
        }
    }
}

// 公司详情 TI指数
.dt-index{
    // 指数解释
    .ant-tooltip{
        width: 330px;
        max-width: 330px;
        left: 212px;
        .ant-tooltip-arrow-content{
            width: 10px;height: 10px;
            background-color: #fff;
        }
        .ant-tooltip-inner{
            background-color: #fff;
            color: #333;
            font-weight: 500;
            font-size: 10px;
            text-align: justify;
            padding: 15px;
        }
    }
}
.indexdata-box {
    padding-top: 30px;

    ul {
        flex: 1;

        li {
            flex: 1;
            height: 68px;
            border-right: 1px solid #EEEEEE;
            justify-content: center;
            align-items: center;
            color: #999999;

            .strong {
                font-size: 24px;
                color: #000;
            }
        }

        li:nth-last-child(1) {
            border-right: 0;
        }
    }
    i.iconfont {
        display: flex;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F9F9F9;
        margin-right: 10px;
        color: #232F3F;
        font-size: 36px;
    }
}