.board{
    display: flex;
    background: #fff;
    .title{
        padding: 20px 0;
        font-weight: bold;
        font-size: 20px;
        color: #121f3d;
    }
    .title-border{
        border-bottom: 2px solid #333333;
    }
    .section{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 40px 0;
    }
    .policy{
        width: 100%;
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
        padding: 10px 0;
        .time{
            margin-right: 30px;
            font-size: 12px;
            color: #9696a6;
        }
        .link{
            font-size: 14px;
            color: #232f3f
        }
    }
    .center-border{
        width: 1px;
        height: 220px;
        background: #EEEEEE;
        margin-top: 93px;
    }
    .card{
        position: relative;
        width: 480px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 3px;
        .more{
            position: absolute;
            right: 20px;
            top: 30px;
        }
        .top {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid #333333;
            .head{
                font-weight: bold;
                font-size: 20px;
                color: #121f3d;
            }
        }
        .body{
            .news-list{
                padding-top: 20px;
            }
            .time{
                margin-right: 18px;
                font-size: 12px;
                color: #9696a6;
            }
            .chief{
                font-weight: 600;
                font-size: 16px;
                color: #131313;
                margin-right: 10px;
            }
            .content{
                padding-top: 10px;
                padding-left: 44px;
            }
            .ranking{
                .head{
                    display: flex;
                    justify-content: space-around;
                    margin-top: 20px;
                    height: 32px;
                    line-height: 32px;
                    background-color:#333333;
                    font-size: 14px;
                    color: #FFFFFF;
                }
                .body{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    background: #F9F9F9;
                    .order,.nums,.rank-name{
                        padding: 5px 0;
                        font-size: 14px;
                        color: #333333;
                    }
                    .order{
                        color: #666666;
                    }
                    .rank-name{
                        padding-left: 80px;
                    }
                }
                .order, .nums{
                    width: 15%;
                    text-align: center;
                 }
                 .rank-name{
                     flex: 70%;
                     padding-left: 80px;
                 }
            }
        }
    }
    .policy-rank{
        .card:nth-of-type(1){
            padding-left: 0;
        }
        .card:nth-last-of-type(1){
            padding-right: 0;
        }
    }
    .graph{
        // 产业链
        .chain-box, .no-chain-box{
            padding: 20px 20px;
            background-color: #fff;
            // box-shadow: 0px 2px 10px 0px rgba(240, 242, 247, 1);
            // border-radius: 12px;
        }
        .industry-box{
            padding: 40px 20px;
            .column{
                padding-top: 0;
            }
        }
        .custom-table .ant-table-thead > tr > th, .custom-table .ant-table-tbody > tr > td {
            padding: 14px 12px;
        }
    }
    .ant-table-cell{
        a{
            color: #333333 !important;
        }
    }
    .ant-pagination-item-active{
        background-color: #232F3F;
        a{
            color: #fff;
        }
    }
}
.flex-box{
    display: flex;
    justify-content: space-around;
    align-content: center;
}
.wrap-box{
    padding: 30px 20px;
    // box-shadow: 0px 2px 10px 0px rgb(240,242,247);
    // border-radius: 14px;
    position: relative;
    .locate{
        display: flex;
        justify-content: space-between;
        .figure{
            margin-top: 100px;
        }
    }
    .view{
        position: absolute;
        top: 75px;
        right: 60px;
        width: 210px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        border: 1px solid #333333;
        // border-radius: 10px;
    }
    .view:hover {
        color: #fff;
        background-color: #333333;
    }
    .left{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        width: 600px;
        .item{
            width: 30%;
            text-align: center;
            padding: 10px 0;
            .nums{
                font-weight: bold;
                font-size: 26px;
                color: #1e2e42;
                margin-top: 5px;
                span{
                    margin-left: .5em;
                    font-weight: normal;
                    font-size: 12px;
                }
            }
            .desc{
                font-size: 12px;
                color: #9292a3;
            }
        }
    }
    .column{
        display: flex;
        flex-flow: column nowrap;
    }
    .primary{
        width: 140px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        color: #333333;
        background-color: #EEEEEE;
    }

    .child{
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        width: 140px;
        background-color: #F9F9F9;
        margin-top: 30px;
        padding: 30px 14px;
        border: 1px dashed rgb(191,197,207);
        overflow-x: hidden;
        .secondary{
            margin: 8px 0;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
            color: #666666;
            cursor: pointer;
            span{
                // border-bottom: 1px solid rgb(191,197,207);
            }
        }
        
    }

    .top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .item{
            width: 280px;
            height: 70px;
            font-size: 18px;
            color: #fff;
            line-height: 70px;
            border-radius: 10px;
            text-align: center;
        }
        .item:nth-child(1){
            background-color: #8080E7;
        }
        .item:nth-child(3){
            background-color: #6060EC;
        }
        .item:nth-child(5) {
            background-color: #2222DA;
        }
        .arrow{
            height: 70px;
            line-height: 70px;
            background-color: #333333;
        }
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        .column {
            width: 280px;
            .loaf{
                margin-top: 15px;
                padding: 0 20px 20px;
                background-color: rgb(249,250,253);
                border: 1px dashed rgb(191,197,207);
                border-radius: 10px;
                .loaf-title{
                    padding: 0 10px 4px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #202031;
                    background-color: #e3e3ee;
                    border-radius: 0 0 4px 4px;
                }
                .list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    padding-top: 10px;
                    a{
                        color: #7C8793;
                        margin-right: 15px;
                    }
                    .item{
                        margin-top: 16px;
                        width: 30%;
                        span{
                            border-bottom: 1px solid #bfc5cf;
                        }
                    }
                }
            }
        }
    }

    .filter{
        border-bottom: 1px solid #f4f4fa;
        padding-bottom: 30px;
        .label-group{
            justify-content: flex-start;
            flex-wrap: wrap;
            .active{
                color: #fff;
                background-color: #333333;
                span{
                    color: #fff;
                }
            }
        }
        
        .label{
            width: 160px;
            height: 40px;
            line-height: 36px;
            font-size: 14px;
            margin-right: 20px;
            margin-bottom: 20px;
            color: #666666;
            text-align: center;
            background-color: #EEEEEE;
            cursor: pointer;
            span{
                color: #333333;
            }
            .nums{
                margin: 0 4px;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
            }
        }
    }
    .column{
        position: relative;
        padding-top: 16px;
        .column-title {
            position: absolute;
            top: 16px;
            left: 0;
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }
        .category {
            padding-left: 80px;
            display: flex;
            flex-flow: row wrap;

            .cate-label {
                height: 30px;
                padding: 0 10px;
                line-height: 30px;
                color: #666666;
                font-size: 14px;
                cursor: pointer;
            }

            .active {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }
        }
    }

    .relate-footer{
        display: flex;
        justify-content: flex-end;
        .oprate{
            width: 48px;
            height: 40px;
            margin-left: 20px;
            line-height: 36px;
            border: 1px solid #f4f4fa;
            text-align: center;
            cursor: pointer;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    .tab-bar{
        background: #fff;
        border-radius: 0;
        padding: 0;
        margin: 2em 0;
        .item{
            font-size: 14px;
            border-right: 1px solid #EEEEEE;
            height: 24px;
            line-height: 38px;
            color: #333;
            cursor: pointer;
            margin-right: 15px;
            padding: 0px 16px 0px 0;
        }
        .item:nth-last-child(1){
            border-right: 0;
        }
        .active{
            font-weight: 800;
        }
    }
}
.board-tabcharts{
    padding: 20px 0;
}