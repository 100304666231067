@import "styles/_mixin";
$primary_color:#000;

.ant-tabs-ink-bar{
    background: #333333;
}
.collection-con{
    .search-select-box{
        border-bottom: 2px solid #000;
    }
    margin-top: 50px;
}
.collect-con{
    margin-top: -20px !important;
}
.recommend-con-fixed, .sidemenu-box-fixed{
    position: fixed;
    top: 66px;
    z-index: 100;
    .search-select-box,.sidemenu-box{
        margin-top: 40px;
    }
}
.recommend-con-fixed{
    z-index: 100;
    background-color: #fff;
}
.collect-main{
    width: 960px;
    // position: relative;
    .search-select-box {
        padding-bottom: 14px;
        padding-top: 10px;
        background: #fff;
        width: 960px;
        // padding: 0 20px;
    }
}
.collectdetail-box{
    width: 1150px;
}
.collect-box{
    background-color: transparent;
    margin-top: -20px;
    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        -webkit-transform: rotate(40deg) translateX(3px);
        transform: rotate(40deg) translateX(3px);
    }
    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        -webkit-transform: rotate(-40deg) translateX(-3px);
        transform: rotate(-40deg) translateX(-3px);
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
        right: 30px;
        &::before, &::after {
            width: 8px;
            height: 2px;
        }
        &::before {
            -webkit-transform: rotate(-40deg) translateX(3px);
            transform: rotate(-40deg) translateX(3px);
        }
        &::after {
            -webkit-transform: rotate(40deg) translateX(-3px);
            transform: rotate(40deg) translateX(-3px);
        }
    }
    .ant-menu-title-content {
        overflow: visible !important;
    }
    .search-select{
        height: 50px;
        // padding: 0 20px;
        .search-select-box{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .ant-input{
                border-right: 0 !important;
                border-radius: 0 !important;
            }
            .form-box {
                display: flex;
                justify-content: space-between;
                .input-box{
                    height: 34px;
                    width: 216px;
                    input{
                        // border-radius: 8px;
                        border-color: #333333;
                    }
                    .ant-input-affix-wrapper{
                        padding: 0 11px;
                        height: 34px;
                        border-right: 0 !important;
                        border-radius: 0 !important;
                    }
                }
               
                
                button{
                    width: 70px;
                    height: 34px;
                    background-color: #333333;
                    // border-radius: 8px;
                    &:hover{
                        background-color: #333333;
                        border-color: #333333;
                    }
                }
                .ant-btn .ant-btn-primary{
                    border-radius: 0 !important;
                    border: 0 !important;
                }
                
            }
            .searchOutlined{
                width:26px;
                height:30px;
                display:flex;
                align-items:center;
                justify-content:center;
                font-size: 24px;
                color: #333;
            }
            .searchOutlined-active{
                border:1px solid #333;
            }
            .paixu-box{
                width: 120px;
                display: flex;
                justify-content: center;
                position: relative;
                .icon-paixu,.icon-ic_qiehuan{
                    font-size: 24px;
                    position: absolute;
                    left: 50%;
                    top: -2px;
                }
            }
            
        }
        
    }
    .recommend-item{
        // padding-left: 10px;
        width: 100%;
        height: 123px;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-top: 20px;
        font-size: 14px;      
        color: #333333;
        cursor: pointer;
        // .img-box{
        //     width: 120px !important;
        //     height: 67px !important;
        //     background: #f6f9fe;
        //     // border-radius: 5px;
        //     display: flex;
        //     display: -webkit-flex;
        //     justify-content: center;
        //     align-items: center;
        //     overflow: hidden;
        //     img{
        //         width: 100%;
        //         height: 100%;
        //         margin: 0 auto;
        //     }   
        // }
       
        .introduce{
            width: 300px;
            margin-left: 20px;  
        }
        .all{
            width: 100px;
            // margin: 0 100px 0 100px ;
        }
        .date{
            width: 200px;
        }
    }
  
    .load-more{
        width: 100px;
        height: 40px;
        border: 1px solid #333333;
        margin:20px auto 50px;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        letter-spacing: 1px;
        cursor: pointer;
    }
    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
        border-bottom: 1px solid #333333;
    }
    .ant-tabs-tab.ant-tabs-tab-active{
        // border-bottom: 2px solid #333333;
    }
    .ant-tabs-tab-btn{
        font-size: 18px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        font-weight: 800;
        color: #333333;
    }
    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
        margin-bottom: 0;
    }
    .ant-tabs{
        width: 100%;
    }
    .mycollect-con{
        position: relative;
        // padding-left: 20px;
        margin-top: 20px;
        height: 730px;
        width: 1200px;
        .collect-main{
            width: auto;
        }
        .mycollecttab{
            width: 100%;
        }
        .ant-tabs-tabpane{
            width: 100%;
            display: flex;
        }
        .creat-btn-box{
            position: absolute;
            right: 20px;
            z-index: 99;
            top: -20px;
            // 新建专辑
            .new-collect{
                border: 1px solid #000;
                color: #000;
                font-weight: 500;
                font-size: 16px;
                height: 40px;
                // border-radius: 5px;
                margin: 20px 0 0 24px;
                padding-left: 5px !important;
                .anticon .anticon-plus, .ant-btn > .anticon + span, .ant-btn > span + .anticon{
                    margin-left: 0 !important;
                }
                &:hover{
                    background-color: $primary_color;
                    color: #fff;
                }
            }
        }
        .my-basic{
            position: relative;    
        }
        
    }
    .mycollect-con.collection-con {
        height: auto;
    }
    .mycollect{
        .mycollect-item{
            width: 100%;
            border-bottom: 1px solid #DDDDDD;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;      
            color: #333333;
            cursor: pointer;
            .introduce{
                width: 157px;
                // margin-left: 20px; 
                min-height: 80px;
                padding: 20px 0; 
                display: flex;
                align-items: center;
            }
            .all{
                min-height: 80px;
                width: 100px;
                margin: 0 100px 0 100px ;
                padding: 20px 0;
                display: flex;
                align-items: center;
            }
            .date{
                width: 174px;
                min-height: 80px;
                padding: 20px 0;
                display: flex;
                align-items: center;
            }
        }
        .ant-breadcrumb{
            border-bottom: 2px solid #000;
            margin-top: 20px;
            padding-bottom: 20px;
        }
        .my-basic{
            margin-top: 0;
        }
        .ant-breadcrumb{
            padding: 0;
            margin: 0;
        }
        
    }
    .mycollect-detail-item{
        width: 353px;
        height: 70px;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        font-size: 14px;      
        color: #333333;
        padding-right: 20px;
        cursor: pointer;
    }
    .recommendlist{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .recommendlist-pos{
        position:  relative;
        left: 202px;
        top: 65px;
    }
    .myrecommendlist-pos{
        position:  relative;
        left: 200px;
        // top: 65px;
    }
    .ant-breadcrumb{
        margin: 20px 0;
    }
}
.mydetail-box {
    position: relative;
    .cancel-btn{
        top: 20px;
        right: 0;
    }
    .collection-con{
        margin-top: 0;
    }
    .collect-rt{
        margin-bottom: 10px;
        padding: 0 !important;
        .my-basic{
            margin-top: 0;
            h1{
                margin-top: 0;
            }
        }
    }
    .custom-page{
        margin-left: -30px;
    }
    .ant-pagination-item-active{
        background-color: #333333;
        a{
            color:#fff !important;
        }
    }
}
.detail-collection-con{
    .ant-layout-content .collect-main{
        width: 1200px !important;
    }
    // .ant-breadcrumb{
    //     padding: 30px 0 ;
    // }
    .search-select{
        position: relative;
        .search-select-box{
            position: absolute;
            right: 20px;
            top: 5px;
            width: fit-content;
        }
        .ant-tabs-tab{
            padding: 8px 0;
        }
    }
    position: relative;
    .load-more{
        width: 80px;
        height: 30px;
        border: 1px solid #333;
        font-size: 12px;
        line-height: 28px;
    }
}
.cancel-btn{
    // width: 14px;
    // height: 18px;
    background: #FFFFFF;
    // box-shadow: 0px 2px 6px 0px #EEEEEE, 0px 2px 4px 0px #EAEAFF;
    text-align: center;
    // font-size: 20px;
    // line-height: 15px;
    .iconfont{
        color: #333333;
    }
    cursor: pointer;
    img{
        width: 100%;
    }
    position: absolute;
    top: 20px;
    right: 10px;
}
.search-input{   
    opacity: 0;
    height: 30px;
    width:10px !important; 
    transition-duration:1s;
    transition-property:all;
    border: 1px solid #333;
}
.search-input-show{
    display: block !important;
    width: 216px !important;
    opacity: 1;

}
.ant-input:focus {
    box-shadow: none;
}
.collection-con, .detail-collection-con{
    flex-direction: row;
    background-color: transparent;
    .ant-anchor-wrapper{
        padding: 0;
        &::-webkit-scrollbar{
            display: none;
        }
        .ant-anchor-ink{
            left: -2px;
        }
    }
    // 左侧导航
    .sidemenu-box{
        // height: 400px;
        // border-right: 1px solid #333;
        .new-collect{
            border: 1px solid #000;
            color: #000;
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            // border-radius: 5px;
            margin: 20px 0 0 24px;
            padding-left: 5px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            .ant-btn > .anticon + span, .ant-btn > span + .anticon{
                margin-left: 0 !important;
            }
            &:hover{
                background-color: $primary_color;
                color: #fff;
            }
        }
    }
    .ant-menu{
        width: 200px;
        border-right:none;
        // border-color: #333;
        .ant-menu-sub.ant-menu-inline{
            max-height: 45vh;
            overflow-y: auto;
            overflow-x: hidden; 
            @include ScrollBarStyle()
        }
        > .ant-menu-submenu > .ant-menu-submenu-title{
            width: 200px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            // font-weight: 600;
            text-align: left;
            padding: 0;
            // padding-left: 40px;
            cursor: pointer;
            color: #333333;
        }
     
        .ant-menu-item{
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            a{
                color: #333333;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                padding-bottom: 5px;
            }
            a:hover{
                font-weight: 800;
            }
        }
        .ant-menu-item-selected{
            // color: $primary_color;
            font-weight: 800;
            // background:linear-gradient(90deg,rgba(243,244,250,1) 0%,rgba(255,255,255,1) 100%);
            background-color: #fff;
            color: #333333 !important  ;
            &::after{
                display: none;
            }
        }
        .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
            color: #000 !important;
            border-bottom: 1px solid #000;
            font-weight: 800 ;
        }
        .ant-menu-item:active, .ant-menu-submenu-title:active{
            background: none;
            color: #333 !important;
            .ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after{
                color: #333333 !important;
            }
        }
        .ant-menu-submenu-title:hover{
            color: #333 !important;
            & >.ant-menu-submenu-arrow, & >.ant-menu-submenu-arrow:before, & >.ant-menu-submenu-arrow:after{
                color: #333333 !important;
            }
        }
        .ant-menu-submenu-active, .ant-menu-submenu-selected{
            color:#333 !important;
            .ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after{
                color: #333 !important;
            }
        }
        
        // 新建专辑
        .new-collect{
            border: 1px solid #000;
            color: #000;
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            // border-radius: 5px;
            margin: 20px 0 0 24px;
            padding-left: 5px !important;
            .anticon .anticon-plus, .ant-btn > .anticon + span, .ant-btn > span + .anticon{
                margin-left: 0 !important;
            }
            &:hover{
                background-color: $primary_color;
                color: #fff;
            }
        }
    }
    // 右侧主体部分
    .collect-rt{
        padding: 0 10px 0 40px;
        background: #fff;
        // width: 1000px;
        section{
            h1.title{
                font-size: 16px;
                color: #333333;
                line-height: 36px;
                justify-content: space-between;
                margin-bottom: 20px;     
                border-bottom: 2px solid #000;
                padding-bottom: 10px;
                font-weight: 800;
                > .rt-btn {
                    display: flex;
                }
                .collect_btn{
                    border: 1px solid #333333;
                    // border-radius: 4px;
                    height: 36px;
                    color: #333333;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 36px;
                    padding:0 15px;
                    margin-right: 15px;
                    cursor: pointer;
                    i{
                        display: none;
                    }
                    &:hover{
                        color: #fff;
                        background-color:$primary_color;
                    }
                }
            }
        }
    }
    // 基本信息
    .collect-basic{
        padding: 0 0 20px 0;
        // margin-top: 20px;
        >div{
            // margin-bottom: 20px;
            .basicimg{
                width: 100px;height: 55px;margin-right: 20px;margin-top: 5px;
            }
            h1{
                font-size: 16px;color: #333333;font-weight: 800;
            }
           
        }
        .title-box{
            margin-left: 20px;
        }
        .collect-introduce-box{
            border-bottom: 1px solid #EEEEEE;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        // 简介
        >p{
            line-height: 26px;color: #7C8793;
        }
    }
    p.statistics{
        span{
            margin-right: 20px;
            color: #262626;
            &:first-child{
                font-size:16px;font-weight: 500;
                strong{ margin: 0 10px; font-size:20px; color: #1F3992;}
            }
            &:last-child{
                font-size: 12px;
                color:#666666;
            }
            
        }
        margin-top: -5px;
    }
    // 可收藏的列表
    .collect-lists{
        margin-top: 40px;
        .open_btn{
            display: block;
            text-align: center;
            margin-top: 20px;
            cursor: pointer;
            color: #666666;
            .icon.iconfont{
                font-size: 16px;
                margin-left: 8px;
            }
        }
        .list_ul{
            padding: 20px;
            a{
                display: inline-block;
                width: 16.6%;
                text-align: left;
                color: #232F3F;
                font-size: 12px;
                line-height: 32px;
                padding:0 10px;
                font-weight: 500;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                &:hover{
                    color: #333333;
                }
            }
        }
    }
    // 内容详情
    .collect-detail{
        margin: 40px 0 20px;
        .list{
            padding: 20px 40px;
            // margin-bottom: 40px;
            div.title{
                position: relative;
                display: flex;
                .collect_btn{
                    position: absolute;
                    top: 0;right: 0;
                    text-align: center;
                }
                h1{
                    display: inline-block;
                    vertical-align: top;
                    padding-top: 6px;
                    span{
                        display: block;
                        font-size: 14px;
                        &:first-child{
                            a{
                                font-size: 14px;
                                color: #202031;
                                font-weight: 600;
                                line-height: 30px;
                                &:hover{
                                    color: $primary_color;
                                }
                            }
                        }
                        &:last-child{
                            color:#1F3992;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                }
                .custom-avatar,.custom-no-avatar{
                    width: 60px;
                    height: 60px;
                    border-radius: 0;
                }
            }
            .des{
                color: #333333;
                font-size: 12px;
                line-height: 24px;
                margin-top: 11px;
                
            }
            
        }
    }
    // 我的收藏 - 基本信息
    .my-basic{
        margin-top: 20px;
        position: relative;
        h1{
            color: #333333;
            font-size: 16px;
            margin: 20px 0;
            font-weight: 800;
        }
        .des{
            color: #7C8793;
            line-height: 26px;
            text-align: justify;
            width: calc( 100% - 200px );
        }
        .actions{
            position: absolute;
            top: 0;right: 0;
            span{
                display: inline-block;
                margin: 0 10px;
                cursor: pointer;
                text-align: center;
            }
            i{
                height: 36px;width: 36px;
                border-radius: 50%;
                border: 1px solid #F4F4FA;
                display: flex;
                display: -webkit-flex;
                justify-content: center;
                align-items: center;
                background-color: #F4F4FA;
                margin-bottom: 6px;
                color: #9B9BAB;
                &:hover{
                    background-color: $primary_color;
                    color: #fff;
                }
            }
            .delete{
                i{
                    &::before{
                        content: '';
                        background-repeat: no-repeat;
                        width: 24px;display: inline-block;
                        height: 24px;
                    }
                    &::before{
                        background-image: url('../../assets/images/collect/delete.svg');
                    }
                    &:hover{
                        &::before{
                            background-size: 70%;
                            background-position: center;
                            background-image: url('../../assets/images/collect/delete-active.svg');
                        }
                    }
                }
                
            }
        }
    }
    // 我的收藏企业列表
    .my-lists{
        margin-top: 40px ;
        position: relative;
        // 人物对比
        #compare{
            position: absolute;
            top: 0;
            right: -60px;
            #toast{
                z-index: 10;
            }
        }
        .collect-table{
            a{
                color: #333333;
            }
            .ant-pagination{
                margin: 0;
                padding: 40px 0 10px;
            }
            .icon-collection-del{
                color: #9B9BAB;
                font-weight: 500;
                &:hover{
                    color: $primary_color;
                }
            }
        }
    }

    // 添加新条目
    .add-lists{
        // margin:  30px 0 100px;
        text-align: center;
        .ant-btn{
            border: 1px solid #333333;
            height: 40px;
            line-height: 40px;
            color: #333333;
            text-align: center;
            &:hover{
                color: #fff;
                background-color: $primary_color;
            }
        }
    }
}
.ant-btn-primary{
    background: #333;
}
.ant-btn-primary:hover{
    background: #333;
}
@media (max-width:1440px) {
    .collection-con .ant-menu .ant-menu-item{
        line-height: 48px;
        height: 48px;
    }
}
.empty{
    width: 100%;
    padding-top: 48px;
    text-align: center;
    .title{
        font-size: 16px;
        color: #202031;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .tips{
        font-size: 14px;
        color: #9094a0;
    }
    img{
        width: 50px !important;
        height: 60px !important;
    }
}
#emptyimg{
    width: 200px;
    height: 131px;
    text-align: center;
    margin-top: 50px;
}
.emptyimg-boxs{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}
.mycollectdetaillist{
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.swiper-box ,#swiperSlide{
    width: 100%;
    height: 400px;
    .swiper-container{
      height: 400px;
    }
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    // background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 60px;
    background: url('../../assets/images/collect/banner2.png') no-repeat;


    .swiper-left{
      width: 546px;
      .swiper-left-title{
        width: 502px;
        height: 62px;
        font-size: 44px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 62px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        margin-top: -40px;
      }
  
      .swiper-left-content{
        width: 546px;
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        text-align: left;
        margin: 10px 0 20px 0;
        overflow: hidden;
        display:-webkit-box; 
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2; 
        text-overflow: ellipsis;
      }
  
      .swiper-left-click-btn{
        width: 80px;
        height: 30px;
        background: #FFFFFF;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }
    //   display: none;
    }
    .swiper-right{
      text-align: left;
    //   display: none;
      .swiper-right-item{
        width: 400px;
        height: 100px;
        background: #FFFFFF;
        opacity: 0.5;
        padding: 15px;
        margin-top: 15px;
        cursor: pointer;
        .swiper-right-title{
          // width: 226px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 22px;
          margin-top: -3px;
          font-weight: 800;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .swiper-right-content{
          // width: 319px;
          // height: 32px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
          overflow: hidden;
          display:-webkit-box; 
          -webkit-box-orient:vertical;
          -webkit-line-clamp:3; 
          text-overflow: ellipsis;
          
        }
      }
    }
    .textblock{
      display: block;
    }
  }
 
.swiper-button-next, .swiper-button-prev{
  --swiper-theme-color:#ccc
}

.dropdown{
    .ant-select-item-option-content{
        text-align: center;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 8px;
        // font-size: 12px;
        // width: fit-content;
    }
    .ant-select-item-option:nth-last-of-type(1){
        .ant-select-item-option-content{
            border-bottom: none;
        }
    }
    .ant-select-item .ant-select-item-option .ant-select-item-option-active{
        background: #fff;
    }
    .ant-select-item-option-selected{
        background-color: #fff !important;
    }
   
    .ant-select-focused{
        box-shadow: none !important;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 0;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled){
        background: #fff;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
        border-color: #333;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        border-color: #333;
        box-shadow: none !important;
        -webkit-box-shadow:none !important;
    }
    
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: #333;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #333;
    box-shadow: none !important;
    -webkit-box-shadow:none !important;
}

