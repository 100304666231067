// 颜色自定义
$primary_color:#232F3F;

@import 'styles/comps/tabcharts';
@import 'styles/comps/news';


.park-container {
    // 主体内容
    .layout-rt {
        padding: 0 10px;
        background: #fff;

        // main
        .park-main {
            section {
                padding: 20px 0;

                .header-title h1.flex_box {
                    justify-content: flex-start;
                    width: 88%;
                }

                >h1 {

                    font-size: 22px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #202031;
                    margin-top: 0;
                    margin-bottom: 20px;
                    // border-bottom: 2px solid #333333;
                    // padding-bottom: 20px;

                    >span {
                        color: #262626;
                        font-size: 14px;
                    }
                }
                .noborder{
                    border: none;
                }

                h1.flex_box {
                    justify-content: space-between;

                    .ant-btn-link {
                        color: #9094A0;

                        &:hover {
                            color: $primary_color;
                        }
                    }
                }

                ul.fn-ul {
                    padding-left: 0;
                    // width: 28%;
                    // text-align: right;
                }

                >.box_shadow {
                    padding: 20px;
                }
            }

            section.market {
                >.box_shadow {
                    padding: 0;
                }
            }

            // 园区服务
            .service {
                >h1 {
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #232F3F;
                    line-height: 26px;

                }

                >hr {
                    margin: -20px 0 20px 0;
                    border: 1px solid #333333;
                }

                >ul {
                    justify-content: space-between;

                    >li {
                        width: 22%;
                        text-align: center;
                        cursor: pointer;
                        padding: 20px;

                        i{
                            font-size: 40px;
                        }

                        &:hover {
                            background-color: #fff;
                            box-shadow: 0px 0px 0px 1px #333333;
                        }

                        >h2 {
                            font-size: 16px;
                            line-height: 22px;
                            color: #121F3D;
                            margin-top: 20px;

                            &::after {
                                width: 20px;
                                height: 2px;
                                background-color: #232F3F;
                                content: '';
                                display: block;
                                margin: 20px auto;
                            }
                        }

                        >p {
                            text-align: left;
                        }

                        .no-service {
                            text-align: center;
                            margin-top: 50px;
                            color: #ccc;
                        }
                    }
                }

            }

            // 园区影响力
            .effect {
                >h1 {
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #232F3F;
                    line-height: 26px;

                }

                >hr {
                    margin: -20px 0 20px 0;
                    border: 1px solid #333333;
                }

                .graph {
                    width: 1100px;
                    height: 500px;
                }

                .effect-ul {
                    padding-left: 20px;

                    >li {
                        border: none;
                        list-style: disc;
                        color: #121F3D;
                        font-size: 14px;
                        margin-bottom: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 28px;
                        padding: 0;
                    }
                }
            }

            // 园区头部
            .header-title {
                justify-content: space-between;

                > h1 {
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 28px;


                    span {
                        font-size: 14px;
                    }

                    i {
                        display: inline-block;
                        background-color: #f9f9f9;
                        font-size: 12px;
                        margin-top: 0px;
                        padding: 2px 8px;
                        font-style: normal;
                        margin-right: 15px;
                        border-radius: 5px;
                        margin-top: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;

                    }
                }

                ul {
                    li {
                        list-style: none;
                        float: left;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 10px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }

            // 简介
            .summary {
                margin-top: 20px;
                display: flex;
                .nr {
                    width: 70%;

                    .btn-zs {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #232F3F;
                        line-height: 17px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                dl,
                ul {
                    color: #7C8793;
                    font-size: 14px;
                    line-height: 24px;
                }

                ul {
                    width: calc(30% - 30px);
                    margin-left: 30px;
                    flex-direction: column;
                }

                .link_ul li {
                    color: #333333;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: flex-start;
                    padding-left: 40px;
                    border-left: 1px solid #333333;

                    span {
                        &:first-child {
                            white-space: nowrap;
                        }

                        &:last-child {
                            word-break: break-all;
                        }
                    }

                    p {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4A4A4A;
                        line-height: 26px;
                        width: 200px;
                        height: 26px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin: 0;
                    }


                    >dl {
                        width: 70%;
                        text-align: justify;
                    }

                    .operate {
                        color: #232F3F;
                    }

                }

                .text-body {
                    height: 100px;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    color: #333333;
                }

                .text-auto {
                    height: auto;
                    overflow: visible;
                    color: #333333;
                }

                .park-info {

                    >dl,
                    >ul {
                        display: inline-block;
                        vertical-align: top;
                        padding-left: 40px;
                        border-left: 1px solid;
                        height: 100%;
                    }
                }

                .dt-top {
                    justify-content: space-between;

                    li {
                        width: 13%;
                        color: #9094A0;
                        flex-direction: column;
                        align-items: center;
                        padding: 20px 0;

                        span {
                            color: #000;
                            font-size: 22px;
                            vertical-align: bottom;
                            font-weight: 500;

                            i {
                                font-style: normal;
                                font-size: 12px;
                                color: #4A4A4A;
                                margin-left: 4px;
                            }
                        }
                    }
                }

                // DT 指数
                .dt-index {
                    >h1 {
                        .anticon {
                            font-size: 14px;
                            color: #9094A0;
                            margin-left: 10px;
                        }
                    }

                    >div.flex_box {
                        align-items: center;
                        justify-content: space-between;

                        >ul {
                            margin-bottom: 0;
                            width: 80%;

                            li {
                                width: 25%;
                            }
                        }
                    }

                    li {
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #EBF0F2;

                        &:last-child {
                            border: none;
                        }

                        span {
                            display: inline-block;
                            margin-right: 15px;
                            color: #9094A0;
                            font-size: 14px;
                            line-height: 26px;

                            strong {
                                color: #111F3F;
                                font-size: 24px;
                            }

                            .iconfont {
                                font-size: 24px;
                                color: #232F3F;
                            }

                            &:first-child {
                                width: 56px;
                                height: 56px;
                                border-radius: 7px;
                                background-color: #F9FAFD;
                                display: flex;
                                display: -webkit-flex;
                                justify-content: center;
                                align-items: center;
                            }

                            &:last-child {
                                text-align: left;
                            }
                        }
                    }

                    .dt-radar {
                        width: 20%;
                        // height: 120px;
                        display: inline-block;

                        .radar {
                            width: 100%;
                            height: 130px;
                        }
                    }
                }

                // 入驻企业
                .settled {
                    .ant-table-wrapper {
                        margin-top: 30px;
                    }

                    .ant-table-thead>tr {
                        >th {
                            border: none;
                        }
                    }

                    .ant-table-tbody>tr {
                        cursor: pointer;

                        &:nth-child(2n) {
                            background-color: #F9FAFD;
                        }

                        &:nth-child(2n+1) {
                            background: rgba(255, 255, 255, 1);
                            box-shadow: 0px 3px 15px 0px rgba(240, 242, 247, 1);
                        }
                    }

                    .ant-table-tbody>tr>td {
                        border: none;
                    }
                }

                // 投资动向
                .invest-trend {
                    padding: 20px;
                    justify-content: space-between;

                    .park-bar {
                        width: 45%;
                        display: flex;
                        display: -webkit-flex;
                        align-items: center;
                    }

                    ul {
                        width: 50%;
                        background-color: #F9FAFD;
                        border: 20px;
                        padding: 20px;

                        li {
                            margin-bottom: 20px;
                            display: flex;
                            align-items: baseline;

                            &:last-child {
                                margin: 0;
                            }

                            &::before {
                                content: '';
                                width: 16px;
                                height: 8px;
                                background-color: #2222DA;
                                display: inline-block;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            section.parklist {
                >.box_shadow {
                    padding: 0;
                }
            }
        }
    }

    ul.service-ul {
        >li {
            margin-bottom: 10px;
            padding: 8px 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;


            &:nth-child(4n+1) {
                // background-color: #F0FBF7;
                background-color: #F9F9F9;
            }

            &:nth-child(4n+2) {
                // background-color: #FEF5EE;
                background-color: #F9F9F9;
            }

            &:nth-child(4n+3) {
                // background-color: #E5F1FF;
                background-color: #F9F9F9;
            }

            &:nth-child(4n+4) {
                // background-color: #F0F0FF;
                background-color: #F9F9F9;
            }
        }

        li.more {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232F3F;
            line-height: 17px;

            >li {
                margin-bottom: 10px;
                padding: 8px 5px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 17px;

            }
        }
    }



    // 切换按钮 入驻企业 line和table切换
    .ant-radio-group.toggle-btn {
        background-color: #F9FAFD;
        padding: 10px;

        .ant-radio-button-wrapper {
            border: none;
            background: none;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background-color: #fff;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
            outline: none;
        }
    }
}

// 园区列表页面
@import 'styles/comps/search';
@import 'styles/comps/table';

// 园区列表
.park-container.company-con {
    background: none;

    .layout-rt {
        padding: 0;
    }

    .layout-rt .park-main section.parklist {
        padding: 0;
        margin-top: 20px;
    }

    .top-border {
        border: 1px solid #333333;
        background-color: #333333;
        margin: 50px 0;
    }

    .park-table {
        padding-left: 40px;

        .ant-table-thead>tr,
        .ant-table-tbody>tr {
            height: 28px;
            line-height: 28px;
        }

        .ant-table-tbody>tr>td:first-child {
            text-align: left;
            padding-left: 40px;
        }
    }
}

#parklist {
    .select-tag {

        &:nth-of-type(1),
        &:nth-of-type(2) {
            .toggle-btn {
                opacity: 0;
            }
        }

        &:nth-of-type(3) {
            margin-bottom: 20px;
        }
    }
}

.parklist {
    padding: 0;

    p {
        margin-bottom: 40px;
    }

    .geo-radios {
        margin-left: 40px;

        .ant-radio-inner,
        .ant-radio {
            display: none;
        }

        .ant-radio-wrapper-checked {
            color: $primary_color;
            font-size: 16px;
        }
    }
}

// 招商工作台
.platform {
    .company-list {
        h1.flex_box {
            justify-content: space-between;

            .cart-btn>span {
                border: 1px solid #C9C9C9;
                border-radius: 3px;
                color: #333333;
                font-size: 14px;
                display: inline-block;
                padding: 5px 10px;
                margin: 0 15px;
                cursor: pointer;

                &:hover {
                    color: #333333;
                    border-color: #333333;
                }
            }
        }
        i.iconfont.icon-park-cart{
            margin-left: 10px;
            font-size: 24px;
            color: #999;
            cursor: pointer;
            &.active{
                color: #333;
            }
        }
    }
}

// 园区服务弹框
.park-service-modal {
    .ant-modal-content {
        padding: 50px 80px;
    }

    .ant-modal-header {
        text-align: center;
        border: none;

        .ant-modal-title {
            font-size: 32px;
            color: #121F3D;
            margin-bottom: 40px;
        }
    }

    .ant-modal-body {
        padding: 0;

        >ul.service-ul {
            flex-wrap: wrap;

            >li {
                width: 31%;
                margin-right: 3%;
                padding: 8px 30px;
                text-align: center;
                margin-bottom: 20px;
                background-color: #f9f9f9;

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }
        }
    }
}