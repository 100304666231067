.sign{
    display: inline-block;
    max-width: 200px;
    height: 24px;
    padding: 2px 10px 0 10px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    // border-radius: 4px;
    cursor: default;
}

.sign-orange{
    // background-color: #FFF1E2;
    // color: #ED6400;
    // background-color: #F0F3FD;
    // color: #2222DA;
    background: #F9F9F9;
    color: #999999;
}

.sign-blue {
    // background-color: #E3EFFF;
    // color: #1975D0;
    background: #F9F9F9;
    color: #999999;
}

.sign-purple {
    // background-color: #F5F0FF;
    // color: #764CED;
    background: #F9F9F9;
    color: #999999;
}