.academic-box{
    padding-right: 20px;
    border-right: 1px solid  #EEEEEE;
}
.academic{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ranking-list {
    display: flex;
    justify-content: space-between;
    // width: 48%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    // background-color: #F7F9FE;
    font-size: 16px;
    
    .left {
        img {
            margin-right: 1em;
        }

        span {
            font-weight: bold;
            color: #232F3F;
        }
    }

    .order {
        // width: 100px;
    }

    .nums {
        font-weight: bold;
        font-size: 20px;
        margin: 0 6px;
        color: #1F3992;
    }
}
.ranking-no-bottom{
    margin-bottom: 0;
}