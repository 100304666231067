@media screen and (max-width: 640px) {
    footer.footer-section {
        background: url('../../assets/images/official/bottom640.png') no-repeat;
    }
}

@media screen and (max-width: 1440px) {
    footer.footer-section {
        background: url('../../assets/images/official/bottom1440.png') no-repeat;
    }
}

@media screen and (max-width: 1920px) {
    footer.footer-section {
        background: url('../../assets//images/official/bottom1920.png') no-repeat;
    }
}

footer.footer-section {
    background: url('../../assets/images/official/bottom1440.png');
    background-size: 100% 100%;
    width: 100%;
    .foot-com {
        width: 1240px;
        margin: 0 auto;
        padding: 40px 0 20px 0;
        display: flex;
        height: 164px;
        justify-content: center;
        align-items: center;
    }
    .left {
        width: 220px;
        height: 66px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            width: 90px;
            // height: 27px;
        }

        p {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 28px;
            margin: 0;
        }
    }

    .center {
        padding: 0 40px;
        border-left: 1px solid #666666;
        border-right: 1px solid #666666;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .top {
            a {
                margin-right: 30px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                cursor: pointer;
            }
        }

        .cen {
            span {
                margin-right: 22px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #DCDCDC;
                line-height: 17px;
                margin: 20px 0 10px 0;
            }
        }

        .bottom {
            span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #DCDCDC;
                line-height: 17px;
            }
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 80px;
            height: 80px;
            margin-left: 80px;
        }

        >p {
            margin-left: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #DCDCDC;
            line-height: 17px;
        }
    }
    .footer-beian {
        width: 1240px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 20px;
        a {
            color: #fff;
            font-size: 12px;
            line-height: 24px;
            img {
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
            }
        }
    }
}