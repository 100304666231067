.des-con{
    // height: fit-content;
    color: #333333;
    font-size: 12px;
    overflow: hidden;
    margin-top: 10px;
}
.des-open{
    height: auto;
}
.open-cont{
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10PX;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 17px;
    letter-spacing: 1px;
    font-size: 12px;
    cursor: pointer;
    .iconfont{
        font-size: 14px;
        // transform: scale(0.7);
        margin-left: 5px;
    }
}
