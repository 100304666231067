// 自定义滚动条
@mixin ScrollBarStyle(){
  // 滚动条
  &::-webkit-scrollbar {
    width: 6px;
    /*对垂直流动条有效*/
    // height: 10px; /*对水平流动条有效*/
  }

  /*定义滚动条的轨道颜色、内阴影及圆角*/
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }


  /*定义滑块颜色、内阴影及圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .2);
    background-color: #ddd;
  }

  /*定义两端按钮的样式*/
  &::-webkit-scrollbar-button {
    background-color: transparent;
  }

  /*定义右下角汇合处的样式*/
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

// 出现自定义滚动条的限制条件
@mixin customScrollBar($maxHeight) {
  max-height: $maxHeight;
  overflow-y: auto;
  overflow-x: hidden;
  @include ScrollBarStyle()
}

// 隐藏滚动条
@mixin hiddenScrollBar() {
  scrollbar-width:none;/*Firefox*/
  -ms-overflow-style:none;/*IE10+*/
  &::-webkit-scrollbar{
    display:none; /*ChromeSafari*/
  }
}