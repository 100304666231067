.detail{
    display: flex;
}
.aside{
    width: 200px;
    .link-item{
        display:block;
        line-height: 56px;
        height: 56px;
        color: #333;
        font-size: 18px;
        text-align: left;
        padding: 0;
        padding-left: 40px;
        cursor: pointer;
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        &:hover {
            font-weight: 600;
        }
    }
    .active {
        font-weight: 600;
        &::after{
            content: '';
            display: block;
            width: 50%;
            height: 1px;
            position: absolute;
            bottom: 6px;
            border-bottom: 2px solid #000;
        }
        // background:linear-gradient(90deg,rgba(243,244,250,1) 0%,rgba(255,255,255,1) 100%);
    }
}
.content, .main{ 
    flex: 1;
}
.content-title{
    font-size: 20px;
    font-weight: bold;
    color: #121F3D;
    margin: 20px 0;
    span{
        margin-left: 1em;
        font-weight: normal;
        font-size: 14px;
        color: #262626;
    }
}
.content-box{
    padding: 20px 20px 0 20px;
    // box-shadow: 0px 0px 6px 0px rgba(234, 234, 255, 1);
    border-radius: 4px;
    .page-box{
        padding: 30px 0;
        margin: 0 auto;
        text-align: center;
    }
    .list{
        padding-top: 10px;
        border-bottom: 2px solid #f4f4fa;
        .item{
            padding-bottom: 24px;
            color: #797983;
            .title{
                margin: 1em 0;
                font-weight: bold;
                font-size: 14px;
                color: #121f3d;
                border-bottom: none;
                padding-bottom: 0;
            }
            
            .body{
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                .info{
                    width: 20%;
                }
                .info:first-child{
                    width: 40%;
                }
                .patent-info{
                    width: 50%;
                }
            }
            .link{
                cursor: pointer;
                margin-top: 1em;
                span:hover{
                    color:#232F3F
                }
            }
        }
    }
    .list-no-border-btom{
        border-bottom: 0;
    }

    .filter{
        display: flex;
        .left{
            width: 90px;
            height: 40px;
            line-height: 40px;
            text-align: left;
            color: #262626;
            font-size: 14px;
        }
    }
}
.patent-box{
    padding: 20px 20px 0 20px;
}
.action-bar{
    display: flex;
    justify-content: space-around;
    user-select: none;
    cursor: pointer;
    height: 40px;
    width: fit-content;
    // line-height: 40px;
    align-items: center;
    // background-color: #FAFAFC;
    color:#BABAC4;
    font-size: 12px;
    &> div{
        padding: 0 10px;
        border-right: 1px solid #eeeeee;
        height: fit-content;
    }
    &> div:nth-last-child(1){
        border-right: none;
    }
    .active{
        // border-radius: 4px;
        font-weight: bold;
        color: #232F3F;
    }
}

.quote{
    display: flex;
    justify-content: space-between;
    user-select: none;
    width: 280px;
    padding: 20px 0;
    div{
        height: 30px;
        padding: 0 16px;
        line-height: 30px;
        color:#BABAC4;
        font-size: 12px;
        cursor: pointer;
        // background-color: #FAFAFC;
        // border-radius: 8px;
    }
    .active{
        color: white;
        // background-color:#232F3F;
        font-weight: bold;
        color: #232F3F;
    }
}