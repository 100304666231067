    .all-box-vip {
        background-color: #FFFFFF;
        // margin-top: -20px;

        @media screen and (max-width: 640px) {
            .back-img {
                background: url('../../assets/images/official/bannerd640.png') no-repeat;
                background-size: 100% 100%;
                width: 100%;
            }
        }

        @media screen and (max-width: 1440px) {
            .back-img {
                background: url('../../assets/images/official/bannerd1440.png') no-repeat;
                background-size: 100% 100%;
                width: 100%;
            }
        }

        @media screen and (max-width: 1920px) {
            .back-img {
                background: url('../../assets/images/official/bannerd1920.png') no-repeat;
                background-size: 100% 100%;
                width: 100%;
            }
        }

        .back-imgs {
            height: 531px;
            background-color: red;
            background: url('../../assets/images/official/bannerd1440.png') no-repeat;
            background-size: 100%;
            width: 100%;
            margin-top: 66px;

            .vip-body {
                width: 1160px;
                display: flex;
                flex-direction: column;
                margin: 0 140px;

                >h1 {
                    margin-top: 70px;
                    font-size: 36px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 50px;
                }

                >span {
                    width: 580px;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 28px;
                    margin-bottom: 20px;
                }
            }


        }

        .vip-list {
            margin: -65px auto 180px;
            width: 1040px;
            padding: 40px;
            background-color: #FFFFFF;

            .vip-table {
                background-color: #EEEEEE;
                width: 100%;

                >tr {
                    height: 60px;
                    text-align: center;

                    &:nth-child(1) {
                        background: #232F3F;

                        >th {
                            font-size: 24px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 33px;
                        }
                    }

                    >th {
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 33px;
                    }
                }
            }
        }
    }


    @media (max-width: 1440px) {
        .back-img {
            width: 1440px;

            .vip-body {
                width: 1160px;
            }
        }
    }