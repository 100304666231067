// 颜色自定义
$primary_color:#232F3F;

.company-filters {
    margin-top: 20px;
    padding-bottom: 20px;

    >h1 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        color: #121F3D;
        margin-bottom: 20px;

        >span {
            color: #262626;
            font-size: 14px;
        }
    }
}

// 筛选项的 展开收起
.toggle-btn-sel {
    align-self: flex-start;
    margin-top: 7px;
    height: 24px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F6F6F6;
    color: #999;
    cursor: pointer;
    font-size: 12px;
    i{
        margin-left: 5px;
        font-size: 10px;
    }
}

// 一级菜单
.select-tag {
    margin: 10px 0;
    // justify-content: space-between;
    max-height: 500px;
    overflow: hidden;
    position: relative;

    >.title {
        width: 8%;
        display: inline-block;
        padding: 5px 0;
        font-size: 16px;
        color: #333333;
        font-weight: 800;
        position: relative;

        .anticon.anticon-info-circle {
            position: absolute;
            right: 15px;
            top: 8px;
            color: #9094A0;
        }
    }

    >div {
        width: 88%;
    }

    .ant-tag {
        &:nth-child(1){
            font-size: 16px !important;
        }
        font-size: 14px;
        color: #333;
        font-weight: 500;
        margin-right: 30px;
        padding: 5px 0;
        cursor: pointer;
    }

    .ant-tag-checkable-checked {
        background: none;
        font-size: 18px;
        font-weight: 800;
        color: #333333;
    }

    &:last-child {
        .toggle-btn {
            opacity: 0;
        }
    }
}
.hidden-text {
    max-height: 34px;
    overflow: hidden;
}

// 有搜索框的选项
.filter-box {
    overflow: visible;
    display: flex;
    > .tags_box {
        width: auto;
        overflow: hidden;
    }
    > .fiter-search-input {
        width:auto;
    }

}

.toggle-btn {
    align-self: flex-end;
    font-size: 15px;
    color: #aaa;
    line-height: 32px;
    cursor: pointer;
}

// dispaly tag list
.checked-box {
    justify-content: space-between;
    align-items: flex-start;

    >p {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .ant-tag {
        background-color: #EEEEEE;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
        margin-bottom: 10px;
        border-radius: 4px;
        font-size: 15px;
        color: #333333;
        border: none;
        font-weight: 500;

        .anticon.anticon-close {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            margin-left: 12px;
        }
    }

    .sub-tag {
        background-color: #fff;
        border: 1px solid #333333;
    }

    .ant-btn {
        color: #9292A3;
        font-size: 15px;
        border: none;
        margin-top: 14px;
    }
}

// 二级菜单
.sub-select-tag {
    background-color: #F6F6F6;
    margin-left: 8%;
    margin-top: -10px;
    padding: 8px 5px;

    >.title {
        color: #333;
        width: 70px;
        display: inline-block;
        margin: 0 0 0 15px;
        vertical-align: top;
    }

    >div {
        display: inline-block;
        width: calc(100% - 85px);
    }

    span {
        font-size: 15px;
        margin: 0 15px;
        padding: 5px 0;
        cursor: pointer;
    }

    .ant-tag {
        color: #9292A3;
    }

    .ant-tag-checkable-checked {
        background: none;
        font-weight: 800;
        color: $primary_color;
    }
}


// 字母筛选
.alpha-box {
    > .tags_box {
        .ant-tag:not(:first-child) {
            width: 24px;
            height: 24px;
            margin-top: 3px;
            margin-right: 6px;
            padding: 0;
            text-align: center;
            line-height: 24px;
            border: 1px solid #c9c9c9;
            border-radius: 4px;
        }
        .ant-tag-checkable-checked:not(:first-child) {
            border-color: #232F3F;
            font-size: 14px;
            color: #fff;
            background-color: #232F3F;
        }
    }
}