@import 'styles/_mixin';

.edit-collect-modal{
    .ant-modal-title{
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        color: #1A1A1A;
    }
    .ant-modal-header{
        border: none;
    }
    .ant-modal-body{
        width: 70%;
        margin: 0 auto;
        padding-bottom: 0;
    }
    input.ant-input{
        height: 36px;
    }
    .ant-modal-footer{
        border: none;
        text-align: center;
        width: 70%;
        margin: 0 auto;
        padding-bottom: 20px;
        button{
            width: 45%;
            height: 40px;
        }
        button + button{
            margin-left: 10%;
        }
    }
    .ant-select-selection--single{
        height: 36px;
    }
    .ant-select-selection__rendered{
        line-height: 36px;
    }
}

// 删除确定弹框
.delete-collect-confirm{
    .anticon-question-circle{
        display: none;
    }
    .ant-modal-confirm-body{
        text-align: center;
    }
    .ant-modal-confirm-btns{
        float: none;
        text-align: center;
        margin-top: 40px;
        .ant-btn{
            width: 120px;
            height: 40px;
            &:first-child{
                &:hover{
                    color: #D0021B;
                    border-color: #D0021B;
                }
            }
        }
        .ant-btn-primary{
            background-color: #D0021B;
            border-color: #D0021B;
            margin-left: 20px;
        }
    }
}

.add-collect{
    .ant-modal-body{
        width: 90%;
        >.tip{
            color: #CACACA;
            font-size: 14px;
            margin-bottom: 10px;
            text-align: left;
        }
        >ul{
            background: #F9FAFD;
            padding: 20px;
            @include customScrollBar(320px);
            li{
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                span{
                    border: 1px solid #333333;
                    color: #333333;
                    padding: 3px 20px;
                    // border-radius: 4px;
                    cursor: pointer;
                    display: inline-block;
                    width: 70px;
                    .add-btn{
                        width: 70px;
                    }
                    &:hover{
                        background-color: #333333;
                        color: #fff;
                    }
                }
                >div{
                }
            }
            
        }
        .no-tip{
            display: inline-block;
            width: 100%;
            color: #585858;
            height: 45px;
            line-height: 45px;
            background: #FFFCF0;
            box-shadow: 0px 1px 0px 0px #F2F2F2;
            opacity: 0.9;
            .anticon{
                color: #FFAF00;
                margin: 0 10px;
            }
        }
        .actions{
            text-align: center;
            padding: 20px 0;
            .anticon.anticon-plus{
                display: none;
            }
            .ant-btn {
                background-color: rgba($color: #232F3F, $alpha: 0.8);
                padding:10px 60px;
                height: auto;
                color: #fff;
                > .anticon + span{
                    margin-left: 0;
                }
            }
        }
    }
}

.collect_btn{
    i{
        height: 36px;
        width: 36px;
        border-radius: 50%;
        color: #232F3F;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        align-items: center;
        background-color: #F9F9F9;
        cursor: pointer;
        &:hover{
            background-color: #232F3F;
            color: #fff;
        }
    }
}

.box_shadow{
    box-shadow: 0px 2px 10px 0px #f0f2f7;
    border-radius: 12px;
    background-color: #fff;
}