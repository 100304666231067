@import 'styles/_mixin';
.all-box-report {
    background-color: #FFFFFF;

    .report-body {
        width: 1160px;
        margin: 0 auto 0;

        .body-one {
            .title {
                display: flex;
                justify-content: space-between;
                padding-top: 126px;

                p {
                    margin-bottom: -24px;
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                }

                .btn {
                    width: 140px;
                    height: 40px;
                    background: #ffffff;
                    border-radius: 4px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    line-height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #000000;
                }

                .btn:hover {
                    background: #000000;
                    color: #ffffff;
                }

            }

            .hr {
                border: 1px solid #333333;
                margin: 30px 0;
            }

            .all-body {
                display: flex;
                .left {
                    width: 70%;
                    height: 314px;
                    padding-right: 15px;
                    margin-right: 15px;
                    border-right: 1px solid #dcdcdc;
                    > a{
                        width: 34%;
                        display: inline-block;
                        position: relative;
                        > img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        > h3 {
                            position: absolute;
                            margin-bottom: 0;
                            bottom: 0;
                            width: 100%;
                            padding: 15px 10px;
                            color: #fff;
                            background-color: rgba($color: #000000, $alpha: .7);
                        }
                        &:first-child{
                            width: 64%;
                            float: left;
                            height: 100%;
                        }
                        &:nth-of-type(2),&:nth-of-type(3) {
                            float: right;
                            height: 48%;
                            > h3 {
                                font-size: 12px;
                                padding: 10px;
                            }
                            > img {
                                object-fit: fill;
                            }
                        }
                        &:nth-of-type(3) {
                            margin-top: 12px
                        }
                    }
                }
                
                .right {
                    @include customScrollBar(314px);
                    width: 30%;
                    max-height: 314px;
                    overflow: hidden;
                    overflow-y: auto;

                    .item {
                        margin-bottom: 20px;
                        .tit {
                            font-size: 16px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #000000;
                            line-height: 30px;
                        }

                        .article {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            margin: 4px 0;
                            line-height: 22px;
                            color: #666666;
                        }

                        .time {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            line-height: 20px;

                        }
                    }
                }
            }
        }

        .body-two {
            display: flex;
            height: 766px;

            .left {
                width: 760px;
                height: 620px;
                margin-right: 30px;

                .tit {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    line-height: 33px;
                    margin-bottom: 20px;
                }

                .body-top {
                    width: 730px;
                    border-top: 1px solid #DCDCDC;

                    .body-right {
                        height: 598px;
                        border-right: 1px solid #DCDCDC;
                        margin: 22px -30px 0 0;

                        .item {
                            display: flex;
                            margin-bottom: 20px;
                            cursor: pointer;
                            
                            .intro {
                                width: 534px;
                                height: 102px;
                                margin-left: 16px;

                                .ti {
                                    font-size: 18px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #333333;
                                    line-height: 25px;
                                    margin-bottom: 8px;
                                }

                                .bod {
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #666666;
                                    line-height: 26px;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }
            }

            .right {
                .tit {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    line-height: 33px;
                    margin-bottom: 20px;
                }

                .body {
                    width: 370px;
                    height: 620px;
                    padding-top: 30px;
                    border-top: 1px solid #DCDCDC;

                    .item {
                        width: 370px;
                        height: 283px;
                        margin-bottom: 24px;
                        cursor: pointer;

                        img {
                            width: 370px;
                            height: 205px;
                        }

                        .tit {
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 25px;
                            margin: 16px 0 14px 0;
                        }

                        .ti_all {
                            display: flex;
                            justify-content: space-between;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                }
            }
        }
    }

    .title-tip {
        background: yellow;
        width: 100%;
        height: 200px;
        background-image: url('../../assets//images//official/tip21440.png');
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 100px 0 100px 0;

        p {
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 42px;
            margin-bottom: 0;
        }

        a {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;

            >i {
                font-size: 22px;
            }
        }
    }


}

@media (max-width: 1440px) {
    .report-body {
        width: 1160px;
    }
}