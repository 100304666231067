.all-box-signup{
    .picture{
        width: 100%;
        padding-bottom: 10px;
        background-color: #18222F;
    }
    .picture:nth-child(5){
        width: 100%;
        padding-bottom:0;
    }
}

@media (max-width: 1440px) {
    .all-box {
        width: 1440px;
    }
}