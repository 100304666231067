.vip-modal {
    .ant-modal-header {
        background-color: #232F3F;

        .ant-modal-title {
            text-align: center;

            >img {
                width: 120px;
                height: 29px;
            }
        }
    }

    .ant-modal-body {
        text-align: center;

        >p {
            &:nth-child(1) {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
                margin: 15px 0 20px 0;
            }

            &:nth-child(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                margin-bottom: 50px;
            }

            &:nth-child(3) {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                margin-bottom: 10px;
            }

            &:nth-child(4) {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                margin-bottom: 50px;
            }
        }

        .tip1-btn {
            width: 100px;
            height: 32px;
            background: #232F3F;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 20px;
            letter-spacing: 1px;
        }
    }
}



.tip2 {
    height: 100px;

    .tip2-body {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 100px;
        justify-content: center;
        display: flex;
        .tip2-a{
            color: #1F3992;
        }
    }
}

.tip3 {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .tip3-btn {
        width: 320px;
        height: 46px;
        background: #FFFFFF;
        border: 1px solid #232F3F;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }

    .tip3-btn:hover {
        background: #232F3F;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
    }
}