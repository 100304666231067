.nav-box{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    width: 100%;
    background-color: #232F3F;
    // box-shadow: 0px 3px 4px 0px #e8eaee;
    .nav-inner{
        display: flex;
        justify-content: space-between;
        max-width: 1440px;
        height: 66px;
        padding: 0 40px;
        margin: 0 auto;
    }
    #logo{
        display: block;
        line-height: 66px;
        width: 115px;
        color: #fff;
    }
    .links{
        display: flex;
        position: relative;
        margin-left: 30px;
        min-width: 542px;
        user-select: none;
        .link{
            display: flex;
            padding: 0 30px;
            line-height: 66px;
            color: #fff;
            cursor: pointer;
            .anticon-up,.anticon-down{
                margin-left: 4px;
                padding: 28px 0;
                font-size: 10px;
            }
        }
        .link:hover{
            background: #FFFFFF;
            color: #333333;
        }
        .link-active{
            background: #FFFFFF;
            color: #333333;
        }
        .sub{
            position: absolute;
            top: 66px;
            left: -40px;
            width: 980px;
            padding: 60px;
            padding-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: #F0F1F4;
            
            .item{
                width: 260px;
                line-height: 18px;
                padding-bottom: 30px;
                order: 3;
                .head{
                    i{
                        display: inline-block;
                        font-size: 28px;
                        margin-right: .2em;
                        vertical-align: text-top;
                    }
                    span{
                        line-height: 24px;
                        font-size: 16px;
                        font-weight: 600;
                        color: #232f3f;
                    }
                }
                .body{
                    position: relative;
                    padding-top: 10px;
                    padding-left: 48px;
                    width: 100%;
                    overflow-y: hidden;
                    li{
                        width: 170px;
                        margin-bottom: 10px;
                    }
                    a{
                        font-size: 14px;
                        color: #9292a3;
                    }
                }
                .blur {
                    filter: blur(2px);
                }
                .blur::after{
                    content: '';
                    position: absolute;
                    top:0;
                    overflow: hidden;
                    display: block;
                    width: 160px;
                    height: 100%;
                    background: url('~assets/images/search/lock.png') no-repeat 40px 40px;
                }
            }
            
            .item:nth-child(4) {
                order: 2;
            }
            .item:nth-child(1) {
                order: 1;
            }
        }
    }
    .search{
        margin: 14px 40px 14px auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span{
            padding-left: 10px;
            color: #797983;
        }
        .searchOutlined{
            color: #fff;
            font-size: 24px;
        }
    }
    .user{
        display: flex;
        justify-content: center;
        align-items: center;
        >i{
            color: #fff;
            font-size: 30px;
        }
    }
}

/* 搜索弹层样式 */
.search-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 130;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}

.search-wrap{
    position: fixed;
    z-index: 132;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
}

.search-content{
    position: relative;
    // width: 1200px;
    // margin: 0 auto;
    .search-logo{
        width: 200px;
        display: block;
        margin: 100px auto 30px;
    }
}
.search-close{
    position: absolute;
    top: -40px;
    right: 15%;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    .anticon-close{
        display: block;
        width: 56px;
        height: 56px;
        font-size: 32px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
    }
}

.search-fade-enter,
.search-fade-appear {
  opacity: 0;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.search-fade-leave {
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.search-fade-enter.search-fade-enter-active,
.search-fade-appear.search-fade-appear-active {
  animation-name: rcDialogFadeIn;
  animation-play-state: running;
}
.search-fade-leave.search-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}
@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
