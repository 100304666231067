
.result{
    .list{
        padding: 0 5px;
        width: 100%;
    }
    .search-list{
        padding: 20px 0;
        margin-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid #EEEEEE;
        &:hover{
            background-color: #fff;
            // box-shadow:0px 2px 10px 0px rgba(240,242,247,1);
            // border-radius: 12px;
        }
       
        .sear-detail{
            justify-content: space-between;
            .custom-avatar{
                img{
                    width: 100%;height: 100%;
                    object-fit: contain;
                    // border-radius: 10px;
                }
            }
            .custom-no-avatar {
                width: 125px;
                height: 84px;
                object-fit: contain;
                overflow: hidden;
                background-color: #f6f9fe;
                border-radius: 0;
                img{
                    width: fit-content;
                }
            }
            dl{
                width: calc( 100% - 145px);
                margin-bottom: 0;
                dd.intro{
                    color: 333333;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                    font-size: 12px;
                }
                .date{
                    color: #999999;
                    font-size: 12px;
                }
            }
        }
        // 搜索结果是公司的logo
        .company-list{
            .custom-avatar{
                width: 125px;
                height: 84px;
                object-fit: contain;
                border: 1px solid #E9E9E9;
                border-radius: 0;
                background-color: transparent;
            }
            .custom-no-avatar{
                width: 125px;
                height: 84px;
                object-fit: contain;
                overflow: hidden;
                background-color: #f6f9fe;
                border-radius: 0;
                img{
                    width: 40px;height: 40px;
                }
            }
        }
        // logo部分
        .logo-img{
            display: inline-block;
            margin-right: 20px;
        }
        // 无logo的情况
        span.no-img,.park-logo{
            width: 125px;
            height: 84px;
            line-height: 84px;
            overflow: hidden;
            margin-right: 20px;
            text-align: center;
            background-color: #f6f9fe;
            // border-radius: 8px;
            display: inline-block;
            img{
                width: 40px;height: 40px;
                margin: 0;
            }
        }
        .logo-img.company{
            width: 125px;
            height: 84px;
            object-fit: contain;
            border: 1px solid #E9E9E9;
            // border-radius: 8px;
        }
        .park-logo img{
            width: 50px;
            height: 44px;
        }
        // 标签
        .sign{
            margin-right: 10px;
            max-width: 350px;
            padding: 0 10px;
            line-height: 24px;
            font-weight: 6s00;
            border-radius: 0;
        }

        // 头部
        a{
            margin-bottom: 20px;
            display: flex;
            display: -webkit-flex;
            justify-content: flex-start;
            white-space:nowrap;
            span{
                margin-right: 15px;
                color: #232F3F;
                font-size: 16px;
                font-weight: 500;
                
            }
            .type{
                background-color: #1F3992;
                color: #FFFFFF;
                font-size: 12px;
                width: 40px;
                height: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2px;
            }
            .name{
                font-weight: 600;
            }
            .des{
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &:hover{
                .name,.des{
                    color: #2222DA;
                }
            }
        }
    }
}
.sign.orange{
    background-color:#F9F9F9;
    color: #999999;
}
.sign.blue{
    background-color: #F9F9F9;
    color: #999999;
}

// 搜索建议
.suggest-box{
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(240, 242, 247, 1);
    a{  
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        margin-bottom: 0;
        color: #121f3d;
        font-size: 14px;
        // border-radius: 10px;
        cursor: pointer;
        span{
            display: inline-block;
        }
        .label{
            // background-color: #fff;
            display: inline-block;
            padding: 4px 10px;
            // border-radius: 4px;
            color: #1F3992;
            width: auto;
        }
        .label.park{
            background-color: #fff;
            color: #1F3992;
        }
        .label.company{
            background-color: #fff;
            color: #1F3992;
        }
        .label.tech{
            background-color: #fff;
            color: #1F3992;
        }
    }
    a:hover{
        background-color: #F9F9F9;
        span{
            color: #1F3992;
        }
        .label{
            background-color: #F9F9F9;
            color: #1F3992;
        }
    }
}
