.person-items{
    .ant-modal-body{
        display: flex;
        position: relative;
        padding:24px 24px 50px;
        // 左侧菜单 文字
        .left{
            flex-shrink: 0;
            width: 10%;
            .side-title{
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
                height: 100%;
                padding-left: 20px;
                font-size: 12px;
                color: #121f3d;
                .top{
                    padding-top: 250px;
                    >p{
                        font-size: 12px;
                        line-height: 36px;
                        margin-bottom: 0;
                        &:last-child{
                            margin-top: 5px;
                        }
                    }
                }
                .bottom{
                    padding-bottom: 60px;
                    >p{
                        height: 20px;
                        line-height: 20px;
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .compare-title{
            font-size: 20px;
            font-weight: bold;
            color: #202031;
        }
        .page-title{
            position: absolute;
            top: 20px;
            left: 20px;
        }
        .content{
            width: 90%;
            flex: 1;
            display: flex;
            justify-content: space-between;
            .item{
                flex: 1 ;
                padding: 0 18px 60px;
                border-right: 40px solid #fff;
                background-color: #F9FAFD;
                // 头像部分
                .head{
                    padding: 20px 0 ;
                    .profile{
                        .df{
                            height: 160px;
                            overflow: hidden;
                            line-height: 160px;
                            background-color: #e6e7e7;
                            text-align: center;
                            border-radius: 8px;
                            .avatar {
                                display: block;
                                margin: 0 auto;
                                max-width: 100%;
                                height: 160px;
                            }
                        }
                    }
                    .person-name{
                        margin: 20px auto 0;
                        text-align: center;
                    }
                }
                .profile{
                    margin: 0 auto;
                    width: 160px;
                    height: 160px;
                }
                .info{
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap; 
                    text-overflow: ellipsis;
                    font-size: 14px;
                    line-height: 36px;
                    span{
                        margin-right: 10px;
                    }
                }
                // 奖项及荣誉称号
                .tags {
                    height: 36px;
                    padding-top: 10px;
                   span{
                       margin-right: 10px; 
                       margin-bottom: .5em;
                   }
                }
                // 雷达图
                .radar{
                    padding: 50px 0;
                    >div{
                        margin: 0 auto;
                    }
                }
            }
            // 横向柱状图
            .rows{
                height: 20px;
                background-color: #826AF9;
                padding-left: 2px;
                margin-bottom: 12px;
                line-height: 20px;
                font-size: 16px;
                color:#202031;
                border-radius: 3px;
                white-space: nowrap;
            }
    
            .pink{
                width: 78px;
                background-color: #F7D2FF;
            }
        }
    }
}