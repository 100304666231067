.flex-box {
    display: flex;
}

.company {
    width: 480px;

    .head {
        padding: 36px 0;
        font-weight: 600;
        font-size: 24px;
        color: #121f3d;
    }

    .tab-wrap {
        justify-content: flex-start;

        .tab {
            padding: 2px 20px;
            margin-right: 20px;
            background-color: #f4f4fa;
            border-radius: 8px;
        }

        .tab-active {
            color: white;
            background-color: #2222da;
        }
    }
}

.section-title {
    margin: 20px 0;
    font-weight: 600;
    font-size: 20px;
    color: #121f3d;
}

.sheet {
    position: relative;
    width: 1180px;
    box-shadow: 0px 0px 8px 0px rgb(234, 234, 255);
    border-radius: 3px;

    .left {
        position: absolute;
        top: 0;
        left: 0;
        width: 260px;

        .cell {
            padding-left: 30px;
        }
    }

    .right {
        display: flex;
        padding-left: 260px;

        .report {
            flex: 1;
            width: 20%;
            font-size: 12px;
        }
    }

    .cell {
        height: 60px;
        padding: 0 20px;
        line-height: 60px;
    }

    .cell:nth-child(odd) {
        background-color: #F9FAFD;
    }

    .emp {
        color: #131313;
        font-size: 16px;
        font-weight: 600;
    }
}

.round {
    position: relative;
    width: 980px;
    user-select: none;
    box-shadow: 0px 0px 8px 0px rgb(234, 234, 255);
    border-radius: 3px;

    .left {
        position: absolute;
        top: 0;
        left: 0;
        width: 260px;
        z-index: 2;

        .cell {
            padding-left: 30px;
            background-color: #fff;
        }
    }

    .right {
        display: flex;
        padding-left: 260px;
        overflow-x: scroll;

        .report {
            flex: 1;
            width: 20%;
            font-size: 12px;

            .cell {
                height: 60px;
                line-height: 60px;
                width: 160px;
            }
        }
    }

    .emp {
        color: #131313;
        font-size: 16px;
        font-weight: 600;
    }

    .cell {
        height: 60px;
        line-height: 60px;
    }

    .cell:nth-child(odd) {
        background-color: #F9FAFD;
    }

    .cell:first-child {
        background-color: #fff;
    }
}

.all-box {
    display: flex;
    align-items: baseline;

    .ant-layout-sider-children {
        background-color: white;
    }

    .left-box {
        margin-right: 100px;

        .link-active {
            color: '#182390';
            font-weight: 600;
            background: linear-gradient(90deg '#f3f4fa'0%, white 100%);
        }
    }

    .title-box {
        margin: 20px 0;

        .title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 600;
            color: '#121F3D';
            margin-bottom: 20px;
        }
    }
    // tab切换按钮
    .ant-tabs-bar,.ant-tabs-nav {
        border: none;
        margin-bottom: 15px;
        &::before{
            border: none;
        }
        .ant-tabs-ink-bar {
            display: none !important;
        }
        .myself{
            margin: -4px 0 0 -40px;
        }
        .ant-tabs-nav,.ant-tabs-nav-list {
            background-color: #F9FAFD;
            padding: 8px;
            border-radius: 10px;

            .ant-tabs-tab {
                margin-right: 0;
                padding: 5px 16px;
            }

            .ant-tabs-tab-active {
                background-color: #fff;
                border-radius: 8px;
            }
        }
    }

}

@media (max-width: 1440px) {
    .container,
    .login-con .nologin-container {
        width: 1240px;
        padding: 20px;
    }
}

