.custom-avatar,.custom-no-avatar{
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    border-radius: 10px;
    background-color: #f6f9fe;
    margin-right: 20px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img.avatar{
       width: 100%; 
    }
    img.no-avatar{
        width: 50%;
     }
}
// 公司的团队成员
.member{
    .custom-avatar,.custom-no-avatar{
        width: 100px;
        height: 100px;
        margin-right: 0;
        img.avatar{
            display: block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            // border-radius: 10px;
        }
    }
}
// 专辑
.collection-con {
    .custom-avatar,.custom-no-avatar{
        width: 80px;
        height: 80px;
        display: inline-block;
        background-color: #f6f8ff;
        margin-right: 20px;
        margin-bottom: 5px;
        // border-radius: 5px;
        img{
            border-radius: 5px;
            width: 80px;
            height: 80px;
            object-fit: contain;
        }
        img.no-avatar{
            padding: 15px;
        }
    }
}

// 对比公司基本信息
.compare-items{
    .custom-avatar,.custom-no-avatar{
        width: 100px;
        height: 100px;
        display: inline-block;
        // border-radius: 5px;
        margin-right: 0;
        img.avatar{
            width: 100%;
        }
        img.no-avatar{
            width: 50%;
            padding-top: 25px;
        }
    }
}
