
// 人物详情 对比icon
.action i.iconfont.icon-compare{
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: #232F3F;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #F9F9F9;
    cursor: pointer;
}

#compare{
    .compare-tools {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 50%;
        background-color: #F4F4FA;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }
    
    .active {
        background-color: #2222DA;
        color: #fff;
    }
}

.person-compare{
    position: relative;
    #toast{
        position: absolute;
        z-index: 10;
        top: 0;
        right: 60px;
    }
}

.mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    .section-title {
        font-size: 20px;
        font-weight: bold;
        color: #202031;
    }
}
.person-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,.45);
}
.person-wrap{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
}
.person-content{
    position: relative;
    top: 120px;
    margin: 0 auto;
    width: 80%;
    min-width: 1440px;
    background-color: #fff;
    border: 0;
    border-radius: 3px;
}
.person-close{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    .anticon-close{
        display: block;
        width: 56px;
        height: 56px;
        font-size: 32px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
    }
}

.person-body{
    display: flex;
    position: relative;
    .left{
        flex-shrink: 0;
        width: 120px;
        .side-title{
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            height: 100%;
            padding-left: 20px;
            font-size: 12px;
            color: #121f3d;
            .top{
                padding-top: 320px;
            }
            .bottom{
                padding-bottom: 100px;
            }
        }
    }
    .compare-title{
        font-size: 20px;
        font-weight: bold;
        color: #202031;
    }
    .page-title{
        position: absolute;
        top: 20px;
        left: 20px;
    }
    .content{
        flex: 1;
        display: flex;
        // padding-top: 60px;
        justify-content: space-between;
        .item{
            flex: 1 ;
            padding: 0 30px 60px;
            border-right: 20px solid #fff;
            background-color: #F9FAFD;
            .head{
                padding-top: 30px;
                margin-bottom: 20px;
                border-bottom: 1px solid #D2D2D7;
                .profile{
                    .df{
                        height: 160px;
                        overflow: hidden;
                        line-height: 160px;
                        background-color: #e6e7e7;
                        text-align: center;
                        border-radius: 8px;
                        .avatar {
                            display: block;
                            margin: 0 auto;
                            max-width: 100%;
                            height: 160px;
                        }
                    }
                }
                .person-name{
                    margin: 20px auto;
                    text-align: center;
                }
            }
            .profile{
                margin: 0 auto;
                width: 160px;
                height: 160px;
            }
            .info{
                text-align: center;
                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis;
                font-size: 14px;
                line-height: 2em;
                span{
                    margin-right: 10px;
                }
            }
            .tags {
                height: 36px;
                padding-top: 20px;
               span{
                   margin-right: 10px; 
                   margin-bottom: .5em;
               }
            }
            .radar{
                padding-top: 50px;
                padding-bottom: 100px;
                & > div{
                    margin: 0 auto;
                    padding: 4px;
                    background-color: #fff;
                    border-radius: 5px;
                }
            }
        }
        .item:last-child{
            border: none;
        }
        .rows{
            height: 24px;
            background-color: #826AF9;
            padding-left: 6px;
            margin-bottom: .5em;
            line-height: 24px;
            font-size: 16px;
            color:#202031;
            border-radius: 3px;
            white-space: nowrap;
        }

        .pink{
            width: 78px;
            background-color: #F7D2FF;
        }
    }
}