// 未登录 门户首页
.all {
    background-color: #FFFFFF;
    overflow-x: hidden;
    .all-box {
        // zoom: 1;
        // width:100%;
        // height:100%;
        // min-width: 1440px;
        // zoom: 1;
        // background-size: contain;
        // -webkit-background-size: contain;
        // -o-background-size: contain;
        // background-position: center 0;
        position: relative;
        height: fit-content;
        .main-banner {
            width: 100%;
            // position: absolute;
        }
        .official-img {
            width: 100%;
            // width: 1200px;
            // padding: 0 20px;
            height: 100%;
            margin: 0 auto ;
            position: absolute;
            display: flex;
            left: 0;
            bottom: 0;
            .cenetr-box {
                width: 1440px;
                margin: -88px auto 0;
                .img-body {
                    align-self: center;
                    // margin-bottom: 200px;
                    .top_title{
                        opacity: 0;
                    }
                    .img {
                        width: 180px;
                        height: 52px;
                    }
    
                    .h1 {
                        font-size: 35px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 42px;
                        margin: 21px 0 40px 0;
                    }
                    .topIntro_img {
                        width: 1440px;
                    }
                    .btns {
    
                        .demo {
                            width: 140px;
                            height: 44px;
                            border: 1px solid #979797;
                            margin-right: 30px;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 44px;
                            background-color: transparent;
                        }
    
                        .demo:hover {
                            background-color: #FFFFFF;
                            color: #333333;
                        }
    
    
    
                        .download {
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 25px;
                            border-bottom: 1px solid white;
                        }
                    }
                }
                .future_pic_box{
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }
    
    .official-body {
        width: 100%;
        margin: 0px auto;
        text-align: center;
        .second_box{
            width: 1440px;
            height: 868px;
            position: relative;
            margin: 0 auto;
            .second_label{
                position: absolute;
                top: 284px;
                left: 255px;
            }
            .second_cell{
                position: absolute;
                top: 104px;
                right: 90px;
            }
        }
        .third_box{
            width: 100%;
            height: 560px;
            background: #010101;
            .title_white{
                text-align: center;
                font-size: 52px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 64px;
                padding-top: 68px;
            }
            .banner_box{
                width: 1440px;
                padding: 0 88px;
                display: flex;
                justify-content: space-between;
                margin: 74px auto 0;
                .banner_item{
                    // margin-right: 36px;
                    width: 224px;
                    height: 260px;
                    background: url('https://image.sciphi.cn/dt_insights/platform_bg.svg') center top no-repeat;
                    &:hover{
                        background: url('https://image.sciphi.cn/dt_insights/platform_bg_active.svg') center top no-repeat;
                    }
                    .banner_pic{
                        width: 120px;
                        height: 120px;
                        margin-top: 36px;
                        // border: 1px solid #fff;
                    }
                    .banner_title{
                        width: 96px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 26px;
                        margin-left: 64px;
                        margin-top: 2px;
                        position: relative;
                        
                    }
                }
            }
        }
        .four_box{
            width: 1440px;
            // height: 878px;
            background: #fff;
            margin: 0 auto;
            .title_black{
                font-size: 52px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 64px;
                margin-top: 90px;
                text-align: center;
            }
            .four_banner_box{
                margin-top: 84px;
                padding-left: 80px;
                display: flex;
                .four_left{
                    .banner_btn{
                        width: 460px;
                        height: 112px;
                        border-radius: 60px;
                        border: 1px solid #CBCBCB;
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 111px;
                        text-align: center;
                        margin-bottom: 28px;
                        cursor: pointer;
                    }
                    .banner_title{
                        position: relative;
                    }
                    .banner_active_icon{
                        position: absolute;
                        top: 46px;
                        right: 52px;
                    }
                    .banner_btn_active{
                        width: 460px;
                        height: 112px;
                        background: #101010;
                        border-radius: 60px;
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 111px;
                        text-align: center;
                        margin-bottom: 28px;
                        cursor: pointer;
                    }

                }
                .four_right{
                    width: 800px;
                    height: 540px;
                    margin-left: 20px;
                    overflow: hidden;
                    @keyframes opacity {
                        from{
                            opacity: 0;
                        }to{
                            opacity: 1;
                        }
                    }
                    .four_pic{
                        width: 800px;
                        height: 540px;
                        opacity: 0; 
                        // transition: opacity 200ms ease-in-out 0s;
                    }
                    .four_pic_active {
                        animation: opacity .5s ease-in .2s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
        .title-one,
        .title-two,
        .title-four {
            margin: 120px 0 60px 0;
            font-size: 34px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 48px;
        }

        .title-three {
            margin: 0 0 60px 0;
            font-size: 34px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 48px;
        }

        .five_box{
            width: 100%;
            height: 654px;
            background: url('https://image.sciphi.cn/dt_insights/Number.svg') no-repeat;
            background-size: 100%;
            padding-top: 78px;
            margin-top: 72px;
            .title_white{
                font-size: 52px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 64px;
                text-align: center;
                
            }
            .five_banner_box{
                // display: flex;
                // margin-top: 74px;
                // padding-left: 79px;
                width: 1440px;
                padding: 0 88px;
                display: flex;
                justify-content: space-between;
                margin: 74px auto 0;
                .des_item{
                    width: 302px;
                    height: 332px;
                    // margin-right: 26px;
                    padding-left: 25px;
                    padding-top: 19px;
                    background: url('https://image.sciphi.cn/dt_insights/des_bg.svg') no-repeat;
                    text-align: left;
                    .des_pic{
                        width: 72px;
                        height: 72px;
                    }
                    .des_item_title{
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 33px;
                        margin-top: 20px;
                        padding-left: 6px;
                    }
                    .des_item_line{
                        width: 46px;
                        height: 2px;
                        background: #FFFFFF;
                        border-radius: 1px;
                        margin-left: 6px;
                    }
                    .des_item_con{
                        width: 240px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 24px;
                        margin-top: 26px;
                        padding-left: 6px;
                        // -webkit-text-stroke: 1px #979797;
                        // text-stroke: 1px #979797;
                    }
                    .despic1{
                        background: url('https://image.sciphi.cn/dt_insights/des_bg1.svg') no-repeat;
                    }
                    .despic2{
                        background: url('https://image.sciphi.cn/dt_insights/des_bg2.svg') no-repeat;
                    }
                    .despic3{
                        background: url('https://image.sciphi.cn/dt_insights/des_bg3.svg') no-repeat;
                    }
                    .despic4{
                        background: url('https://image.sciphi.cn/dt_insights/des_bg4.svg') no-repeat;
                    }
                }
                .desItem1:hover{
                    background: url('https://image.sciphi.cn/dt_insights/des_bg_active.svg') no-repeat;
                    .desLine1{
                        width: 46px;
                        height: 2px;
                        background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                        border-radius: 1px;
                    }
                    .despic1{
                        background: url('https://image.sciphi.cn/dt_insights/des_act_bg1.svg') no-repeat;
                    }
                }
                .desItem2:hover{
                    background: url('https://image.sciphi.cn/dt_insights/des_bg_active.svg') no-repeat;
                    .desLine2{
                        width: 46px;
                        height: 2px;
                        background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                        border-radius: 1px;
                    }
                    .despic2{
                        background: url('https://image.sciphi.cn/dt_insights/des_act_bg2.svg') no-repeat;
                    }
                }
                .desItem3:hover{
                    background: url('https://image.sciphi.cn/dt_insights/des_bg_active.svg') no-repeat;
                    .desLine3{
                        width: 46px;
                        height: 2px;
                        background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                        border-radius: 1px;
                    }
                    .despic3{
                        background: url('https://image.sciphi.cn/dt_insights/des_act_bg3.svg') no-repeat;
                    }
                }
                .desItem4:hover{
                    background: url('https://image.sciphi.cn/dt_insights/des_bg_active.svg') no-repeat;
                    .desLine4{
                        width: 46px;
                        height: 2px;
                        background: linear-gradient(315deg, #FC9E9E 0%, #E75040 100%);
                        border-radius: 1px;
                    }
                    .despic4{
                        background: url('https://image.sciphi.cn/dt_insights/des_act_bg4.svg') no-repeat;
                    }
                }
            }
        }
    }

    
    .title-tip {
        width: 100%;
        height: 200px;
        background-image: url('../../assets//images//official/tip21440.png') ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 100px 0 100px 0;

        p {
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 42px;
            margin-bottom: 0;
        }

        a {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;

            >i {
                font-size: 22px;
            }
        }
    }

}
